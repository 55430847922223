// components/ImageGenerator/constants.js
export const fieldOptions = {
    model: [
        "flux",
        "turbo",
        "hyperrealism",
        "3d-rendered",
        "noir-gothic",
        "clean-minimal",
        "futuristic-modern",
        "ethereal-fantasy",
        "abstract-expressions",
        "retro-vintage"
    ]
};

export const dimensionPresets = {
    'custom': { width: 1024, height: 1024, label: 'Custom Dimensions' },
    'square': { width: 1080, height: 1080, label: 'Square 1080 x 1080' },
    'logo': { width: 500, height: 500, label: 'Logo 500 x 500' },
    'landscape': { width: 1920, height: 1080, label: 'Landscape 1920 x 1080' },
    'portrait': { width: 1080, height: 1920, label: 'Portrait 1080 x 1920' },
    'wide': { width: 1600, height: 900, label: 'Wide 1600 x 900' },
    'tshirt': { width: 2400, height: 3200, label: 'T-Shirt Design 2400 x 3200' },
    'art': { width: 1400, height: 2000, label: 'Art Print 1400 x 2000' },
    'sticker': { width: 800, height: 800, label: 'Sticker 800 x 800' }
};

