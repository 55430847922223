import { useState, useCallback } from 'react';
import { downloadImage } from '../../utils/image/imageDownload';
import { reportImage } from '../../utils/image/reportImage';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { copyMarkdownLinkToClipboard } from '../../utils/image/copyMarkdown';

const useImageHandlers = () => {
  const [buttonStates, setButtonStates] = useState({});
  const [error, setError] = useState(null);

  const extractImageSettings = (url) => {
    try {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);

      return {
        width: params.get('width') || 'default',
        height: params.get('height') || 'default',
        model: params.get('model') || 'default',
        seed: params.get('seed') || 'random',
        prompt: decodeURIComponent(urlObj.pathname.split('/prompt/')[1] || '')
      };
    } catch (error) {
      console.error('Failed to parse image URL:', error);
      return null;
    }
  };

  const handleShowSettings = useCallback(async (imageUrl, id) => {
    setButtonStates(prev => ({ ...prev, [id]: { settings: 'loading' } }));
    try {
      const settings = extractImageSettings(imageUrl);
      if (settings) {
        setButtonStates(prev => ({ ...prev, [id]: { settings: 'success' } }));
        setTimeout(() => {
          setButtonStates(prev => ({ ...prev, [id]: { settings: 'default' } }));
        }, 3000);
        return {
          success: true,
          message: 'Image settings retrieved successfully',
          settings
        };
      } else {
        throw new Error('Failed to parse image settings');
      }
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [id]: { settings: 'default' } }));
      setError('Failed to retrieve image settings: ' + error.message);
      return { success: false, message: 'Failed to retrieve image settings' };
    }
  }, []);

  const handleDownload = useCallback(async (imageUrl, id, imageTitle, verifiedFollower) => {
    setButtonStates(prev => ({ ...prev, [id]: { download: 'loading' } }));
    try {
      await downloadImage(imageUrl, imageTitle, verifiedFollower);
      setButtonStates(prev => ({ ...prev, [id]: { download: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      }, 3000);
      return { success: true, message: 'Image downloaded successfully' };
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      setError('Failed to download image: ' + error.message);
      return { success: false, message: 'Failed to download image' };
    }
  }, []);

  const handleReport = useCallback(async (imageId) => {
    setButtonStates(prev => ({ ...prev, [imageId]: { report: 'loading' } }));
    try {
      const result = await reportImage(imageId);
      if (result.success) {
        setButtonStates(prev => ({ ...prev, [imageId]: { report: 'success' } }));
        setTimeout(() => {
          setButtonStates(prev => ({ ...prev, [imageId]: { report: 'default' } }));
        }, 3000);
        return { success: true, message: 'Image reported successfully' };
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [imageId]: { report: 'default' } }));
      setError('Failed to report image: ' + error.message);
      return { success: false, message: 'Failed to report image' };
    }
  }, []);

  const handleShareClick = useCallback(async (id) => {
    setButtonStates(prev => ({ ...prev, [id]: { share: 'loading' } }));
    try {
      const shareUrl = `https://rangrezai.com/design/${id}`;
      await navigator.clipboard.writeText(shareUrl);
      setButtonStates(prev => ({ ...prev, [id]: { share: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { share: 'default' } }));
      }, 3000);
      return { success: true, message: 'Share link copied to clipboard' };
    } catch (error) {
      setButtonStates(prev => ({ ...prev, [id]: { share: 'default' } }));
      setError('Failed to copy share link: ' + error.message);
      return { success: false, message: 'Failed to copy share link' };
    }
  }, []);

  const handleCopyMarkdownLink = useCallback(async (imageUrl, id, imageTitle) => {
    setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'loading' } }));
    const result = await copyMarkdownLinkToClipboard(imageUrl, imageTitle);
    if (result.success) {
      setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'default' } }));
      }, 2000);
      return { success: true, message: 'Markdown link copied to clipboard' };
    } else {
      setButtonStates(prev => ({ ...prev, [id]: { copyLink: 'default' } }));
      return { success: false, message: 'Failed to copy markdown link' };
    }
  }, []);

  const handleSetAsProfile = useCallback(async (imageUrl, id) => {
    if (!imageUrl || !id) {
      setError('Invalid image URL or ID');
      return { success: false, message: 'Invalid parameters' };
    }

    setButtonStates(prev => ({
      ...prev,
      [id]: { setProfile: 'loading' }
    }));

    try {
      const response = await authenticatedApiCall(
        '/set-profile-image',
        'POST',
        { imageUrl } 
      );

      if (response?.success) {
        setButtonStates(prev => ({
          ...prev,
          [id]: { setProfile: 'success' }
        }));

        const resetTimer = setTimeout(() => {
          setButtonStates(prev => ({
            ...prev,
            [id]: { setProfile: 'default' }
          }));
        }, 3000);

        return () => clearTimeout(resetTimer);

      } else {
        throw new Error(response?.message || 'Failed to set profile image');
      }

    } catch (error) {
      setButtonStates(prev => ({
        ...prev,
        [id]: { setProfile: 'default' }
      }));

      if (error.status === 401) {
        setError('Session expired. Please login again.');
      } else {
        setError(`Failed to set profile image: ${error.message}`);
      }

      return {
        success: false,
        message: error.message || 'Failed to set profile image'
      };
    }
  }, [setButtonStates, setError]); 


  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    buttonStates,
    error,
    handleDownload,
    handleReport,
    handleShareClick,
    handleShowSettings,
    handleSetAsProfile,
    handleCopyMarkdownLink,
    clearError,
    setError
  };
};

export default useImageHandlers;