import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { GAME_CONFIG, ELEMENTS, GAME_SETTINGS } from './constants';
import { createGrid, calculateMousePosition, createSandPhysicsSimulation } from '../../utils/sandGameUtils';
import { addNewElementBehaviors } from '../../utils/elementBehaviorsUtils';

import './FallingObjectsGame.css';

const FallingObjectsGame = forwardRef((props, ref) => {
  // Destructure constants
  const { WIDTH, HEIGHT, DEFAULT_SQUARE_SIZE } = GAME_CONFIG;
  const {
    DEFAULT_ELEMENT,
    DEFAULT_GRAVITY,
    ELEMENT_SPREAD_MATRIX_RANGE,
    DEFAULT_SPREAD_MATRIX,
    ELEMENT_SPREAD_PROBABILITY_RANGE,
    DEFAULT_SPREAD_PROBABILITY
  } = GAME_SETTINGS;

  // State for customizable settings
  const [spreadMatrix, setSpreadMatrix] = useState(DEFAULT_SPREAD_MATRIX);
  const [spreadProbability, setSpreadProbability] = useState(DEFAULT_SPREAD_PROBABILITY);

  // Calculated grid dimensions based on current square size
  const cols = Math.floor(WIDTH / DEFAULT_SQUARE_SIZE);
  const rows = Math.floor(HEIGHT / DEFAULT_SQUARE_SIZE);

  // Create extended sand physics simulation with new element behaviors
  const extendedSandPhysics = addNewElementBehaviors(createSandPhysicsSimulation)(cols, rows, DEFAULT_SQUARE_SIZE);
  const { updateElements, addElement, dropSuspendedElements } = extendedSandPhysics;

  // Component state and refs
  const canvasRef = useRef(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedElement, setSelectedElement] = useState(DEFAULT_ELEMENT);
  const animationFrameRef = useRef(null);
  const [isDropping, setIsDropping] = useState(false);

  // Game state references
  const gridRef = useRef(createGrid(cols, rows));

  // Get username for canvas download
  const user = JSON.parse(localStorage.getItem('user'));
  const username = user && user.username ? user.username : "guest_user";

  const resetCanvas = () => {
    // Cancel any ongoing animation frame
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    // Clear canvas
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
    }

    // Reset all state and refs
    gridRef.current = createGrid(cols, rows);
    setIsMouseDown(false);
    setIsDropping(false);
    setSelectedElement(DEFAULT_ELEMENT);
    setSpreadMatrix(DEFAULT_SPREAD_MATRIX);
    setSpreadProbability(DEFAULT_SPREAD_PROBABILITY);

    // Important: Reset gameStarted to force a re-render and re-enable interactions
    setGameStarted(false);

    // Explicitly clear the canvas using drawElements
    drawElements();
  };

  useImperativeHandle(ref, () => ({
    cleanup: () => {
      // Cancel any ongoing animation frame
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      // Clear canvas
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, WIDTH, HEIGHT);
      }

      // Reset all state and refs
      gridRef.current = createGrid(cols, rows);
      setGameStarted(false);
      setIsMouseDown(false);
    },
    reset: resetCanvas
  }));

  // Download canvas as image
  const downloadCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Create a temporary link element to trigger download
    const link = document.createElement('a');
    link.download = `element_art_canvas_by_${username}_on_rangrezai.com.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  // Draw the element simulation
  const drawElements = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, WIDTH, HEIGHT);

    const grid = gridRef.current;
    for (let i = 0; i < cols; i++) {
      for (let j = 0; j < rows; j++) {
        const cell = grid[i][j];
        if (cell.type) {
          ctx.fillStyle = cell.color;
          ctx.fillRect(i * DEFAULT_SQUARE_SIZE, j * DEFAULT_SQUARE_SIZE, DEFAULT_SQUARE_SIZE, DEFAULT_SQUARE_SIZE);
        }
      }
    }
  };

  // Game loop
  useEffect(() => {
    if (!gameStarted) return;

    const animate = () => {
      let newGrid = gridRef.current;

      if (isDropping) {
        const { nextGrid, elementsMoved } = dropSuspendedElements(newGrid);
        newGrid = nextGrid;

        // Stop dropping if no more elements can move
        if (!elementsMoved) {
          setIsDropping(false);
        }
      } else {
        newGrid = updateElements(newGrid, DEFAULT_GRAVITY);
      }

      gridRef.current = newGrid;
      drawElements();
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
    // eslint-disable-next-line 
  }, [gameStarted, DEFAULT_GRAVITY, isDropping]);

  // Mouse interaction to add elements
  const handleMouseMove = (e) => {
    e.stopPropagation();
    if (!gameStarted) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const { x: mouseX, y: mouseY } = calculateMousePosition(e, canvas);

    // Only add element if mouse is clicked
    if (isMouseDown) {
      gridRef.current = addElement(
        gridRef.current,
        mouseX,
        mouseY,
        selectedElement,
        {
          ELEMENT_SPREAD_MATRIX: spreadMatrix,
          ELEMENT_SPREAD_PROBABILITY: spreadProbability
        }
      );
    }
  };

  // Mouse down handler to start drawing
  const handleMouseDown = (e) => {
    e.stopPropagation();
    if (!gameStarted) return;

    const canvas = canvasRef.current;
    const { x: mouseX, y: mouseY } = calculateMousePosition(e, canvas);

    setIsMouseDown(true);
    gridRef.current = addElement(
      gridRef.current,
      mouseX,
      mouseY,
      selectedElement,
      {
        ELEMENT_SPREAD_MATRIX: spreadMatrix,
        ELEMENT_SPREAD_PROBABILITY: spreadProbability
      }
    );
  };

  // Mouse up handler to stop drawing
  const handleMouseUp = (e) => {
    e.stopPropagation();
    setIsMouseDown(false);
  };

  const handleDropElements = () => {
    setIsDropping(true);
  };

  // Start game
  const startGame = (e) => {
    e.stopPropagation();
    // Reset game state
    gridRef.current = createGrid(cols, rows);
    setGameStarted(true);
  };

  // Render element selector
  const renderElementSelector = () => {
    return (
      <div className="fog-color-selector">
        {Object.keys(ELEMENTS)
          .filter(elementKey => elementKey !== 'ERASER')
          .map((elementKey) => {
            const element = ELEMENTS[elementKey];
            return (
              <button
                key={elementKey}
                className={`fog-color-button ${selectedElement === elementKey ? 'selected' : ''}`}
                style={{
                  backgroundColor: element.baseColor ?
                    `hsl(${element.baseColor.hue}, ${element.baseColor.saturation}%, ${element.baseColor.lightness}%)`
                    : 'white',
                  border: elementKey === 'STONE' ? '2px solid black' : 'none'
                }}
                onClick={() => setSelectedElement(elementKey)}
              >
                {element.name}
              </button>
            );
          })}
        <button
          key="ERASER"
          className={`fog-color-button ${selectedElement === 'ERASER' ? 'selected' : ''}`}
          style={{
            backgroundColor: 'white',
            border: '2px dashed black'
          }}
          onClick={() => setSelectedElement('ERASER')}
        >
          Erase
        </button>
      </div>
    );
  };

  // Render settings sidebar
  const renderSettingsSidebar = () => {
    return (
      <div className="fog-settings-sidebar">
        <div className="fog-setting-group">
          <label>Element Spread Width</label>
          <input
            type="range"
            min={ELEMENT_SPREAD_MATRIX_RANGE.MIN}
            max={ELEMENT_SPREAD_MATRIX_RANGE.MAX}
            step={ELEMENT_SPREAD_MATRIX_RANGE.STEP}
            value={spreadMatrix}
            onChange={(e) => setSpreadMatrix(Number(e.target.value))}
          />
          <span>{spreadMatrix}</span>
        </div>

        <div className="fog-setting-group">
          <label>Element Spread Density</label>
          <input
            type="range"
            min={ELEMENT_SPREAD_PROBABILITY_RANGE.MIN}
            max={ELEMENT_SPREAD_PROBABILITY_RANGE.MAX}
            step={ELEMENT_SPREAD_PROBABILITY_RANGE.STEP}
            value={spreadProbability}
            onChange={(e) => setSpreadProbability(Number(e.target.value))}
          />
          <span>{spreadProbability.toFixed(2)}</span>
        </div>

        <div className="fog-setting-group">
          <label>Element Selection</label>
          {renderElementSelector()}
        </div>
        <div className="fog-setting-group-buttons">
          <button
            onClick={handleDropElements}
            className="fog-drop-button"
            style={{ userSelect: 'none' }}
            disabled={!gameStarted}
          >
            Drop All Elements
          </button>
          <button
            onClick={resetCanvas}
            className="fog-reset-button"
            style={{ userSelect: 'none' }}
            disabled={!gameStarted}
          >
            Reset Canvas
          </button>
          <button
            onClick={downloadCanvas}
            className="fog-download-button"
            style={{ userSelect: 'none' }}
          >
            Download Image
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <h1 className='gcc-heading'>Element Art Canvas</h1>
      <div className="fog-game-container">
        {!gameStarted ? (
          <div className="fog-start-screen">
            <h2>Falling Elements Simulation</h2>
            <p>Create mesmerizing patterns and watch physics in action! Experiment with different elements.</p>
            <button onClick={startGame}>Start</button>
            <a
              href="https://thecodingtrain.com/challenges/180-falling-sand"
              target="_blank"
              rel="noopener noreferrer"
              className="fog-credit-link"
            >
              inspired by thecodingtrain
            </a>
          </div>
        ) : (
          <div className="fog-game-content">
            {renderSettingsSidebar()}
            <div className="fog-canvas-container">
              <canvas
                ref={canvasRef}
                width={WIDTH}
                height={HEIGHT}
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={() => setIsMouseDown(false)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default FallingObjectsGame;