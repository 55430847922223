// src/utils/elementBehaviors/beehiveBehavior.js
export const updateBeehiveBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Randomly spawn bees - low probability
    if (Math.random() < 0.05) {
        // Check surrounding cells to spawn bee
        const spawnDirections = [
            { dx: -1, dy: 0 }, { dx: 1, dy: 0 },
            { dx: 0, dy: -1 }, { dx: 0, dy: 1 }
        ];

        for (let { dx, dy } of spawnDirections) {
            const newX = i + dx;
            const newY = j + dy;

            if (withinCols(newX) && withinRows(newY) && !grid[newX][newY].type) {
                nextGrid[newX][newY] = {
                    type: 'BEE',
                    color: `hsl(60, 100%, ${50 + Math.random() * 20}%)`
                };
                break;
            }
        }
    }

    // Keep beehive stationary
    nextGrid[i][j] = currentCell;
    return false;
};