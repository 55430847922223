// constants.js
export const GAME_CONFIG = {
    WIDTH: 600,
    HEIGHT: 500,
    DEFAULT_SQUARE_SIZE: 3
};

export const ELEMENTS = {
    SAND: {
        name: 'Sand',
        baseColor: { hue: 60, saturation: 100, lightness: 50 },
        density: 2,
        flowRate: 0.5
    },
    WATER: {
        name: 'Water',
        baseColor: { hue: 210, saturation: 100, lightness: 50 },
        density: 1,
        flowRate: 0.8
    },
    STONE: {
        name: 'Stone',
        baseColor: { hue: 0, saturation: 0, lightness: 50 },
        density: 3,
        flowRate: 0
    },
    ROCK: {
        name: 'Rock',
        baseColor: { hue: 0, saturation: 0, lightness: 40 },
        density: 3,
        flowRate: 0
    },
    BRICK: {
        name: 'Brick',
        baseColor: { hue: 10, saturation: 70, lightness: 40 },
        density: 3,
        flowRate: 0
    },
    WOOD: {
        name: 'Wood',
        baseColor: { hue: 30, saturation: 50, lightness: 30 },
        density: 2,
        flowRate: 0
    },
    FIRE: {
        name: 'Fire',
        baseColor: { hue: 30, saturation: 100, lightness: 50 },
        density: 0,
        flowRate: 0
    },
    FLOWER: {
        name: 'Flower',
        baseColor: { hue: 300, saturation: 70, lightness: 50 },
        density: 1,
        flowRate: 0
    },
    ICE: {
        name: 'Snow',
        baseColor: { hue: 200, saturation: 50, lightness: 80 },
        density: 2,
        flowRate: 0
    },
    MUD: {
        name: 'Mud',
        baseColor: { hue: 40, saturation: 50, lightness: 40 },
        density: 2,
        flowRate: 0.5
    },
    PLANT: {
        name: 'Plant',
        baseColor: { hue: 120, saturation: 70, lightness: 40 },
        density: 1,
        flowRate: 0
    },
    CLOUD: {
        name: 'Cloud',
        baseColor: { hue: 0, saturation: 0, lightness: 90 },
        density: 0,
        flowRate: 0.6
    },
    LAVA: {
        name: 'Lava',
        baseColor: { hue: 10, saturation: 100, lightness: 40 },
        density: 2,
        flowRate: 0.6
    },
    OIL_RIG_SOURCE: {
        name: 'Oil Pump',
        baseColor: { hue: 30, saturation: 50, lightness: 20 },
        density: 3,
        flowRate: 0
    },
    BEEHIVE: {
        name: 'Beehive',
        baseColor: { hue: 50, saturation: 50, lightness: 40 },
        density: 3,
        flowRate: 0
    },
    BEE: {
        name: 'Bees',
        baseColor: { hue: 60, saturation: 100, lightness: 50 },
        density: 0,
        flowRate: 0.8
    },
    OIL: {
        name: 'Oil',
        baseColor: { hue: 30, saturation: 50, lightness: 20 },
        density: 0.9,
        flowRate: 0.7
    },
    GAS: {
        name: 'Gas',
        baseColor: { hue: 180, saturation: 20, lightness: 90 },
        density: 0,
        flowRate: 0.9
    },
    VIRUS: {
        name: 'Virus',
        baseColor: { hue: 120, saturation: 100, lightness: 40 },
        density: 0,
        flowRate: 0.4
    },
    ERASER: {
        name: 'Eraser',
        baseColor: null
    }
};

export const GAME_SETTINGS = {
    DEFAULT_ELEMENT: 'SAND',
    DEFAULT_GRAVITY: 0.1,
    ELEMENT_SPREAD_MATRIX_RANGE: {
        MIN: 2,
        MAX: 16,
        STEP: 2
    },
    DEFAULT_SPREAD_MATRIX: 8,
    ELEMENT_SPREAD_PROBABILITY_RANGE: {
        MIN: 0.2,
        MAX: 0.8,
        STEP: 0.05
    },
    DEFAULT_SPREAD_PROBABILITY: 0.4,
    TREE_GENERATION_PROBABILITY: 0.005,
    OIL_RIG_GENERATION_PROBABILITY: 0.002
};