import React, { useState, useEffect, useRef } from 'react';
import { X, Loader2, Download, ArrowLeft, Undo2Icon, ChevronRight, AlertCircle, Loader } from 'lucide-react';
import { authenticatedApiCall } from '../../../utils/authenticatedApiCall';
import './DesignViewer.css';
import { useNavigate, Link } from 'react-router-dom';
import { isLoggedIn } from '../../../utils/cookies';
import { relatedImageCacheManager } from '../../ImageDetailRealtedImagesCache';
import { useDocumentHead } from '../../../hooks/useDocumentHead';
import ImageCard from '../../ImageCard';
import PromptSuggestions from './PromptSuggestion';
import useImageHandlers from '../../../hooks/image/useImageHandlers';


const DesignViewer = ({ design, onClose, type: initialType, customProps = {}, isFullPage = false }) => {
  const [input, setInput] = useState('');
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(design.image_url);
  const [currentDesign, setCurrentDesign] = useState(design);
  const [currentType, setCurrentType] = useState(initialType);
  const [relatedImages, setRelatedImages] = useState([]);
  const [isLoadingRelated, setIsLoadingRelated] = useState(false);
  // eslint-disable-next-line
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  // New state for generation tracking
  const [generationError, setGenerationError] = useState(null);
  const [generationCount, setGenerationCount] = useState(0);
  const [generationLimit, setGenerationLimit] = useState(50);
  const [generationReset, setGenerationReset] = useState(null);
  const [showPromptSuggestions, setShowPromptSuggestions] = useState(false);

  const [designHistory, setDesignHistory] = useState([design]);
  const [historyIndex, setHistoryIndex] = useState(0);

  const [showUndoButton, setShowUndoButton] = useState(false);
  const [previousDesignUrl, setPreviousDesignUrl] = useState(null);

  const [isSimilarImagesOpen, setIsSimilarImagesOpen] = useState(true);
  // eslint-disable-next-line
  const [hasInteractedWithSimilar, setHasInteractedWithSimilar] = useState(false);

  const [isGenerating, setIsGenerating] = useState(false); // New state for API call
  const [imageLoadError, setImageLoadError] = useState(false);

  const [downloadState, setDownloadState] = useState({
    status: 'default',
    message: ''
  });

  const relatedImagesScrollRef = useRef(null);
  const navigate = useNavigate();

  const { handleDownload } = useImageHandlers();

  const user = JSON.parse(localStorage.getItem('user'));
  const verifiedFollower = user && user.verified_follower ? user.verified_follower : false;

  useDocumentHead(
    `${currentDesign.title} by ${currentDesign.submitted_by} | Rangrez AI Design`,
    `Design ${currentDesign.title} by ${currentDesign.submitted_by} on Rangrez AI`
  );

  useEffect(() => {
    const newUrl = `/design/${design.id}`;

    if (window.location.pathname !== newUrl) {
      window.history.pushState({}, '', newUrl);
    }

    const handlePopState = () => {
      onClose();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [design.id, onClose]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });
  }, []);

  const fetchRelatedImages = async (imageId) => {
    if (!isLoggedIn() || !isSimilarImagesOpen) return;

    setIsLoadingRelated(true);
    try {
      const cachedImages = relatedImageCacheManager.get(imageId);
      if (cachedImages) {
        setRelatedImages(cachedImages);
        setIsLoadingRelated(false);
        return;
      }

      const relatedImagesResponse = await authenticatedApiCall(`/get-related-images/${imageId}`, 'GET');
      relatedImageCacheManager.set(imageId, relatedImagesResponse);
      setRelatedImages(relatedImagesResponse);
    } catch (error) {
      console.error('Error fetching related images:', error);
    } finally {
      setIsLoadingRelated(false);
    }
  };

  const overlayClass = isFullPage
    ? 'ldv-overlay-full-page'
    : `ldv-overlay ${isVisible ? 'visible' : ''}`;

  useEffect(() => {
    if (currentDesign.id && isSimilarImagesOpen) {
      fetchRelatedImages(currentDesign.id);
    }
    // eslint-disable-next-line
  }, [currentDesign.id, isSimilarImagesOpen]);

  const handleToggleSimilarImages = () => {
    setIsSimilarImagesOpen(prev => !prev);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsVisible(false);
    setTimeout(() => {
      setShouldRender(false);
      onClose();
    }, 300);
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
    setImageLoadError(false);
  };

  const handleSuggestionSelect = (suggestion) => {
    const formattedSuggestion = suggestion.replace(/\.\.\.$/, ' ');
    setInput(formattedSuggestion);
    setShowPromptSuggestions(false);
  };


  const handleInputFocus = () => {
    setShowPromptSuggestions(true);
  };

  const handleInputBlur = () => {
    // Delay to allow suggestion click to work
    setTimeout(() => {
      setShowPromptSuggestions(false);
    }, 200);
  };

  const handleImageError = () => {
    setIsImageLoading(false);
    setImageLoadError(true);
    setIsGenerating(false); // Make sure to reset generating state on error
  };

  const handleDownloadClick = async () => {
    setDownloadState({ status: 'loading', message: '' });
    const title = `${currentDesign.title} by ${currentDesign.submitted_by} on rangrezai.com`
    const result = await handleDownload(currentImageUrl, currentDesign.id, title, verifiedFollower);

    if (result.success) {
      setDownloadState({
        status: 'success',
        message: result.message
      });
    } else {
      setDownloadState({
        status: 'error',
        message: result.message
      });
    }
  };


  const formatTimeUntilReset = (resetTime) => {
    if (!resetTime) return '';

    // Parse the UTC time from the backend
    const reset = new Date(resetTime);
    const now = new Date();

    // Calculate the difference in milliseconds
    const diff = reset.getTime() - now.getTime();

    // Return early if invalid
    if (diff < 0) return 'less than 1 minute';

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours} hour${hours === 1 ? '' : 's'} ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? '' : 's'}`;
    } else {
      return 'less than 1 minute';
    }
  };

  const handleUpdateDesign = async () => {
    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }

    if (!input.trim()) return;

    setIsGenerating(true); // Set generating state for API call
    setGenerationError(null);
    setImageLoadError(false);
    setPreviousDesignUrl(currentImageUrl);

    try {
      const response = await authenticatedApiCall(
        `/update-${currentType}-design`,
        'POST',
        {
          imageUrl: currentImageUrl,
          userPrompt: input,
          originalDesignId: currentDesign.id,
        }
      );

      if (response.success && response.newImageUrl) {
        setIsImageLoading(true); // Set loading state for new image
        setCurrentImageUrl(response.newImageUrl);
        setShowUndoButton(true);

        if (response.generation_count !== undefined) {
          setGenerationCount(response.generation_count);
          setGenerationLimit(response.generation_limit);
          setGenerationReset(response.generation_reset);
        }
      }
    } catch (error) {
      console.error(`Error updating ${currentType}:`, error);
      if (error.response?.status === 429) {
        const data = error.response.data;
        setGenerationError({
          message: 'Hourly generation limit reached',
          count: data.current_count,
          reset: data.reset_time,
          limit: data.limit
        });
      } else {
        setGenerationError({
          message: `Error updating ${currentType}: ${error.message}`
        });
      }
    } finally {
      setIsGenerating(false); // Reset generating state after API call
      setInput('');
    }
  };

  const handleUndo = () => {
    if (previousDesignUrl) {
      setCurrentImageUrl(previousDesignUrl);
      setShowUndoButton(false);
      setPreviousDesignUrl(null);
      setImageLoadError(false);
    }
  };

  const handleRelatedImageClick = (image) => {
    // Update URL 
    const newUrl = `/design/${image.id}`;
    if (window.location.pathname !== newUrl) {
      window.history.pushState({}, '', newUrl);
    }

    // Set interaction and error states
    setHasInteractedWithSimilar(true);
    setImageLoadError(false);

    // Reset related images scroll
    if (relatedImagesScrollRef.current) {
      relatedImagesScrollRef.current.scrollTop = 0;
    }

    // Update design history
    setDesignHistory(prev => [...prev.slice(0, historyIndex + 1), {
      id: image.id,
      image_url: image.imageUrl,
      title: image.title,
      submitted_by: image.username
    }]);
    setHistoryIndex(prev => prev + 1);

    // Update current design state
    setCurrentDesign({
      id: image.id,
      image_url: image.imageUrl,
      title: image.title,
      submitted_by: image.username
    });

    // Update type and input placeholder if design type is available
    if (image.design_type) {
      setCurrentType(image.design_type);
      customProps.inputPlaceholder = `Describe how you want to modify this ${image.design_type}...`;
    }

    // Update current image URL
    setCurrentImageUrl(image.imageUrl);

    // Fetch related images for the new design
    fetchRelatedImages(image.id);
  };

  const handlePrevious = () => {
    if (historyIndex > 0) {
      const prevDesign = designHistory[historyIndex - 1];
      setHistoryIndex(prev => prev - 1);
      setCurrentDesign(prevDesign);
      setCurrentImageUrl(prevDesign.image_url);
      fetchRelatedImages(prevDesign.id);
      const newUrl = `/design/${prevDesign.id}`;
      if (window.location.pathname !== newUrl) {
        window.history.pushState({}, '', newUrl);
      }
    }
  };


  const handleKeyPress = (e) => {
    if (!isLoggedIn()) {
      navigate('/login');
      return;
    }
    if (e.key === 'Enter' && !isLoading && input.trim()) {
      handleUpdateDesign();
    }
  };

  const placeholderText = customProps.inputPlaceholder || `Describe how you want to modify this ${currentType}...`;

  if (!shouldRender) return null;

  return (
    <div
      className={overlayClass}
      onClick={!isFullPage ? handleClose : undefined}
    >
      <div className="ldv-container" onClick={e => e.stopPropagation()}>
        <button onClick={handleClose} className="ldv-close-button" aria-label="Close viewer">
          <X size={24} />
        </button>

        <div className="ldv-content">
          <div className="ldv-header">
            {historyIndex > 0 && (
              <button onClick={handlePrevious} className="ldv-previous-button" aria-label="Previous design">
                <ArrowLeft size={20} />
                <span>Back</span>
              </button>
            )}
            <h2 className="ldv-title">Update {currentType.charAt(0).toUpperCase() + currentType.slice(1)} Design</h2>
          </div>
          {/* Generation Status and Error Messages */}
          {generationError && (
            <div className="ldv-alert ldv-alert-error">
              <div className="ldv-alert-content">
                <p>{generationError.message}</p>
                {generationError.reset && (
                  <p className="ldv-alert-details">
                    {`${generationError.count}/${generationError.limit} generations used. Resets in ${formatTimeUntilReset(generationError.reset)}.`}
                  </p>
                )}
              </div>
            </div>
          )}

          {generationCount > 0 && (
            <div className="ldv-alert ldv-alert-info">
              <div className="ldv-alert-content">
                <p>
                  {`${generationCount}/${generationLimit} generations. Resets in ${formatTimeUntilReset(generationReset)}.`}
                  {generationLimit === 50 && (
                    <> <Link to="/follow" target='_blank'>Increase</Link></>
                  )}
                </p>
              </div>
            </div>
          )}

          <div className={`ldv-layout ${isSimilarImagesOpen ? 'similar-open' : ''}`}>
            <div className={`ldv-related-images ${isSimilarImagesOpen ? 'open' : ''}`}>
              <div className="ldv-related-header">
                <h3 className="ldv-related-title">Similar Designs</h3>
                <button
                  onClick={handleToggleSimilarImages}
                  className={`ldv-toggle-similar ${isSimilarImagesOpen ? 'open' : ''}`}
                  aria-label={isSimilarImagesOpen ? "Close similar designs" : "Open similar designs"}
                >
                  <ChevronRight size={20} />
                </button>
              </div>

              {isSimilarImagesOpen && (
                isLoadingRelated ? (
                  <div className="ldv-loading-related">
                    <Loader2 className="ldv-spinner animate-spin" size={24} />
                    <p>Loading similar designs...</p>
                  </div>
                ) : relatedImages.length > 0 ? (
                  <div className="ldv-images-scroll" ref={relatedImagesScrollRef}>
                    <div className="ldv-images-row">
                      {relatedImages.map((image, index) => (
                        <div
                          key={`${image.id}-${index}`}
                          className="ldv-related-item"
                          onClick={() => handleRelatedImageClick(image)}
                        >
                          <ImageCard
                            image={image}
                            index={index}
                            selectedImageIndex={selectedImageIndex}
                            handleLike={() => { }}
                            copyPromptToClipboard={() => { }}
                            handleDownload={() => { }}
                            handleReport={() => { }}
                            handleShareClick={() => { }}
                            buttonStates={{}}
                            likeInProgress={{}}
                            handleShowSettings={() => { }}
                            handleGetLikedByUsers={() => { }}
                            isLoggedIn={isLoggedIn()}
                            isRelatedImage={true}
                            minimal={true}
                            isCustomizedDesign={image?.is_customized || false}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : isLoggedIn() ? (
                  <p className="ldv-no-related">No similar designs found</p>
                ) : (
                  <p className="ldv-no-related">
                    <Link to="/login" className="ldv-login-link">Login</Link> to see similar designs.
                  </p>
                )
              )}
            </div>

            <div className="ldv-main-content">
              <div className="ldv-image-wrapper">
                {(isGenerating || isImageLoading) && !imageLoadError && (
                  <div className="ldv-loading-overlay">
                    <Loader2 className="ldv-spinner" size={48} />
                    <p>
                      {isGenerating ?
                        `Generating new ${currentType}...` :
                        `Loading new generated ${currentType}...`
                      }
                    </p>
                  </div>
                )}

                {imageLoadError ? (
                  <div className="ldv-error-container">
                    <div className="ldv-error-content">
                      <AlertCircle className="ldv-error-icon" size={24} />
                      <div className="ldv-error-message">
                        <h3 className="ldv-error-title">Image Loading Error</h3>
                        <p className="ldv-error-description">
                          Unable to load image from Pollinations.ai. The service might be temporarily unavailable. Please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={currentImageUrl}
                    alt={`${currentType} design`}
                    className={`ldv-image ${(isGenerating || isImageLoading) ? 'ldv-image-loading' : ''}`}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                )}

                <div className="ldv-image-actions">
                  <button
                    onClick={handleDownloadClick}
                    className="ldv-action-button"
                    aria-label={`Download ${currentType}`}
                    disabled={isGenerating || isImageLoading || imageLoadError}
                  >
                    {downloadState.status === 'loading' ? (
                      <Loader className="animate-spin" size={20} />
                    ) : (
                      <Download size={20} />
                    )}
                    <span className="ldv-action-text">
                      {downloadState.status === 'loading' ? '...' : 'Download'}
                    </span>
                  </button>
                  {showUndoButton && (
                    <button
                      onClick={handleUndo}
                      className="ldv-action-button"
                      aria-label="Undo last update"
                      disabled={isGenerating || isImageLoading}
                    >
                      <Undo2Icon size={20} />
                      <span className="ldv-action-text">Undo</span>
                    </button>
                  )}
                </div>
              </div>

              <div className="ldv-input-wrapper">
                {showPromptSuggestions && (
                  <PromptSuggestions
                    type={currentType}
                    onSuggestionSelect={handleSuggestionSelect}
                  />
                )}
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  className="ldv-input"
                  placeholder={placeholderText}
                  disabled={isGenerating || isImageLoading || (generationCount >= generationLimit)}
                />
                <button
                  onClick={handleUpdateDesign}
                  className="ldv-submit-button"
                  disabled={isGenerating || isImageLoading || !input.trim() || (generationCount >= generationLimit)}
                >
                  {isGenerating ? 'Updating...' : `Update ${currentType.charAt(0).toUpperCase() + currentType.slice(1)}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignViewer;