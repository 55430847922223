import React from 'react';
import './ModelSelector.css';

const models = [
    {
        name: 'flux',
        description: 'Balanced, versatile model',
        imageUrl: '/images/models/flux.png'
    },
    {
        name: 'turbo',
        description: 'Fast, lower-quality generation',
        imageUrl: '/images/models/turbo.png'
    },
    {
        name: 'hyperrealism',
        description: 'Photorealistic detailed images',
        imageUrl: '/images/models/hyperrealism.png'
    },
    {
        name: '3d-rendered',
        description: 'Crisp 3D-style renderings',
        imageUrl: '/images/models/3d-rendered.png'
    },
    {
        name: 'noir-gothic',
        description: 'Dark, dramatic aesthetic',
        imageUrl: '/images/models/noir-gothic.png'
    },
    {
        name: 'clean-minimal',
        description: 'Sleek, minimalist design',
        imageUrl: '/images/models/clean-minimal.png'
    },
    {
        name: 'futuristic-modern',
        description: 'Cutting-edge, tech-inspired',
        imageUrl: '/images/models/futuristic-modern.png'
    },
    {
        name: 'ethereal-fantasy',
        description: 'Dreamy, magical atmosphere',
        imageUrl: '/images/models/ethereal-fantasy.png'
    },
    {
        name: 'abstract-expressions',
        description: 'Creative, avant-garde styles',
        imageUrl: '/images/models/abstract-expressions.png'
    },
    {
        name: 'retro-vintage',
        description: 'Nostalgic, classic vibes',
        imageUrl: '/images/models/retro-vintage.png'
    }
];

const ModelSelector = ({ selectedModel, onModelChange, onClose }) => {
    const handleModelSelect = (modelName) => {
        onModelChange(modelName);
        onClose();
    };

    return (
        <div className="ms-selector">
            <div className="ms-selector-grid">
                {models.map((model) => (
                    <div 
                        key={model.name} 
                        className={`ms-model-card ${selectedModel === model.name ? 'ms-active' : ''}`}
                        onClick={() => handleModelSelect(model.name)}
                    >
                        <div className="ms-model-image-container">
                            <img 
                                src={model.imageUrl} 
                                alt={`${model.name} model preview`} 
                                className="ms-model-image" 
                            />
                        </div>
                        <div className="ms-model-details">
                            <h3 className="ms-model-name">{model.name.replace(/-/g, ' ')}</h3>
                            <p className="ms-model-description">{model.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ModelSelector;