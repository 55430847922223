// src/utils/sandGameUtils.js
import { ELEMENTS } from '../Components/Game/constants';
import { elementBehaviors } from './elementBehaviour';
import { checkWaterLavaInteraction } from './elementBehaviour/checkWaterLavaInteraction';

export const createGrid = (cols, rows) => {
    return Array.from({ length: cols }, () =>
        Array.from({ length: rows }, () => ({
            type: null,
            color: null,
            burnProgress: 0,
            lifetime: null
        }))
    );
};

export const isWithinBounds = (value, max) => value >= 0 && value <= max - 1;

export const calculateMousePosition = (e, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
        x: (e.clientX - rect.left) * scaleX,
        y: (e.clientY - rect.top) * scaleY
    };
};

export const createSandPhysicsSimulation = (cols, rows, squareSize) => {
    const withinCols = (i) => i >= 0 && i <= cols - 1;
    const withinRows = (j) => j >= 0 && j <= rows - 1;

    const updateElements = (grid, gravity = 0.1) => {
        let nextGrid = createGrid(cols, rows);

        // Process grid from bottom to top to prevent multiple updates
        for (let j = rows - 1; j >= 0; j--) {
            for (let i = 0; i < cols; i++) {
                const currentCell = grid[i][j];

                if (!currentCell.type) continue;

                if ((currentCell.type === 'WATER' || currentCell.type === 'LAVA') &&
                    checkWaterLavaInteraction(grid, nextGrid, i, j)) {
                    continue; // Skip further processing if interaction occurred
                }
                 // eslint-disable-next-line
                const element = ELEMENTS[currentCell.type];
                let moved = false;

                // Use the imported behaviors
                const behaviorFunc = elementBehaviors[currentCell.type];
                if (behaviorFunc) {
                    moved = behaviorFunc(grid, nextGrid, i, j, currentCell, gravity);
                } else {
                    // Handle special cases for elements without specific behaviors
                    switch (currentCell.type) {
                        case 'BRICK':
                        case 'WOOD':
                            nextGrid[i][j] = currentCell; // Immobile elements
                            break;
                        default:
                            nextGrid[i][j] = currentCell;
                    }
                }

                // If not moved and not immobile, try to preserve the element
                if (!moved &&
                    !['BRICK', 'WOOD', 'STONE', 'ROCK'].includes(currentCell.type)) {
                    nextGrid[i][j] = currentCell;
                }
            }
        }

        return nextGrid;
    };

    const dropSuspendedElements = (grid) => {
        let nextGrid = createGrid(cols, rows);
        let elementsMoved = false;

        // Process grid from bottom to top to prevent multiple updates
        for (let j = rows - 1; j >= 0; j--) {
            for (let i = 0; i < cols; i++) {
                const currentCell = grid[i][j];

                if (!currentCell.type) continue;

                // Check elements that should be affected by gravity
                const suspendedElements = [
                    'BRICK',
                    'WOOD',
                    'FLOWER',
                    'ICE',
                    'PLANT',
                    'CLOUD',
                    'OIL_RIG_SOURCE',
                    'BEEHIVE',
                    'BEE',
                    'GAS',
                    'VIRUS'
                ];
                if (suspendedElements.includes(currentCell.type)) {
                    // Try to move down
                    if (withinRows(j + 1) && !nextGrid[i][j + 1].type) {
                        nextGrid[i][j + 1] = currentCell;
                        elementsMoved = true;
                    } else {
                        // If can't move down, check diagonal moves
                        const diagonalMoves = [
                            { dx: -1, dy: 1 },
                            { dx: 1, dy: 1 }
                        ];

                        for (let { dx, dy } of diagonalMoves) {
                            const newX = i + dx;
                            const newY = j + dy;

                            if (withinCols(newX) && withinRows(newY) &&
                                !nextGrid[newX][newY].type &&
                                !grid[newX][newY].type) {
                                nextGrid[newX][newY] = currentCell;
                                elementsMoved = true;
                                break;
                            }
                        }

                        // If no movement possible, keep in original position
                        if (!nextGrid[i][j].type) {
                            nextGrid[i][j] = currentCell;
                        }
                    }
                } else if (!nextGrid[i][j].type) {
                    nextGrid[i][j] = currentCell;
                }
            }
        }

        return { nextGrid, elementsMoved };
    };

    const addElement = (grid, mouseX, mouseY, elementType, settings) => {
        const mouseCol = Math.floor(mouseX / squareSize);
        const mouseRow = Math.floor(mouseY / squareSize);
        const element = ELEMENTS[elementType];

        if (mouseCol < 0 || mouseCol >= cols || mouseRow < 0 || mouseRow >= rows)
            return grid;

        const { ELEMENT_SPREAD_MATRIX, ELEMENT_SPREAD_PROBABILITY } = settings;
        const matrix = ELEMENT_SPREAD_MATRIX;
        const extent = Math.floor(matrix / 2);
        const newGrid = grid.map(col => [...col]);

        for (let i = -extent; i <= extent; i++) {
            for (let j = -extent; j <= extent; j++) {
                if (Math.random() < ELEMENT_SPREAD_PROBABILITY) {
                    const col = mouseCol + i;
                    const row = mouseRow + j;

                    if (withinCols(col) && withinRows(row)) {
                        // Eraser logic
                        if (elementType === 'ERASER') {
                            newGrid[col][row] = { type: null, color: null };
                        } else {
                            // Color generation logic
                            const generateColor = () => {
                                switch (elementType) {
                                    case 'OIL':
                                        return `hsl(50, 100%, ${30 + Math.random() * 20}%)`;
                                    case 'GAS':
                                        return `hsl(120, 10%, ${80 + Math.random() * 15}%)`;
                                    case 'VIRUS':
                                        return `hsl(120, 100%, ${40 + Math.random() * 20}%)`;
                                    case 'FIRE':
                                        return `hsl(30, 100%, ${50 + Math.random() * 40}%)`;
                                    case 'ICE':
                                        return `hsl(200, 50%, 80%)`;
                                    case 'ROCK':
                                    case 'STONE':
                                        return `hsl(0, 0%, ${40 + Math.random() * 20}%)`;
                                    case 'BRICK':
                                        return `hsl(10, 70%, ${40 + Math.random() * 20}%)`;
                                    case 'WOOD':
                                        return `hsl(30, 50%, ${30 + Math.random() * 20}%)`;
                                    case 'MUD':
                                        return `hsl(40, 50%, 40%)`;
                                    default:
                                        return elementType !== 'ERASER'
                                            ? `hsl(${element.baseColor.hue}, ${element.baseColor.saturation}%, ${element.baseColor.lightness}%)`
                                            : null;
                                }
                            };

                            newGrid[col][row] = {
                                type: elementType,
                                color: generateColor(),
                                ...(elementType === 'FIRE' && { lifetime: 10 })
                            };
                        }
                    }
                }
            }
        }

        return newGrid;
    };

    return { updateElements, addElement, dropSuspendedElements };
};