// src/utils/elementBehaviors/oilBehavior.js
export const updateOilBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    if (j === rows - 1) {
        nextGrid[i][j] = currentCell;
        return false;
    }

    const below = withinRows(j + 1) ? grid[i][j + 1] : null;
    const belowLeft = withinCols(i - 1) && withinRows(j + 1) ? grid[i - 1][j + 1] : null;
    const belowRight = withinCols(i + 1) && withinRows(j + 1) ? grid[i + 1][j + 1] : null;
    const left = withinCols(i - 1) ? grid[i - 1][j] : null;
    const right = withinCols(i + 1) ? grid[i + 1][j] : null;

    // Oil floats on water
    if (below && below.type === 'WATER') {
        nextGrid[i][j] = currentCell;
        return true;
    }

    // Burning oil
    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    let isBurning = neighbors.some(({ x, y }) =>
        withinCols(x) && withinRows(y) &&
        grid[x][y].type === 'FIRE'
    );

    if (isBurning) {
        // If already in burn stage, progress the burning
        if (currentCell.oilBurnStage === undefined || currentCell.oilBurnStage === 0) {
            // First stage: Turn to fire
            nextGrid[i][j] = {
                type: 'FIRE',
                color: `hsl(30, 100%, ${50 + Math.random() * 40}%)`,
                lifetime: 10,
                oilBurnStage: 1
            };
        } else if (currentCell.oilBurnStage === 1) {
            // Second stage: Turn to smoke
            nextGrid[i][j] = {
                type: 'SMOKE',
                color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                lifetime: 5,
                oilBurnStage: 2
            };
        } else {
            // Final stage: Disappear
            nextGrid[i][j] = { type: null, color: null };
        }
        return true;
    }

    // Oil movement behavior
    if (!below || below.type === null) {
        nextGrid[i][j + 1] = currentCell;
        return true;
    }

    // Horizontal spreading
    if (Math.random() < 0.3) {
        if (left && left.type === null) {
            nextGrid[i - 1][j] = currentCell;
            return true;
        }

        if (right && right.type === null) {
            nextGrid[i + 1][j] = currentCell;
            return true;
        }
    }

    // Diagonal movement
    if (Math.random() < 0.5) {
        if (belowLeft && belowLeft.type === null) {
            nextGrid[i - 1][j + 1] = currentCell;
            return true;
        }

        if (belowRight && belowRight.type === null) {
            nextGrid[i + 1][j + 1] = currentCell;
            return true;
        }
    }

    nextGrid[i][j] = currentCell;
    return false;
};