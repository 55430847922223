import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../css/Unsubscribe.css';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';

function Unsubscribe() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const emailParam = urlParams.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address');
            return;
        }

        try {
            const response = await authenticatedApiCall('/unsubscribe', 'POST', { email });
            setMessage(response.message || 'Successfully unsubscribed.');
        } catch (err) {
            if (err.response.status === 403) {
                setError("Unauthorized: Can only unsubscribe your own email");
            } else {
                const errorMessage = err.response?.data?.error || 'An error occurred';
                setError(errorMessage);
            }
        }
    };

    return (
        <div className="unsubscribe-container">
            <div className="unsubscribe-box">
                <h2>Unsubscribe from Emails</h2>
                <p>Enter your email address to stop receiving general email notifications.</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Unsubscribe</button>
                </form>

                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
}

export default Unsubscribe;
