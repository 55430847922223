// ProfileActions.js
import React from 'react';
import { RefreshCw, Bell, Settings, Loader, ThumbsUp } from 'lucide-react';
import SocialLinks from '../SocialLinks';
import { AlertTriangle } from 'lucide-react';
import { UserPlus, UserMinus } from 'lucide-react';

const ProfileActions = ({
    isEditing,
    editedProfileData,
    profileData,
    onSocialLinksChange,
    isOwnProfile,
    handleRefresh,
    handleBellClick,
    handleRateClick,
    setIsSettingsOpen,
    isRefreshing,
    isLoadingNotifications,
    newNotification,
    showRateNow,
    renderAdultContentWarning,
    isComparisonLoading // Add this new prop
}) => {
    return (
        <div className='profile-actions-container'>
            {renderAdultContentWarning()}
            <div className="profile-social-and-setting-ref-btns">
                {isEditing ? (
                    <SocialLinks
                        socialLinks={editedProfileData.socialLinks}
                        isEditing={true}
                        onSocialLinksChange={onSocialLinksChange}
                    />
                ) : (
                    <SocialLinks
                        socialLinks={profileData.socialLinks}
                        isEditing={false}
                    />
                )}
                {isOwnProfile && (
                    <div className="profile-actions-settings-refresh">
                        <button
                            onClick={handleRefresh}
                            className="profile-icon-button"
                            title="Refresh Profile"
                            disabled={isRefreshing}
                        >
                            <RefreshCw size={20} className={isRefreshing ? 'animate-spin' : ''} />
                        </button>
                        <div className="profile-nav-item-container">
                            <button
                                onClick={handleRateClick}
                                className="profile-icon-button"
                                title="Rate Images"
                                disabled={isComparisonLoading}
                            >
                                {isComparisonLoading ? (
                                    <Loader size={20} className="animate-spin" />
                                ) : (
                                    <ThumbsUp size={20} />
                                )}
                            </button>
                            {showRateNow && (
                                <div className="profile-nav-notification-dot"></div>
                            )}
                        </div>
                        <div className="profile-nav-item-container">
                            <button
                                onClick={handleBellClick}
                                className="profile-icon-button"
                                title="View Notifications"
                                disabled={isLoadingNotifications}
                            >
                                {isLoadingNotifications ? (
                                    <Loader size={20} className="animate-spin" />
                                ) : (
                                    <Bell size={20} />
                                )}
                            </button>
                            {newNotification && (
                                <div className="profile-nav-notification-dot"></div>
                            )}
                        </div>
                        <button
                            onClick={() => setIsSettingsOpen(true)}
                            className="profile-icon-button"
                            title="Settings"
                        >
                            <Settings size={20} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};


// ProfileSkeleton.js
const ProfileSkeleton = () => {
    const renderProfileSkeleton = () => (
        <div className="profile-profile-card">
            <div className="profile-profile-header">
                <div className="profile-skeleton profile-skeleton-profile-image"></div>
                <div className="profile-profile-info">
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-name"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-username"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-email"></div>
                    <div className="profile-skeleton profile-skeleton-text profile-skeleton-bio"></div>
                    <div className="profile-stats">
                        <div className="profile-skeleton profile-skeleton-text profile-skeleton-button"></div>
                        <div className="profile-skeleton profile-skeleton-text profile-skeleton-button"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderImagesSkeleton = () => (
        <div className="profile-album-section">
            <div className="profile-album-header">
                <h2 className="profile-album-title">Generated Designs</h2>
            </div>
            <div className="profile-image-grid">
                {[...Array(11)].map((_, index) => (
                    <div key={index} className="profile-skeleton profile-skeleton-image"></div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="profile-profile-container margin">
            {renderProfileSkeleton()}
            {renderImagesSkeleton()}
        </div>
    );
};

// ProfileAdultWarning.js

const ProfileAdultWarning = ({ adultImagesCount }) => {
    if (adultImagesCount <= 15) return null;

    return (
        <div className="">
            <div className="adult-content-warning">
                <AlertTriangle className="adult-warning-icon" size={20} />
                <div>
                    {adultImagesCount >= 20 ? (
                        <p className="adult-warning-text">
                            Your account has been restricted. You cannot use the image generation tool until you delete adult content from your profile.
                        </p>
                    ) : (
                        <p className="adult-warning-text">
                            Your profile visibility is set to private due to excessive adult content.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

// ProfileFollowButton.js

const ProfileFollowButton = ({ isFollowing, followLoading, handleFollowAction }) => {
    return (
        <button
            className={`profile-follow-button ${isFollowing ? 'following' : ''}`}
            onClick={handleFollowAction}
            disabled={followLoading}
        >
            {followLoading ? (
                <Loader size={20} className="animate-spin" />
            ) : isFollowing ? (
                <>
                    <UserMinus size={20} />
                    <span>Unfollow</span>
                </>
            ) : (
                <>
                    <UserPlus size={20} />
                    <span>Follow</span>
                </>
            )}
        </button>
    );
};

export { ProfileActions, ProfileSkeleton, ProfileAdultWarning, ProfileFollowButton };