// src/utils/elementBehaviors/smokeBehavior.js
export const updateSmokeBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Lifetime management with slightly reduced lifetime
    const lifetime = currentCell.lifetime || 4;
    if (lifetime <= 0) {
        nextGrid[i][j] = { type: null, color: null };
        return true;
    }

    // Smoke rising and spreading behavior with smaller spread
    const riseDirections = [
        { x: i, y: j - 1 },      // directly up
        { x: i - 1, y: j - 1 },  // up-left
        { x: i + 1, y: j - 1 }   // up-right
    ];

    let moved = false;
    for (let { x, y } of riseDirections) {
        if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type && Math.random() < 0.2) {
            nextGrid[x][y] = {
                type: 'SMOKE',
                color: `hsl(0, 0%, ${60 + Math.random() * 20}%)`,
                lifetime: lifetime - 1
            };
            moved = true;
            break;
        }
    }

    // If smoke can't rise, try minimal horizontal spread
    if (!moved) {
        const spreadDirections = [
            { x: i - 1, y: j },
            { x: i + 1, y: j }
        ];

        for (let { x, y } of spreadDirections) {
            if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type && Math.random() < 0.1) {
                nextGrid[x][y] = {
                    type: 'SMOKE',
                    color: `hsl(0, 0%, ${60 + Math.random() * 20}%)`,
                    lifetime: lifetime - 1
                };
                moved = true;
                break;
            }
        }
    }

    // If no movement, stay in place
    if (!moved) {
        nextGrid[i][j] = {
            ...currentCell,
            lifetime: lifetime - 1,
            color: `hsl(0, 0%, ${60 + Math.random() * 20}%)`
        };
    }

    return true;
};