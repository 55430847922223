import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import ImageCard from '../ImageCard';
import Masonry from 'react-masonry-css';
import '../../css/ReportedImages.css';
import useLikeImage from '../../hooks/image/useLikeImage';
import useImageHandlers from '../../hooks/image/useImageHandlers';
import useMessage from '../../hooks/useNotify';

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
};

const ReportedImages = () => {
    const [reportedImages, setReportedImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { MessageDisplay, setMessage } = useMessage();

    const {
        images,
        setImages: setLikeImages,
        handleLike,
        likeInProgress,
        error: likeError
    } = useLikeImage(reportedImages);

    const {
        buttonStates,
        error: handlerError,
        handleDownload,
        handleReport,
        handleShareClick,
        handleShowSettings,
        handleCopyMarkdownLink,
    } = useImageHandlers();

    useEffect(() => {
        fetchReportedImages();
    }, []);

    useEffect(() => {
        setLikeImages(reportedImages);
    }, [reportedImages, setLikeImages]);

    useEffect(() => {
        if (likeError) {
            setError(likeError);
        }
    }, [likeError]);

    useEffect(() => {
        if (handlerError) {
            setError(handlerError);
        }
    }, [handlerError]);

    const fetchReportedImages = async () => {
        try {
            const response = await authenticatedApiCall('/admin/reported-images', 'GET');
            setReportedImages(response.reported_images);
            setLoading(false);
        } catch (err) {
            setError('Error fetching reported images: ' + err.message);
            setLoading(false);
        }
    };

    const handleRemoveReportedStatus = async (imageId) => {
        try {
            await authenticatedApiCall(`/admin/remove-reported-status/${imageId}`, 'POST');
            setReportedImages(prevImages => prevImages.filter(img => img.id !== imageId));
        } catch (err) {
            setError('Error removing reported status: ' + err.message);
        }
    };

    const handleDeleteImage = async (imageId) => {
        try {
            await authenticatedApiCall(`/admin/delete-image/${imageId}`, 'DELETE');
            setReportedImages(prevImages => prevImages.filter(img => img.id !== imageId));
        } catch (err) {
            setError('Error deleting image: ' + err.message);
        }
    };

    const handleToggleAdultStatus = async (imageId) => {
        try {
            const response = await authenticatedApiCall(`/admin/toggle-adult-status/${imageId}`, 'PUT');
    
            if (response.success) {
                setMessage("updated adult status", 'success');
            } else {
                setMessage("failed to updated adult status", 'error');
            }
        } catch (err) {
            setError('Error updating adult status: ' + err.message);
        }
    };

    if (loading) return <div className="ri-loading">Loading...</div>;
    if (error) return <div className="ri-error">{error}</div>;

    return (
        <div className="ri-reported-images">
            <MessageDisplay/>
            <h2>Reported Images</h2>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {images.map((image, index) => (
                    <div key={image.id} className="ri-image-card">
                        <ImageCard
                            image={{ ...image, isAdult: false }}
                            index={index}
                            isAdmin={true}
                            onRemoveReportedStatus={() => handleRemoveReportedStatus(image.id)}
                            onDeleteImage={() => handleDeleteImage(image.id)}
                            onToggleAdultStatus={() => handleToggleAdultStatus(image.id)}
                            handleLike={handleLike}
                            handleDownload={handleDownload}
                            handleReport={handleReport}
                            handleShareClick={handleShareClick}
                            buttonStates={buttonStates}
                            likeInProgress={likeInProgress}
                            handleCopyMarkdownLink={handleCopyMarkdownLink}
                            handleShowSettings={handleShowSettings}
                        />
                        <div className="ri-report-info">
                            <p>Times Reported: {image.reported_by.length} user(s)</p>
                            <p>Reported by: {image.reported_by.map(user => user).join(', ')}</p>
                            <p>Adult Status: {image.isAdult ? 'Yes' : 'No'}</p>
                            <p>Reason: {image.reason || 'No reason provided'}</p>
                            <p>Created at: {new Date(image.timestamp.$date).toLocaleString()}</p>
                        </div>

                    </div>
                ))}
            </Masonry>
        </div>
    );
};

export default ReportedImages;