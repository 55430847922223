import React from 'react';
import {
    Download, Loader, Check, Trash2, EyeOff, Eye,
    Image, ImageOff, Flag, Settings, User, Share2, LinkIcon
} from 'lucide-react';
import { Link } from 'react-router-dom';

const user = JSON.parse(localStorage.getItem('user'));
const verifiedFollower = user && user.verified_follower ? user.verified_follower : false;
const isLoggedIn = !!localStorage.getItem('token');

export const MoreOptions = ({
    showMoreOptions,
    image,
    buttonStates,
    handleShare,
    handleSettingsClick,
    onToggleAdultStatus,
    handleDownloadClick,
    isHidden,
    handleToggleHide,
    isBlurred,
    handleToggleBlur,
    handleSetAsProfileClick,
    handleDeleteClick,
    handleReportClick,
    isAdmin,
    isLikedImage,
    isOwnContentImages,
    isOwnProfile,
    isShowingInContent,
    isToolGenerated,
    onDeleteImage,
    onRemoveReportedStatus,
    moreOptionsRef,
    setShowMoreOptions,
    setActiveMoreOptions,
    handleCopyMarkdownLink
}) => {
    return (
        <div
            ref={moreOptionsRef}
            className={`ic-more-options ${showMoreOptions ? 'ic-show' : ''}`}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                className="ic-close-more-options-button"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreOptions(false);
                    setActiveMoreOptions(false);
                }}
            >
                X
            </div>
            {!isToolGenerated && (
                <div className="ic-image-title-mobile">{image.title}</div>
            )}
            {isLoggedIn && !verifiedFollower && (
                <Link to={'/follow'} className='ic-remove-watermark' target='_blank' rel="noopener noreferrer">Remove Watermark</Link>
            )}
            <button
                onClick={handleDownloadClick}
                className="ic-action-button"
                title="Download"
                disabled={buttonStates[image.id]?.download === 'loading'}
            >

                {buttonStates[image.id]?.download === 'loading' ? (
                    <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Downloading..</p></>
                ) : buttonStates[image.id]?.download === 'success' ? (
                    <><Check size={20} className="ic-action-icon" /><p>Downloded!</p></>
                ) : (
                    <><Download size={20} className="ic-action-icon" /><p>Download Design</p></>
                )}

            </button>
            {(!isToolGenerated || !isShowingInContent) && (
                <>
                    <button
                        onClick={handleShare}
                        className="ic-action-button"
                        title="Copy Design Share Link"
                        disabled={buttonStates[image.id]?.share === 'loading'}
                    >

                        {buttonStates[image.id]?.share === 'loading' ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Copying..</p></>
                        ) : buttonStates[image.id]?.share === 'success' ? (
                            <><Check size={20} className="ic-action-icon" /><p>Copied!</p></>
                        ) : (
                            <><Share2 size={20} className="ic-action-icon" /><p>Copy Share Link</p></>
                        )}

                    </button>
                    <button
                        onClick={handleSettingsClick}
                        className="ic-action-button"
                        title="View Settings"
                        disabled={buttonStates[image.id]?.settings === 'loading'}
                    >
                        {buttonStates[image.id]?.settings === 'loading' ? (
                            <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Loading...</p></>
                        ) : buttonStates[image.id]?.settings === 'success' ? (
                            <><Check size={20} className="ic-action-icon" /><p>Settings Loaded!</p></>
                        ) : (
                            <><Settings size={20} className="ic-action-icon" /><p>View Design Settings</p></>
                        )}
                    </button>
                    <button
                        onClick={handleCopyMarkdownLink}
                        className="ic-action-button"
                        title="Copy Markdown Link"
                        disabled={buttonStates[image.id]?.copyLink === 'loading'}
                    >
                        {buttonStates[image.id]?.copyLink === 'loading' ? (
                            <><Loader size={18} className="ic-action-icon ic-animate-spin" /><p>Copying...</p></>
                        ) : buttonStates[image.id]?.copyLink === 'success' ? (
                            <><Check size={18} className="ic-action-icon" /><p>Copied!</p></>
                        ) : (
                            <><LinkIcon size={18} className="ic-action-icon" /><p>Copy Markdown Link</p></>
                        )}
                    </button>
                </>
            )}
            {!isShowingInContent && !isOwnProfile && (
                <button
                    className='ic-action-button ic-hide'
                    onClick={handleToggleHide}
                    title={isHidden ? "Show Design" : "Hide Design"}
                >
                    {isHidden ? (
                        <><Eye size={20} className='ic-action-icon ic-info' /><p>Show Design</p></>
                    ) : (
                        <><EyeOff size={20} className='ic-action-icon ic-info' /><p>Hide Design</p></>
                    )}
                </button>
            )}
            {image.isAdult && isLoggedIn && (
                <button
                    className='ic-action-button ic-blur'
                    onClick={handleToggleBlur}
                    title={isBlurred ? "Unblur Design" : "Blur Design"}
                >
                    {isBlurred ? (
                        <><Image size={20} className='ic-action-icon ic-info' /><p>Unblur Design</p></>
                    ) : (
                        <><ImageOff size={20} className='ic-action-icon ic-info' /><p>Blur Design</p></>
                    )}
                </button>
            )}
            {isOwnProfile && !image.isAdult && (
                <button
                    onClick={handleSetAsProfileClick}
                    className="ic-action-button"
                    title="Set as Profile Picture"
                    disabled={buttonStates[image.id]?.setProfile === 'loading'}
                >
                    {buttonStates[image.id]?.setProfile === 'loading' ? (
                        <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Setting Profile...</p></>
                    ) : buttonStates[image.id]?.setProfile === 'success' ? (
                        <><Check size={20} className="ic-action-icon" /><p>Done!</p></>
                    ) : (
                        <><User size={20} className="ic-action-icon" /><p>Set as Profile Picture</p></>
                    )}
                </button>
            )}
            {(isOwnProfile || isOwnContentImages) && !isLikedImage && (
                <>
                    <button
                        onClick={handleDeleteClick}
                        className="ic-action-button ic-delete"
                        title="Delete"
                    >

                        <Trash2 size={20} className="ic-action-icon ic-delete" />
                        Delete Design
                    </button>
                </>
            )}
            {!isToolGenerated && !isOwnProfile && !isAdmin && (
                <button
                    onClick={handleReportClick}
                    className="ic-action-button ic-report"
                    title="Report Design"
                    disabled={buttonStates[image.id]?.report === 'loading'}
                >

                    {buttonStates[image.id]?.report === 'loading' ? (
                        <><Loader size={20} className="ic-action-icon ic-animate-spin" /><p>Reporting..</p></>
                    ) : buttonStates[image.id]?.report === 'success' ? (
                        <><Check size={20} className="ic-action-icon" /><p>Reported!</p></>
                    ) : (
                        <><Flag size={20} className="ic-action-icon" /><p>Report Design</p></>
                    )}

                </button>
            )}
            {isAdmin && (
                <>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggleAdultStatus();
                        }}
                        className="ic-action-button"
                        title="Change Adult Status"
                    >

                        <Flag size={20} className="ic-action-icon" />
                        Change Adult Status
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemoveReportedStatus();
                        }}
                        className="ic-action-button"
                        title="Remove Reported Status"
                    >

                        <Flag size={20} className="ic-action-icon" />
                        Remove Report by Admin
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteImage();
                        }}
                        className="ic-action-button ic-delete"
                        title="Delete Design"
                    >

                        <Trash2 size={20} className="ic-action-icon ic-delete" />
                        Delete Design by Admin
                    </button>
                </>
            )}
        </div>
    );
};