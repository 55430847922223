import React from 'react';
import './ContentGeneration.css';
import { Link } from 'react-router-dom';

const ContentGeneration = () => {
    return (
        <div className="icgc-container igh-container">
            <div className="igh-left-section">
                <div className="igh-images-container">
                    <Link to={"/design/673ee1dd45c73eb78deded8e"} className="igh-image-link">
                        <img src="../images/landing/logo-design-customized.png" alt="Logo Design" className="igh-image" />
                        <span>change text to Rangrez AI</span>
                    </Link>
                    <Link to={"/design/673ee1e745c73eb78deded8f"} className="igh-image-link">
                        <img src="../images/landing/art-design-customized.png" alt="Art Design" className="igh-image" />
                        <span>add colorful kites in sky and a singer with glowing mic in center</span>
                    </Link>
                    <Link to={"/design/673ee1ef45c73eb78deded90"} className="igh-image-link">
                        <img src="../images/landing/product-design-customized.png" alt="Product Design" className="igh-image" />
                        <span>add a bed of delicate pink rose petals at bottom, sitting on which is tumbler</span>
                    </Link>
                </div>
            </div>
            <div className="igh-right-section">
                <h1 className="igh-heading big-h">Customize as You Want</h1>
                <p className="igh-description landing-p">
                    Take control with Rangrez AI! Refine, personalize, and perfect your designs using intuitive AI tools. Bring your vision to life and join a thriving creator community. Start customizing now!
                </p>
                <div className="igh-button">
                    <Link to="/design">Explore Designs</Link>
                </div>
            </div>
        </div>
    );
};

export default ContentGeneration;
