// src/utils/elementBehaviors/iceBehaviour.js
export const updateIceBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Check surrounding cells for fire
    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    let meltByFire = false;
    neighbors.forEach(({ x, y }) => {
        if (withinCols(x) && withinRows(y)) {
            const neighborCell = grid[x][y];

            // Melt to water when touched by fire
            if (neighborCell.type === 'FIRE') {
                meltByFire = true;
            }
        }
    });

    if (meltByFire) {
        // Transform to water
        nextGrid[i][j] = {
            type: 'WATER',
            color: `hsl(210, 100%, 50%)`
        };
        return true;
    }

    // Slow falling behavior for ice
    if (withinRows(j + 1)) {
        // Primary attempt: move directly down
        if (!nextGrid[i][j + 1].type) {
            // Only move with a small probability to simulate slow, gentle falling
            if (Math.random() < gravity) {
                nextGrid[i][j + 1] = currentCell;
                return true;
            }
        }

        // Secondary attempts: move diagonally
        const diagonalMoves = [
            { x: i - 1, y: j + 1 },
            { x: i + 1, y: j + 1 }
        ];

        for (let { x, y } of diagonalMoves) {
            if (withinCols(x) && withinRows(y) && 
                !nextGrid[x][y].type && 
                !grid[x][y].type) {
                // Even lower probability for diagonal movement
                if (Math.random() < gravity / 2) {
                    nextGrid[x][y] = currentCell;
                    return true;
                }
            }
        }
    }

    // If no movement is possible, keep in original position
    nextGrid[i][j] = currentCell;
    return true;
};