// src/utils/elementBehaviors/sandBehavior.js
export const updateSandStoneBehavior = (grid, nextGrid, i, j, currentCell, gravity, cellType) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    if (j === rows - 1) {
        nextGrid[i][j] = currentCell;
        return false;
    }

    const below = withinRows(j + 1) ? grid[i][j + 1] : null;
    const belowLeft = withinCols(i - 1) && withinRows(j + 1) ? grid[i - 1][j + 1] : null;
    const belowRight = withinCols(i + 1) && withinRows(j + 1) ? grid[i + 1][j + 1] : null;
    const fire = [
        { x: i, y: j },
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    // Fire extinction logic (same as before)
    let isFireNearby = fire.some(({ x, y }) =>
        withinCols(x) && withinRows(y) &&
        grid[x][y].type === 'FIRE'
    );

    if (isFireNearby) {
        fire.forEach(({ x, y }) => {
            if (withinCols(x) && withinRows(y) && grid[x][y].type === 'FIRE') {
                nextGrid[x][y] = { type: null, color: null };
            }
        });
    }

    // Mud creation logic for sand
    if (cellType === 'SAND' && below && below.type === 'WATER' && Math.random() < 0.5) {
        nextGrid[i][j] = {
            type: 'MUD',
            color: `hsl(40, 50%, 40%)`
        };
        return true;
    }

    // Falling behavior (similar to previous implementation)
    if (!below || below.type === null) {
        nextGrid[i][j + 1] = currentCell;
        return true;
    }

    // Sliding behavior
    const slideChance = 0.5;

    if (Math.random() < slideChance) {
        if (belowLeft && belowLeft.type === null) {
            nextGrid[i - 1][j + 1] = currentCell;
            return true;
        }

        if (belowRight && belowRight.type === null) {
            nextGrid[i + 1][j + 1] = currentCell;
            return true;
        }
    }

    // If can't move, stay in place
    nextGrid[i][j] = currentCell;
    return false;
};