import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import ImageCard from '../ImageCard';
import Masonry from 'react-masonry-css';
import useLikeImage from '../../hooks/image/useLikeImage';
import useImageHandlers from '../../hooks/image/useImageHandlers';
import useMessage from '../../hooks/useNotify';
import './UnapprovedDesigns.css'

const DESIGN_TYPES = [
    'logo',
    'art',
    'sketch',
    'wallpaper',
    'product',
    'meme',
    'sticker',
    't-shirt-print',
    'coloring-page'
];

const DESIGN_STYLES = [
    'minimal',
    'modern',
    'vintage',
    'playful',
    'luxurious',
    'tech',
    'geometric',
    'classic',
    'elegant',
    'bold',
    'retro',
    'rustic',
    'urban',
    'natural',
    'soft',
    'casual',
    'line-art',
    '3d',
    'pixel-art',
    'watercolor',
    'neon',
    'digital-painting',
    'collage',
    'gradient',
    'traditional'
];

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
};

const UnapprovedDesigns = () => {
    const [unapprovedDesigns, setUnapprovedDesigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { MessageDisplay, setMessage } = useMessage();
    const [rejectionInProgress, setRejectionInProgress] = useState(false);
    const [editingDesign, setEditingDesign] = useState(null);

    const {
        images,
        setImages: setLikeImages,
        handleLike,
        likeInProgress,
        error: likeError
    } = useLikeImage(unapprovedDesigns);

    const {
        buttonStates,
        error: handlerError,
        handleDownload,
        handleReport,
        handleShareClick,
        handleShowSettings,
        handleCopyMarkdownLink,
    } = useImageHandlers();

    useEffect(() => {
        fetchUnapprovedDesigns();
    }, []);

    useEffect(() => {
        setLikeImages(unapprovedDesigns);
    }, [unapprovedDesigns, setLikeImages]);

    useEffect(() => {
        if (likeError) setError(likeError);
    }, [likeError]);

    useEffect(() => {
        if (handlerError) setError(handlerError);
    }, [handlerError]);

    const fetchUnapprovedDesigns = async () => {
        try {
            const response = await authenticatedApiCall('/admin/get-unapproved-images', 'GET');
            setUnapprovedDesigns(response.unapproved_designs);
            setLoading(false);
        } catch (err) {
            setError('Error fetching unapproved designs: ' + err.message);
            setLoading(false);
        }
    };

    const handleDesignAction = async (designId, action, additionalData = {}) => {
        try {
            const endpoint = `/designs/${designId}/${action}`;
            const method = 'PUT';

            const payload = {
                ...additionalData,
                design_type: additionalData.design_type || undefined,
                style: additionalData.style || undefined
            };

            const response = await authenticatedApiCall(endpoint, method, payload);

            if (response.success) {
                setUnapprovedDesigns(prevDesigns =>
                    prevDesigns.filter(design => design.id !== designId)
                );
                setMessage(response.message, 'success');
                setEditingDesign(null);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            setError(`Error ${action}ing design: ${err.message}`);
            setMessage(`Failed to ${action} design`, 'error');
        }
    };

    const handleApproveDesign = (design) => {
        const additionalData = editingDesign ? {
            design_type: editingDesign.design_type,
            style: editingDesign.style
        } : {};

        handleDesignAction(
            design.id,
            'approve',
            additionalData
        );
    };

    const handleRejectDesign = (design) => {
        handleDesignAction(design.id, 'reject');
    };

    const handleRejectAllByType = async (designType) => {
        if (!window.confirm(`Are you sure you want to reject all ${designType} designs?`)) {
            return;
        }

        setRejectionInProgress(true);
        try {
            const endpoint = `/admin/reject-all-${designType}-designs`;
            const response = await authenticatedApiCall(endpoint, 'PUT');

            if (response.success) {
                // Remove all designs of the specified type from the state
                setUnapprovedDesigns(prevDesigns =>
                    prevDesigns.filter(design =>
                        designType === 'customized' ?
                            !design.original_design_id :
                            !!design.original_design_id
                    )
                );
                setMessage(`All ${designType} designs rejected successfully`, 'success');
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            setError(`Error rejecting all ${designType} designs: ${err.message}`);
            setMessage(`Failed to reject all ${designType} designs`, 'error');
        } finally {
            setRejectionInProgress(false);
        }
    };

    const handleEditDesign = (design) => {
        setEditingDesign({
            id: design.id,
            design_type: design.design_type,
            style: design.style
        });
    };

    const handleUrltoPrompt = (url) => {
        const urlPath = new URL(url).pathname;
        const promptPart = urlPath.split('/prompt/')[1];
        if (promptPart) {
            return decodeURIComponent(promptPart.replace(/%20/g, ' '));
        }
        return 'No prompt available';
    };


    const updateEditingDesign = (field, value) => {
        setEditingDesign(prev => ({
            ...prev,
            [field]: value
        }));
    };

    if (loading) return <div className="ud-loading">Loading...</div>;
    if (error) return <div className="ud-error">{error}</div>;

    const customizedDesignsCount = images.filter(design => design.original_design_id).length;
    const originalDesignsCount = images.filter(design => !design.original_design_id).length;

    return (
        <div className="ud-unapproved-designs">
            <MessageDisplay />
            <div className="ud-header">
                <h2>Unapproved Designs</h2>
                <div className="ud-bulk-actions">
                    {customizedDesignsCount > 0 && (
                        <button
                            className="ud-reject-all-btn"
                            onClick={() => handleRejectAllByType('customized')}
                            disabled={rejectionInProgress}
                        >
                            Reject All Customized ({customizedDesignsCount})
                        </button>
                    )}
                    {originalDesignsCount > 0 && (
                        <button
                            className="ud-reject-all-btn"
                            onClick={() => handleRejectAllByType('original')}
                            disabled={rejectionInProgress}
                        >
                            Reject All Original ({originalDesignsCount})
                        </button>
                    )}
                </div>
            </div>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {images.map((design, index) => (
                    <div key={design.id} className="ud-design-card">
                        <ImageCard
                            image={design}
                            index={index}
                            isAdmin={true}
                            fromDashboard={true}
                            handleLike={handleLike}
                            handleDownload={handleDownload}
                            handleReport={handleReport}
                            handleShareClick={handleShareClick}
                            handleCopyMarkdownLink={handleCopyMarkdownLink}
                            buttonStates={buttonStates}
                            likeInProgress={likeInProgress}
                            handleShowSettings={handleShowSettings}
                        />
                        <div className="ud-design-info">
                            {editingDesign && editingDesign.id === design.id ? (
                                <div className="ud-edit-design">
                                    <div className="ud-edit-field">
                                        <label>Design Type:</label>
                                        <select
                                            value={editingDesign.design_type}
                                            onChange={(e) => updateEditingDesign('design_type', e.target.value)}
                                        >
                                            {DESIGN_TYPES.map(type => (
                                                <option key={type} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="ud-edit-field">
                                        <label>Design Style:</label>
                                        <select
                                            value={editingDesign.style}
                                            onChange={(e) => updateEditingDesign('style', e.target.value)}
                                        >
                                            {DESIGN_STYLES.map(style => (
                                                <option key={style} value={style}>{style}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="ud-edit-actions">
                                        <button
                                            className="ud-approve-btn"
                                            onClick={() => handleApproveDesign(design)}
                                        >
                                            Approve with Changes
                                        </button>
                                        <button
                                            className="ud-cancel-btn"
                                            onClick={() => setEditingDesign(null)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="ud-actions">
                                        <button
                                            className="ud-approve-btn"
                                            onClick={() => handleApproveDesign(design)}
                                        >
                                            Approve Design
                                        </button>
                                        <button
                                            className="ud-reject-btn"
                                            onClick={() => handleRejectDesign(design)}
                                        >
                                            Reject Design
                                        </button>
                                        <button
                                            className="ud-edit-btn"
                                            onClick={() => handleEditDesign(design)}
                                        >
                                            Edit Design
                                        </button>
                                    </div>
                                    <div className="ud-design-type">
                                        <span className="ud-label">Type:</span>
                                        <span className="ud-value">
                                            {design.original_design_id ? 'Customized Design' : 'Original Design'}
                                        </span>
                                    </div>
                                </>
                            )}
                            <div className="ud-design-details">
                                <p><strong>Title:</strong> {design.title}</p>
                                <p><strong>Design Type:</strong> {design.design_type}</p>
                                <p><strong>Style:</strong> {design.style}</p>
                                <p><strong>Final Prompt:</strong> {handleUrltoPrompt(design.imageUrl)}</p>
                                {design.original_design_id && (
                                    <>
                                        <p><strong>Original Prompt:</strong> {design.original_prompt}</p>
                                        <p><strong>User Prompt:</strong> {design.user_prompt}</p>
                                        <p><strong>Created By:</strong> {design.created_by}</p>
                                    </>
                                )}
                                <p><strong>Created At:</strong> {new Date(design.timestamp?.$date || design.created_at.$date).toLocaleString()}</p>
                                <p><strong>Tags:</strong> {design.tags.join(', ')}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Masonry>
        </div>
    );
};

export default UnapprovedDesigns;