// src/utils/elementBehaviors/waterBehavior.js
export const updateWaterBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    if (j === rows - 1 || i === 0 || i === cols - 1) {
        nextGrid[i][j] = currentCell;
        return true;
    }

    const below = withinRows(j + 1) ? grid[i][j + 1] : null;
    const belowLeft = withinCols(i - 1) && withinRows(j + 1) ? grid[i - 1][j + 1] : null;
    const belowRight = withinCols(i + 1) && withinRows(j + 1) ? grid[i + 1][j + 1] : null;
    const left = withinCols(i - 1) ? grid[i - 1][j] : null;
    const right = withinCols(i + 1) ? grid[i + 1][j] : null;

    // Extinguish fire
    if (below && below.type === 'FIRE') {
        nextGrid[i][j] = currentCell;
        nextGrid[i][j + 1] = { type: null, color: null };
        return true;
    }

    // Downward movement
    if (!below || below.type === null) {
        nextGrid[i][j + 1] = currentCell;
        return true;
    }

    // Diagonal movement with randomness
    if (Math.random() < 0.5) {
        if (belowLeft && belowLeft.type === null) {
            nextGrid[i - 1][j + 1] = currentCell;
            return true;
        }

        if (belowRight && belowRight.type === null) {
            nextGrid[i + 1][j + 1] = currentCell;
            return true;
        }
    }

    // Horizontal spreading
    if (Math.random() < 0.3) {
        if (left && left.type === null) {
            nextGrid[i - 1][j] = currentCell;
            return true;
        }

        if (right && right.type === null) {
            nextGrid[i + 1][j] = currentCell;
            return true;
        }
    }

    // Default: keep in place
    nextGrid[i][j] = currentCell;
    return true;
};