// ProfileInfo.js
import React from 'react';
import { formatTimestamp } from '../../../utils/dateFormatter';

const ProfileInfo = ({ profileData }) => {
  return (
    <>
      <h1 className="profile-profile-name">{profileData.fullName}</h1>
      <p className="profile-profile-username">@{profileData.username}</p>
      <p className="profile-profile-email">{profileData.email}</p>
      <p className="profile-profile-date">Joined: {formatTimestamp(profileData.joinedAt)}</p>
      <div className="profile-bio">
        <p>{profileData.bio || ""}</p>
      </div>
    </>
  );
};

export default ProfileInfo;