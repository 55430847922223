export const updateLavaBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Lifetime and mountain-like behavior
    const lifetime = currentCell.lifetime || 50;
    const mountainHeight = currentCell.mountainHeight || (Math.random() * 10 + 5);

    // Cooling and solidification mechanics
    if (lifetime <= 0) {
        nextGrid[i][j] = { type: 'STONE', color: `hsl(0, 0%, ${40 + Math.random() * 20}%)` };
        return true;
    }

    // Gravity and flow behavior
    const flowDirections = [
        { x: i, y: j + 1 },      // Directly down
        { x: i - 1, y: j + 1 },  // Down-left
        { x: i + 1, y: j + 1 }   // Down-right
    ];

    let moved = false;
    for (let { x, y } of flowDirections) {
        if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type) {
            nextGrid[x][y] = {
                type: 'LAVA',
                color: `hsl(10, 100%, ${40 + Math.random() * 20}%)`,
                lifetime: lifetime - 1,
                mountainHeight: mountainHeight
            };
            moved = true;
            break;
        }
    }

    // Periodic smoke and fire generation
    if (Math.random() < 0.01) {
        const smokeDirections = [
            { x: i, y: j - 1 },      // Up
            { x: i - 1, y: j - 1 },  // Up-left
            { x: i + 1, y: j - 1 }   // Up-right
        ];

        for (let { x, y } of smokeDirections) {
            if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type) {
                nextGrid[x][y] = {
                    type: 'SMOKE',
                    color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                    lifetime: 4
                };
                break;
            }
        }
    }

    // Periodic fire generation (less frequent)
    if (Math.random() < 0.08) {
        const fireDirections = [
            { x: i, y: j - 1 },
            { x: i - 1, y: j - 1 },
            { x: i + 1, y: j - 1 }
        ];

        for (let { x, y } of fireDirections) {
            if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type) {
                nextGrid[x][y] = {
                    type: 'FIRE',
                    color: `hsl(30, 100%, ${50 + Math.random() * 40}%)`,
                    lifetime: 8
                };
                break;
            }
        }
    }

    // If no movement, preserve the cell
    if (!moved) {
        nextGrid[i][j] = {
            ...currentCell,
            lifetime: lifetime - 1,
            color: `hsl(10, 100%, ${40 + Math.random() * 20}%)`
        };
    }

    return true;
};