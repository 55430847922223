// src/utils/elementBehaviors/ashBehavior.js
export const updateAshBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Lifetime management
    const lifetime = currentCell.lifetime || 50;
    if (lifetime <= 0) {
        nextGrid[i][j] = { type: null, color: null };
        return true;
    }

    // Ash falling behavior
    const fallDirections = [
        { x: i, y: j + 1 },      // directly down
        { x: i - 1, y: j + 1 },  // down-left
        { x: i + 1, y: j + 1 }   // down-right
    ];

    let moved = false;
    for (let { x, y } of fallDirections) {
        if (withinCols(x) && withinRows(y) && !nextGrid[x][y].type && Math.random() < 0.3) {
            nextGrid[x][y] = {
                type: 'ASH',
                color: `hsl(0, 0%, ${20 + Math.random() * 20}%)`,
                lifetime: lifetime - 1
            };
            moved = true;
            break;
        }
    }

    // If no movement, stay in place
    if (!moved) {
        nextGrid[i][j] = {
            ...currentCell,
            lifetime: lifetime - 1,
            color: `hsl(0, 0%, ${20 + Math.random() * 20}%)`
        };
    }

    return true;
};