export const updateVirusBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Virus spreads to adjacent elements
    const spreadableElements = ['WOOD', 'PLANT', 'WATER', 'MUD'];
    // eslint-disable-next-line
    const nonInfectableElements = ['STONE', 'ROCK', 'BRICK', 'ICE'];

    // Virus lifetime and mutation
    let lifetime = (currentCell.lifetime || 50) - 1;
    let mutationRate = Math.random() < 0.05; // 5% chance of mutation

    // Check if virus should decay
    if (lifetime <= 0) {
        nextGrid[i][j] = { type: null, color: null };
        return true;
    }

    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    let spread = false;
    neighbors.forEach(({ x, y }) => {
        if (withinCols(x) && withinRows(y)) {
            const neighborCell = grid[x][y];

            // Spread logic
            if (spreadableElements.includes(neighborCell.type) && Math.random() < 0.2) {
                let infectedColor;
                // eslint-disable-next-line
                switch (neighborCell.type) {
                    case 'WOOD':
                    case 'PLANT':
                        infectedColor = `hsl(270, 100%, ${20 + Math.random() * 20}%)`;
                        break;
                    case 'WATER':
                        infectedColor = `hsl(270, 80%, ${40 + Math.random() * 20}%)`;
                        break;
                    case 'MUD':
                        infectedColor = `hsl(270, 70%, ${30 + Math.random() * 20}%)`;
                        break;
                }

                nextGrid[x][y] = {
                    type: 'VIRUS',
                    color: infectedColor,
                    lifetime: lifetime
                };
                spread = true;
            }

            // Destruction by fire
            if (neighborCell.type === 'FIRE') {
                nextGrid[x][y] = { type: null, color: null };
            }
        }
    });

    // Mutation logic
    if (mutationRate) {
        // Randomly adjust spread rate or lifetime
        lifetime = Math.max(10, Math.min(100, lifetime + (Math.random() < 0.5 ? 10 : -10)));
    }

    // Update virus cell
    nextGrid[i][j] = {
        ...currentCell,
        lifetime: lifetime,
        color: `hsl(270, 100%, ${40 + Math.random() * 20}%)`
    };

    return spread;
};
