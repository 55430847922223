import React, { useState, useRef, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import './HomeSearch.css';

const HomeSearch = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [trendingTags, setTrendingTags] = useState([]);
  const searchRef = useRef(null);
  const searchTimeout = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // Fetch trending tags when component mounts or no query
    if (!query) {
      fetchTrendingTags();
    }
  }, [query]);

  const fetchTrendingTags = async () => {
    try {
      const response = await authenticatedApiCall('/trending-tags', 'GET');
      const randomTags = response.trending_tags
        .sort(() => 0.5 - Math.random())
        .slice(0, 9);
      setTrendingTags(randomTags);
    } catch (error) {
      console.error('Trending tags error:', error);
    }
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    setShowDropdown(true);

    // Debounce search
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      onSearch(newQuery);
    }, 300);
  };

  const handleClear = () => {
    setQuery('');
    onSearch('');
    fetchTrendingTags();
  };

  const handleTagClick = (tag) => {
    setQuery(tag);
    onSearch(tag);
    setShowDropdown(false);
  };

  const renderTags = () => {
    const tagsToShow = trendingTags;
    const tagClassName = 'home-tag home-trending-tag';

    return tagsToShow.length > 0 ? (
      <div className="home-suggested-tags">
        {tagsToShow.map((tag, index) => (
          <span
            key={index}
            className={tagClassName}
            onClick={() => handleTagClick(tag)}
          >
            {query ? tag : (
              <>
                <span className="home-trending-arrow">↑</span>
                {tag}
              </>
            )}
          </span>
        ))}
      </div>
    ) : null;
  };

  return (
    <div className="home-search-container" ref={searchRef}>
      <div className="home-search-input-wrapper">
        <input
          type="text"
          className="home-search-input"
          placeholder="Search any design..."
          value={query}
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
        />
        {query && (
          <button className="home-search-clear" onClick={handleClear}>
            ×
          </button>
        )}
      </div>

      {showDropdown && (
        <div className="home-search-dropdown">
          {renderTags()}
        </div>
      )}
    </div>
  );
};

export default HomeSearch;