// ProfileStats.js
import React from 'react';
import { Users, Heart } from 'lucide-react';

const ProfileStats = ({ profileData, toggleFollowersList, toggleFollowingList }) => {
  return (
    <div className="profile-stats">
      <button className="profile-stat-button" onClick={toggleFollowersList}>
        <Users size={20} />
        <span>{profileData.followerCount} Followers</span>
      </button>
      <button className="profile-stat-button" onClick={toggleFollowingList}>
        <Users size={20} />
        <span>{profileData.followingCount} Following</span>
      </button>
      <button className="profile-stat-button profile-stat-likes">
        <Heart size={20} />
        <span>{profileData.totalLikes || 0} Likes</span>
      </button>
    </div>
  );
};

export default ProfileStats;
