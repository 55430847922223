// src/utils/elementBehaviors/fireBehavior.js
export const updateFireBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Lifetime management
    const lifetime = currentCell.lifetime || 10;
    if (lifetime <= 0) {
        nextGrid[i][j] = { type: null, color: null };
        return true;
    }

    // All possible neighbor directions
    const allNeighbors = [
        { x: i - 1, y: j },     // Left
        { x: i + 1, y: j },     // Right
        { x: i, y: j - 1 },     // Up
        { x: i, y: j + 1 },     // Down
        { x: i - 1, y: j - 1 }, // Up-Left
        { x: i + 1, y: j - 1 }, // Up-Right
        { x: i - 1, y: j + 1 }, // Down-Left
        { x: i + 1, y: j + 1 }  // Down-Right
    ];
// eslint-disable-next-line
    let fireSpread = false;

    // Comprehensive fire spread logic
    allNeighbors.forEach(({ x, y }) => {
        if (withinCols(x) && withinRows(y)) {
            const neighborCell = grid[x][y];

            // Fire spread to wood
            if (neighborCell.type === 'WOOD') {
                const spreadProbability = 0.4;
                
                if (Math.random() < spreadProbability) {
                    // Create new fire cell
                    nextGrid[x][y] = {
                        type: 'FIRE',
                        color: `hsl(30, 100%, ${50 + Math.random() * 50}%)`,
                        lifetime: 8 + Math.floor(Math.random() * 6)
                    };

                    fireSpread = true;

                    // Smoke generation in multiple directions
                    const smokeDirections = [
                        { x, y: y - 1 },      // Up
                        { x: x - 1, y },      // Left
                        { x: x + 1, y },      // Right
                        { x, y: y + 1 }       // Down
                    ];

                    smokeDirections.forEach(({ x: smokeX, y: smokeY }) => {
                        if (withinCols(smokeX) && withinRows(smokeY) && 
                            !nextGrid[smokeX][smokeY].type && Math.random() < 0.3) {
                            nextGrid[smokeX][smokeY] = {
                                type: 'SMOKE',
                                color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                                lifetime: 3 + Math.floor(Math.random() * 4)
                            };
                        }
                    });
                }
            }

            // Fire spread to oil with different behavior
            if (neighborCell.type === 'OIL') {
                const spreadProbability = 0.6;
                
                if (Math.random() < spreadProbability) {
                    // Gradually turn oil to fire, then smoke, then disappear
                    if (!neighborCell.oilBurnStage || neighborCell.oilBurnStage === 0) {
                        // First stage: Fire starts on oil
                        nextGrid[x][y] = {
                            type: 'FIRE',
                            color: `hsl(30, 100%, ${50 + Math.random() * 50}%)`,
                            lifetime: 10,
                            oilBurnStage: 1
                        };
                    } else if (neighborCell.oilBurnStage === 1) {
                        // Second stage: Oil turning to smoke
                        nextGrid[x][y] = {
                            type: 'SMOKE',
                            color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                            lifetime: 5,
                            oilBurnStage: 2
                        };
                    } else {
                        // Final stage: Disappear
                        nextGrid[x][y] = { type: null, color: null };
                    }

                    fireSpread = true;
                }
            }
        }
    });

    // Update current fire cell
    nextGrid[i][j] = {
        ...currentCell,
        lifetime: lifetime - 1,
        color: `hsl(30, 100%, ${70 + Math.random() * 30}%)`
    };

    // Fire extinction logic
    const woodNeighbors = allNeighbors.filter(({ x, y }) => 
        withinCols(x) && withinRows(y) && 
        (grid[x][y].type === 'WOOD' || grid[x][y].type === 'OIL')
    );

    if (woodNeighbors.length === 0) {
        // When no combustible materials remain, gradually extinguish
        if (Math.random() < 0.7) {
            nextGrid[i][j] = { type: null, color: null };
        }
    }

    return true;
};