// src/utils/elementBehaviors/beeBehavior.js
export const updateBeeBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Check for deadly interactions
    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 },
        { x: i - 1, y: j - 1 }, { x: i + 1, y: j - 1 },
        { x: i - 1, y: j + 1 }, { x: i + 1, y: j + 1 }
    ];

    // Die if touching virus, fire, or smoke
    const isDead = neighbors.some(({ x, y }) => 
        withinCols(x) && withinRows(y) && 
        ['VIRUS', 'FIRE', 'SMOKE', 'OIL'].includes(grid[x][y].type)
    );

    if (isDead) {
        nextGrid[i][j] = { type: null, color: null };
        return true;
    }

    // Eat plants
    const plantNeighbors = neighbors.filter(({ x, y }) => 
        withinCols(x) && withinRows(y) && grid[x][y].type === 'PLANT'
    );

    if (plantNeighbors.length > 0) {
        // Choose a random plant neighbor to eat
        const { x, y } = plantNeighbors[Math.floor(Math.random() * plantNeighbors.length)];
        
        // Eat the plant (clear the plant cell)
        nextGrid[x][y] = { type: null, color: null };
        
        // Bee stays in its current position
        nextGrid[i][j] = currentCell;
        return true;
    }

    // Random movement with more complex behavior
    const movementOptions = [
        { dx: -1, dy: -1 }, { dx: 0, dy: -1 }, { dx: 1, dy: -1 },
        { dx: -1, dy: 0 },                     { dx: 1, dy: 0 },
        { dx: -1, dy: 1 }, { dx: 0, dy: 1 }, { dx: 1, dy: 1 }
    ];

    // Weighted movement - tendency to hover and sometimes land
    const shouldMove = Math.random() < 0.7;
    if (shouldMove) {
        // Randomly select a movement option
        const { dx, dy } = movementOptions[Math.floor(Math.random() * movementOptions.length)];
        const newX = i + dx;
        const newY = j + dy;

        // Check if new position is within bounds
        if (withinCols(newX) && withinRows(newY)) {
            const targetCell = grid[newX][newY];

            // Special landing behavior
            if (!targetCell.type || 
                targetCell.type === 'WATER' || 
                targetCell.type === 'PLANT') {
                nextGrid[newX][newY] = currentCell;
                return true;
            }
        }
    }

    // If no movement, stay in place
    nextGrid[i][j] = currentCell;
    return false;
};