// src/utils/elementBehaviors/plantBehavior.js
export const updatePlantBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Check surrounding cells for water and fire
    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    let waterNearby = false;
    let fireNearby = false;

    neighbors.forEach(({ x, y }) => {
        if (withinCols(x) && withinRows(y)) {
            const neighborCell = grid[x][y];

            if (neighborCell.type === 'WATER') {
                waterNearby = true;
            }
            if (neighborCell.type === 'FIRE') {
                fireNearby = true;
            }
        }
    });

    // Burn to ash when fire is nearby
    if (fireNearby) {
        nextGrid[i][j] = {
            type: 'ASH',
            color: `hsl(0, 0%, 20%)`
        };
        return true;
    }

    // Grow or stay in place when water is nearby
    if (waterNearby && Math.random() < 0.1) {
        const growthDirections = [
            { x: i, y: j - 1 },  // Prefer upward growth
            { x: i - 1, y: j },
            { x: i + 1, y: j }
        ];
    
        for (let { x, y } of growthDirections) {
            if (withinCols(x) && withinRows(y) && !grid[x][y].type) {
                // Add flower when reaching a certain height
                const isFlower = j < 3 && Math.random() < 10;
                nextGrid[x][y] = {
                    type: isFlower ? 'FLOWER' : 'PLANT',
                    color: isFlower 
                        ? `hsl(${Math.random() * 360}, 70%, ${50 + Math.random() * 20}%)` 
                        : `hsl(120, 70%, ${40 + Math.random() * 20}%)`
                };
                break;
            }
        }
    }

    // Keep plant in place or support bottom-up growth
    if (j === rows - 1 && Math.random() < 0.1) {
        // Grow upwards from the bottom
        const growUpwards = { x: i, y: j - 1 };
        if (withinCols(growUpwards.x) && withinRows(growUpwards.y) && !grid[growUpwards.x][growUpwards.y].type) {
            nextGrid[growUpwards.x][growUpwards.y] = {
                type: 'PLANT',
                color: `hsl(120, 70%, ${40 + Math.random() * 20}%)`
            };
        }
    }

    nextGrid[i][j] = currentCell;
    return true;
};