// src/utils/elementBehaviors/flowerBehavior.js
export const updateFlowerBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Flower-specific color variations
    const flowerColors = [
        { hue: 0, name: 'Red Rose' },     // Red
        { hue: 60, name: 'Sunflower' },   // Yellow
        { hue: 300, name: 'Orchid' },     // Pink
        { hue: 270, name: 'Lavender' },   // Purple
        { hue: 180, name: 'Aqua Bloom' }, // Cyan
        { hue: 30, name: 'Peach Bloom' }  // Orange
    ];

    // Decaying and falling behavior
    if (Math.random() < 0.05) {
        // Slight chance to decay or fall
        if (withinRows(j + 1) && !grid[i][j + 1].type) {
            // Gently fall down
            nextGrid[i][j + 1] = {
                type: 'FLOWER',
                color: currentCell.color,
                decay: (currentCell.decay || 0) + 1
            };
            return true;
        }
    }

    // Regenerate color with subtle variation to create a more natural look
    if (Math.random() < 0.1) {
        const selectedColor = flowerColors[Math.floor(Math.random() * flowerColors.length)];
        currentCell.color = `hsl(${selectedColor.hue}, 70%, ${50 + Math.random() * 20}%)`;
    }

    // Withering mechanism
    if (currentCell.decay && currentCell.decay > 100) {
        // Turn into ash after extended time
        nextGrid[i][j] = {
            type: 'ASH',
            color: `hsl(0, 0%, 20%)`
        };
        return true;
    }

    // Neighbor interaction
    const neighbors = [
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 }
    ];

    let waterNearby = false;
    let fireNearby = false;

    neighbors.forEach(({ x, y }) => {
        if (withinCols(x) && withinRows(y)) {
            const neighborCell = grid[x][y];

            if (neighborCell.type === 'WATER') {
                waterNearby = true;
            }
            if (neighborCell.type === 'FIRE') {
                fireNearby = true;
            }
        }
    });

    // Flower reactions
    if (fireNearby) {
        // Burn to ash if fire is near
        nextGrid[i][j] = {
            type: 'ASH',
            color: `hsl(0, 0%, 20%)`
        };
        return true;
    }

    // Slightly extend lifetime with nearby water
    if (waterNearby && Math.random() < 0.2) {
        currentCell.decay = Math.max(0, (currentCell.decay || 0) - 1);
    }

    nextGrid[i][j] = currentCell;
    return true;
};