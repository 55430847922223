import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import './SettingsPanel.css';
import DimensionControls from './DimensionControls';
import ModelSelector from './ModelSelector';

const SettingsPanel = ({ formData, handleChange, fieldOptions, dimensionPresets }) => {
    const [activeSelector, setActiveSelector] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleSelectorOpen = (field, event) => {
        event.stopPropagation();
        event.preventDefault();
        setActiveSelector(field);

        // Use setTimeout to allow DOM to update before adding active class
        setTimeout(() => {
            setIsVisible(true);
        }, 10);
    };

    const handleSelectorClose = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setIsVisible(false);

        // Wait for transition to complete before clearing active selector
        setTimeout(() => {
            setActiveSelector(null);
        }, 300);
    };

    const handleOptionChange = (field, value) => {
        handleChange({
            target: {
                name: field,
                value: value
            }
        });
    };

    // Close on Escape key
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape' && activeSelector) {
                handleSelectorClose();
            }
        };

        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [activeSelector]);

    return (
        <div className="set-container">
            {/* Number of generations */}
            <div className="set-field-group">
                <label className="set-label">Number of Generations:</label>
                <select
                    name="numImages"
                    value={formData.numImages}
                    onChange={handleChange}
                    className="set-select"
                >
                    {[1, 2, 3, 4].map(num => (
                        <option key={num} value={num}>{num}</option>
                    ))}
                </select>
            </div>

            {/* Model selector */}
            <div className="set-field-group">
                <label className="set-label">Model:</label>
                <button
                    onClick={(e) => handleSelectorOpen('model', e)}
                    className="set-button"
                >
                    {formData.model}
                </button>
            </div>

            {/* Dimension presets */}
            <DimensionControls
                formData={formData}
                handleChange={handleChange}
                dimensionPresets={dimensionPresets}
            />

            {/* Model Selector Modal */}
            {activeSelector === 'model' && (
                <div
                    className={`set-overlay ${isVisible ? 'active' : ''}`}
                    onClick={handleSelectorClose}
                >
                    <div
                        className={`set-selector ${isVisible ? 'active' : ''}`}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="set-selector-header">
                            <h3 className="set-selector-title">Select Model</h3>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleSelectorClose(e);
                                }}
                                className="set-close-button"
                            >
                                <X size={20} />
                            </button>
                        </div>
                        <ModelSelector
                            selectedModel={formData.model}
                            onModelChange={(model) => handleOptionChange('model', model)}
                            onClose={handleSelectorClose}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SettingsPanel;