import React, { useEffect, useRef, useState } from 'react';
import { PencilIcon, Lock, Unlock, LogOut, UserX } from 'lucide-react';
import ThemeSwitcher from './ThemeSwitcher';
import '../../css/ProfileSettings.css'
import ProfileCardShare from './ProfileCardShare';

const ProfileSettings = ({
    isOpen,
    onClose,
    onEditProfile,
    onToggleVisibility,
    onLogout,
    onDeleteAccount,
    isPublic,
    isDeleting,
    username,
    imageUrl
}) => {
    const menuRef = useRef(null);
    const [theme, setTheme] = useState(() =>
        document.documentElement.getAttribute('data-theme') || 'light'
    );

    const handleThemeChange = (newTheme) => {
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('theme', newTheme);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="settings-overlay">
            <div ref={menuRef} className="settings-popup">
                <button onClick={() => {
                    onEditProfile();
                    onClose();
                }}
                    className="settings-button"
                >
                    <PencilIcon size={20} />
                    <span>Edit Profile</span>
                </button>

                <button onClick={onToggleVisibility} className="settings-button">
                    {isPublic ? <Unlock size={20} /> : <Lock size={20} />}
                    <span>{isPublic ? 'Lock Profile' : 'Unlock Profile'}</span>
                </button>

                <ProfileCardShare username={username} imageUrl={imageUrl} />

                <div className="theme-section">
                    <h3>Theme</h3>
                    <ThemeSwitcher currentTheme={theme} onThemeChange={handleThemeChange} />
                </div>

                <button onClick={onLogout} className="settings-button">
                    <LogOut size={20} />
                    <span>Logout</span>
                </button>

                <button
                    onClick={onDeleteAccount}
                    disabled={isDeleting}
                    className="settings-button delete"
                >
                    <UserX size={20} />
                    <span>Delete Account</span>
                </button>
            </div>
        </div>
    );
};

export default ProfileSettings;