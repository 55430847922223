// src/utils/image/copyMarkdown.js

export const copyMarkdownLinkToClipboard = async (imageUrl, imageTitle) => {
  try {
    const markdownLink = `![${imageTitle}](${imageUrl})`;
    await navigator.clipboard.writeText(markdownLink);
    return { success: true, message: 'Markdown link copied to clipboard!' };
  } catch (err) {
    console.error('Failed to copy markdown link: ', err);
    return { success: false, message: 'Failed to copy markdown link' };
  }
};