import React, { useEffect, useState } from 'react';
import '../css/RandomLoader.css';
import '../css/RandomLoaderMetronome.css';

const RandomLoader = ({ className = "" }) => {
  const [batonWidth, setBatonWidth] = useState(205);

  useEffect(() => {
    // Generate random width between 100 and 200
    const randomWidth = Math.floor(Math.random() * (200 - 100 + 1)) + 100;
    setBatonWidth(randomWidth);
  }, []);

  const MetronomeLoader = () => (
    <div className="lp-metronome-container">
      {[...Array(36)].map((_, i) => (
        <div key={i} className={`lp-baton-${i}`}>
          <div className="lp-metronome">
            <div className="lp-baton" style={{ width: `${batonWidth}px` }}></div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`lp-loader-container ${className}`}>
      <MetronomeLoader />
    </div>
  );
};

export default RandomLoader;