// src/utils/elementBehaviors/cloudBehavior.js
export const updateCloudBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Limit cloud spread with a maximum cloud group size
    const MAX_CLOUD_WIDTH = 10;  // Adjust this value to control cloud spread
    const MAX_CLOUD_HEIGHT = 5;  // Maximum vertical extent

    // Count nearby clouds
    const checkCloudSpread = (grid, centerX, centerY) => {
        let cloudCount = 0;
        const cloudNeighbors = [
            { x: centerX - 1, y: centerY }, { x: centerX + 1, y: centerY },
            { x: centerX, y: centerY - 1 }, { x: centerX, y: centerY + 1 }
        ];

        cloudNeighbors.forEach(({ x, y }) => {
            if (withinCols(x) && withinRows(y) && grid[x][y].type === 'CLOUD') {
                cloudCount++;
            }
        });

        return cloudCount;
    };

    // Prevent excessive cloud spread
    const cloudSpread = checkCloudSpread(grid, i, j);
    if (cloudSpread > 3) {
        nextGrid[i][j] = currentCell;
        return true;
    }

    // Clouds move towards the top and float horizontally with controlled spreading
    if (j > 0) {
        const left = withinCols(i - 1) ? grid[i - 1][j] : null;
        const right = withinCols(i + 1) ? grid[i + 1][j] : null;
        const above = withinRows(j - 1) ? grid[i][j - 1] : null;

        // Limit vertical extent
        const verticalExtent = j < MAX_CLOUD_HEIGHT;

        // Move up if possible and within vertical limit
        if (verticalExtent && (!above || above.type === null)) {
            nextGrid[i][j - 1] = currentCell;
            return true;
        }

        // Horizontal floating with randomness and spread control
        if (Math.random() < 0.3) {
            // Ensure horizontal spread doesn't exceed MAX_CLOUD_WIDTH
            const horizontalSpread = grid.filter(col => 
                col[j].type === 'CLOUD' || (col[j].type === null && Math.random() < 0.3)
            ).length;

            if (horizontalSpread < MAX_CLOUD_WIDTH) {
                if (left && left.type === null) {
                    nextGrid[i - 1][j] = currentCell;
                    return true;
                }
                if (right && right.type === null) {
                    nextGrid[i + 1][j] = currentCell;
                    return true;
                }
            }
        }

        // Cloud generation logic with reduced frequency
        if (cloudSpread >= 2 && Math.random() < 0.1) {
            const waterDirections = [
                { x: i, y: j + 1 },
                { x: i - 1, y: j + 1 },
                { x: i + 1, y: j + 1 }
            ];

            for (let { x, y } of waterDirections) {
                if (withinCols(x) && withinRows(y) && !grid[x][y].type) {
                    nextGrid[x][y] = {
                        type: 'WATER',
                        color: `hsl(210, 100%, 50%)`
                    };
                    break;
                }
            }
        }
    }

    // Keep cloud in place or move
    nextGrid[i][j] = currentCell;
    return true;
};