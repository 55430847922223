import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import RandomLoader from '../../RandomLoader';

const GeneratedImage = ({
  isLoading,
  generatedImageUrls,
  isMobileView
}) => {
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [failedImages, setFailedImages] = useState(new Set());

  const handleImageLoad = (url) => {
    setLoadedImages(prev => new Set([...prev, url]));
    setFailedImages(prev => {
      const newSet = new Set(prev);
      newSet.delete(url);
      return newSet;
    });
  };

  const handleImageError = (url) => {
    setFailedImages(prev => new Set([...prev, url]));
    setLoadedImages(prev => {
      const newSet = new Set(prev);
      newSet.delete(url);
      return newSet;
    });
  };

  // Check if all images failed to load
  const allImagesFailed = generatedImageUrls?.length > 0 && 
    generatedImageUrls.every(url => failedImages.has(url));

  if (isLoading || !generatedImageUrls || generatedImageUrls.length === 0) return null;

  const isMultipleImages = generatedImageUrls.length > 1;

  if (allImagesFailed) {
    return (
      <div className="error-container">
        <div className="error-content">
          <AlertCircle className="error-icon" />
          <div className="error-message-content">
            <h3 className="error-title">Image Loading Error</h3>
            <p className="error-description">Unable to load images from Pollinations.ai. The service might be temporarily unavailable. Please try again later.</p>
          </div>
        </div>
      </div>
    );
  }  

  return (
    <div className={`imgen-generated-images ${isMobileView ? 'mobile' : 'desktop'}`}>
      <h2 className="imgen-image-title">Generated Designs</h2>
      <div className={`imgen-images-grid ${isMultipleImages ? 'multi-column' : ''}`}>
        {generatedImageUrls.map((url, index) => (
          <div
            key={`${url}-${index}-${isMobileView ? 'mobile' : 'desktop'}`}
            className={`imgen-generated-image-container ${isMultipleImages ? 'grid-item' : ''}`}
          >
            {!loadedImages.has(url) && !failedImages.has(url) && (
              <div className="imgen-loading-spinner">
                <RandomLoader />
              </div>
            )}
            {failedImages.has(url) && (
              <div className="p-4 rounded-md bg-red-50 text-sm text-red-700">
                Failed to load image {index + 1}. Please try refreshing.
              </div>
            )}
            {!failedImages.has(url) && (
              <div
                className="imgen-image-wrapper"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100%',
                  margin: '0 auto',
                  display: isMobileView ?
                    window.innerWidth < 768 ? 'block' : 'none' :
                    window.innerWidth >= 768 ? 'block' : 'none',
                  position: 'relative'
                }}
              >
                <img
                  src={url}
                  alt={`Generated design ${index + 1}`}
                  className="imgen-generated-image"
                  loading="lazy"
                  onLoad={() => handleImageLoad(url)}
                  onError={() => handleImageError(url)}
                  style={{
                    opacity: loadedImages.has(url) ? 1 : 0,
                    width: '100%',
                    height: 'auto',
                    transition: 'opacity 0.3s ease'
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneratedImage;