import React, { useState, useEffect, useRef } from 'react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/ComparisonPopup.css';
import { Heart } from 'lucide-react';

const COMPARISON_LAST_RATED_KEY = 'comparisonLastRated';
const RATE_NOW_KEY = 'rateNowEnabled';
const HOURS_24 = 24 * 60 * 60 * 1000;

const ComparisonPopup = ({ isOpen, onClose }) => {
    const [imagePairs, setImagePairs] = useState([]);
    const [currentPairIndex, setCurrentPairIndex] = useState(0);
    const [votedPairs, setVotedPairs] = useState(new Set());
    const [skippedPairs, setSkippedPairs] = useState(new Set());
    const [likeInProgress, setLikeInProgress] = useState({});
    const [showAnimation, setShowAnimation] = useState(null);
    const imagePairsRef = useRef(imagePairs);

    const preloadImages = async (pairs) => {
        const loadPromises = pairs.flatMap(pair =>
            pair.images.map(image => new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = resolve;
                img.onerror = reject;
                img.src = image.imageUrl;
            }))
        );

        try {
            await Promise.all(loadPromises);
        } catch (error) {
            console.error('Error preloading images:', error);
        }
    };

    useEffect(() => {
        imagePairsRef.current = imagePairs;
    }, [imagePairs]);

    useEffect(() => {
        if (isOpen) {
            fetchImagePairs();
        }
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        const lastRatedTime = localStorage.getItem(COMPARISON_LAST_RATED_KEY);
        const currentTime = Date.now();

        if (!lastRatedTime || (currentTime - parseInt(lastRatedTime)) >= HOURS_24) {
            localStorage.setItem(RATE_NOW_KEY, 'true');
        }
    }, []);

    const fetchImagePairs = async () => {
        try {
            const response = await authenticatedApiCall('/get-related-pairs', 'GET');
            if (response && response.length > 0) {
                setImagePairs(response);
                await preloadImages(response);
            }
        } catch (error) {
            console.error('Error fetching image pairs:', error);
        }
    };

    const handleImageClick = async (image) => {
        if (likeInProgress[image.id]) return;

        setShowAnimation(image.id);
        setTimeout(() => setShowAnimation(null), 1000);

        const newVotedPairs = new Set(votedPairs);
        newVotedPairs.add(currentPairIndex);
        setVotedPairs(newVotedPairs);

        setLikeInProgress(prev => ({ ...prev, [image.id]: true }));
        const currentLikeCount = image.likeCount;
        const isCurrentlyLiked = image.is_liked;

        setImagePairs(prevPairs => prevPairs.map((pair, index) => ({
            ...pair,
            images: pair.images.map(img =>
                img.id === image.id
                    ? {
                        ...img,
                        likeCount: isCurrentlyLiked ? currentLikeCount - 1 : currentLikeCount + 1,
                        is_liked: !isCurrentlyLiked
                    }
                    : img
            )
        })));

        // Delay moving to next pair to show animation
        setTimeout(() => moveToNextPair(), 800);

        try {
            const response = await authenticatedApiCall(`/update-like-count/${image.id}`, 'POST', {
                increment: isCurrentlyLiked ? -1 : 1
            });

            if (!response.success) {
                throw new Error(response.error || 'Failed to update like count');
            }
        } catch (error) {
            console.error('Error updating like count:', error);
            setImagePairs(prevPairs => prevPairs.map((pair, index) => ({
                ...pair,
                images: pair.images.map(img =>
                    img.id === image.id
                        ? { ...img, likeCount: currentLikeCount, is_liked: isCurrentlyLiked }
                        : img
                )
            })));
        } finally {
            setLikeInProgress(prev => ({ ...prev, [image.id]: false }));
        }
    };


    const handleSkip = () => {
        const newSkippedPairs = new Set(skippedPairs);
        newSkippedPairs.add(currentPairIndex);
        setSkippedPairs(newSkippedPairs);
        moveToNextPair();
    };

    const moveToNextPair = () => {
        const totalResponded = new Set([...votedPairs, ...skippedPairs]).size;

        if (totalResponded >= imagePairs.length - 1) {
            localStorage.setItem(COMPARISON_LAST_RATED_KEY, Date.now().toString());
            localStorage.setItem(RATE_NOW_KEY, 'false');
            onClose();
            return;
        }

        if (currentPairIndex < imagePairs.length - 1) {
            setCurrentPairIndex(prev => prev + 1);
        }
    };

    if (!isOpen || imagePairs.length === 0) return null;

    const currentPair = imagePairs[currentPairIndex];


    return (
        <div className="cip-overlay">
            <div className="cip-container">
                <div className="cip-header">
                    <h2 className="cip-title">Which one do you prefer?</h2>
                    <p className="cip-subtitle">
                        Related to {currentPair.tags.join(' & ')}
                    </p>
                </div>

                <div className="cip-image-grid">
                    {currentPair.images.map((image) => (
                        <div
                            key={image.id}
                            className="cip-image-wrapper"
                            onClick={() => handleImageClick(image)}
                        >
                            <div className="cip-image-container">
                                <img
                                    src={image.imageUrl}
                                    alt={image.description || 'Comparison image'}
                                    className="cip-image"
                                />
                                {showAnimation === image.id && (
                                    <div className="cip-heart-animation">
                                        <Heart className="cip-heart" />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="cip-footer">
                    <div className="cip-footer-content">
                        <span className="cip-progress">
                            {currentPairIndex + 1} of {imagePairs.length}
                        </span>
                        <button
                            onClick={handleSkip}
                            className="cip-skip-button"
                        >
                            Skip this pair
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonPopup;