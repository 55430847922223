import React, { useState } from 'react';
import { CheckCircle } from 'lucide-react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/InstagramFollow.css';

const InstagramFollow = () => {
    const [instagramHandle, setInstagramHandle] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleFollowClick = () => {
        window.open('https://instagram.com/rangrezai', '_blank');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmissionStatus(null);

        try {
            // eslint-disable-next-line
            const response = await authenticatedApiCall(
                '/submit-follow-request-verification',
                'POST',
                { instagramHandle }
            );

            setSubmissionStatus({
                type: 'success',
                message: 'Verification request submitted successfully! We will verify your follow within 24 hours. Please check notifications in your profile to see if it is confirmed.'
            });
        } catch (error) {
            setSubmissionStatus({
                type: 'error',
                message: error.response?.data?.error || 'An error occurred while submitting your handle'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="fv-container">
            <div className="fv-card">
                <div className="fv-card-header">
                    <p><strong>Follow</strong> on Instagram to unlock exclusive benefits!</p>
                </div>

                <div className="fv-instagram-preview">
                    <div className="fv-instagram-mockup">
                        <div className="fv-profile-info">
                            <img
                                src="/images/Rangrez-AI.jpg"
                                alt="Our Instagram Profile"
                                className="fv-profile-pic"
                            />
                            <div className="fv-profile-details">
                                <h3>Rangrez AI</h3>
                                <p>Creative AI Generation Platform</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fv-benefits">
                    <h4>Benefits of Following</h4>
                    <ul>
                        <li>
                            <CheckCircle color="var(--button-background)" size={24} />
                            Double Hourly Generations (Up to 100)
                        </li>
                        <li>
                            <CheckCircle color="var(--button-background)" size={18} />
                            Watermark-Free Downloads
                        </li>
                        <li>
                            <CheckCircle color="var(--button-background)" size={20} />
                            Support Platform Development
                        </li>
                        <li>
                            <CheckCircle color="var(--button-background)" size={22} />
                            Stay Updated with Latest Updates
                        </li>
                    </ul>
                </div>
                <button
                    onClick={handleFollowClick}
                    className="fv-follow-button"
                >
                    Open Instagram Profile
                </button>

                <form onSubmit={handleSubmit} className="fv-verification-form">
                    <input
                        type="text"
                        placeholder="Enter your Instagram username"
                        value={instagramHandle}
                        onChange={(e) => setInstagramHandle(e.target.value)}
                        className="fv-handle-input"
                        required
                    />
                    <button
                        type="submit"
                        className="fv-submit-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Verify Follow'}
                    </button>
                </form>

                {submissionStatus && (
                    <div className={`fv-submission-status ${submissionStatus.type}`}>
                        {submissionStatus.message}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InstagramFollow;