// src/utils/elementBehaviors/oilRigBehavior.js
export const updateOilRigBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;
    // eslint-disable-next-line
    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Check the cell directly below the oil rig
    if (withinRows(j + 1)) {
        const belowCell = grid[i][j + 1];

        // If the cell below is empty, spawn oil
        if (!belowCell.type) {
            nextGrid[i][j + 1] = {
                type: 'OIL',
                color: `hsl(50, 100%, ${30 + Math.random() * 20}%)`
            };
        }
    }

    // Keep the oil rig in place
    nextGrid[i][j] = currentCell;
    return false;
};