import React from 'react';

const promptSuggestions = {
    logo: [
        "Change color scheme to...",
        "Simplify by removing...",
        "Add a gradient with...",
        "Make it modern by using...",
    ],
    meme: [
        "Change text to something about...",
        "Add an image that shows...",
        "Make it relatable by referencing...",
    ],
    sticker: [
        "Make it playful by adding...",
        "Use bright colors like...",
        "Add a bold outline around...",
    ],
    "t-shirt-print": [
        "Make it eye-catching with...",
        "Add a design inspired by...",
        "Highlight the theme using...",
    ],
    default: [
        "Change text to...",
        "Modify colors to...",
        "Add details like...",
        "Simplify by removing...",
        "Make it professional by using...",
    ]
};



const PromptSuggestions = ({ type, onSuggestionSelect }) => {
    const suggestions = promptSuggestions[type] || promptSuggestions.default;

    return (
        <div className="ldv-prompt-suggestions">
            {suggestions.map((suggestion, index) => (
                <button
                    key={index}
                    className="ldv-prompt-suggestion"
                    onMouseDown={(e) => {
                        e.preventDefault();
                        onSuggestionSelect(suggestion);
                    }}
                >
                    {suggestion}
                </button>
            ))}
        </div>
    );
};

export default PromptSuggestions;