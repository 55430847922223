import React, { useState, useEffect } from 'react';
import { Sun, Moon, Sparkles, Leaf, Cloud, Flame } from 'lucide-react';
import './ThemeShowcase.css';

const ThemeShowcaseButton = ({ theme, currentTheme, onClick, icon: Icon, label }) => (
  <button
    onClick={() => onClick(theme)}
    className={`tsc-theme-button ${currentTheme === theme ? 'active' : ''}`}
  >
    <Icon size={24} className={currentTheme === theme ? 'text-primary-brand-color-bright' : ''} />
    <span>{label}</span>
  </button>
);

const ThemeShowcase = () => {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) return savedTheme;
    
    return 'crimson-night'; 
  });

  const themes = [
    { id: 'light', icon: Sun, label: 'Light' },
    { id: 'dark', icon: Moon, label: 'Dark' },
    { id: 'crimson-night', icon: Sparkles, label: 'Crimson' },
    { id: 'nature', icon: Leaf, label: 'Nature' },
    { id: 'ocean', icon: Cloud, label: 'Ocean' },
    { id: 'sunset', icon: Flame, label: 'Sunset' }
  ];

  const handleThemeChange = (newTheme) => {
    setCurrentTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (!localStorage.getItem('theme')) {
        handleThemeChange(e.matches ? 'dark' : 'light');
      }
    };

    mediaQuery.addListener(handleChange);
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  return (
    <div className="tsc-container">
      <div className="tsc-content">
        <h1 className="tsc-heading big-h">Customize Your Experience</h1>
        <p className="tsc-description landing-p">
          Experience our platform in your preferred style. Try out different themes 
          to find the perfect look that suits your taste. Your selection will be 
          saved and remembered across visits.
        </p>
        <div className="tsc-themes-grid">
          {themes.map(theme => (
            <ThemeShowcaseButton
              key={theme.id}
              theme={theme.id}
              currentTheme={currentTheme}
              onClick={handleThemeChange}
              icon={theme.icon}
              label={theme.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThemeShowcase;