import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import useMessage from '../../hooks/useNotify';
import '../../css/EmailTab.css';

// Email Preview Component
const EmailPreview = ({ body, template }) => {
    const renderPreview = () => {
        if (template === 'html') {
            return (
                <div 
                    className="email-preview html-preview" 
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            );
        }
        return (
            <div className="email-preview plain-preview">
                {body}
            </div>
        );
    };

    return (
        <div className="email-preview-container">
            <h3>Email Preview</h3>
            {renderPreview()}
        </div>
    );
};

const EmailTab = () => {
    const [emailData, setEmailData] = useState({
        subject: '',
        body: '',
        template: 'html',
        target_users: []
    });
    const [emailLogs, setEmailLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const { MessageDisplay, setMessage } = useMessage();

    // Fetch email logs on component mount
    useEffect(() => {
        fetchEmailLogs();
        // eslint-disable-next-line
    }, []);

    const fetchEmailLogs = async () => {
        try {
            const response = await authenticatedApiCall('/admin/email-logs', 'GET');
            setEmailLogs(response.email_logs);
        } catch (error) {
            console.error('Error fetching email logs:', error);
            setMessage('Failed to fetch email logs', 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmailData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUsersChange = (e) => {
        const users = e.target.value.split(',').map(user => user.trim());
        setEmailData(prev => ({
            ...prev,
            target_users: users
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await authenticatedApiCall('/admin/send-email', 'POST', emailData);
            
            if (response) {
                setMessage(`Emails sent: ${response.sent_count}, Failed: ${response.failed_count}`, 'success');
                
                // Reset form and refresh logs
                setEmailData({
                    subject: '',
                    body: '',
                    template: 'html',
                    target_users: []
                });
                setShowPreview(false);
                fetchEmailLogs();
            }
        } catch (error) {
            console.error('Error sending emails:', error);
            setMessage('Failed to send emails', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    return (
        <div className="email-tab">
            <MessageDisplay />
            <h2>Email Management</h2>
            
            <form onSubmit={handleSubmit} className="email-form">
                <input 
                    type="text"
                    name="subject"
                    value={emailData.subject}
                    onChange={handleInputChange}
                    placeholder="Email Subject"
                    required
                />
                <textarea 
                    name="body"
                    value={emailData.body}
                    onChange={handleInputChange}
                    placeholder="Email Body (Use {Name}/{email} for personalization)"
                    required
                    rows={5}
                />
                <div className="form-row">
                    <select 
                        name="template"
                        value={emailData.template}
                        onChange={handleInputChange}
                    >
                        <option value="html">HTML Template</option>
                        <option value="plain">Plain Text</option>
                    </select>
                    <button 
                        type="button" 
                        className="preview-button"
                        onClick={togglePreview}
                    >
                        {showPreview ? 'Hide Preview' : 'Preview Email'}
                    </button>
                </div>
                <input 
                    type="text"
                    name="target_users"
                    value={emailData.target_users.join(', ')}
                    onChange={handleUsersChange}
                    placeholder="Target Users (comma-separated)"
                    required
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Emails'}
                </button>
            </form>

            {showPreview && (
                <EmailPreview 
                    body={emailData.body} 
                    template={emailData.template} 
                />
            )}

            <div className="email-logs">
                <h3>Email Send Logs</h3>
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Status</th>
                            <th>Sent At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emailLogs.map((log, index) => (
                            <tr key={index} className={log.status}>
                                <td>{log.to_user}</td>
                                <td>{log.email}</td>
                                <td>{log.subject}</td>
                                <td>{log.status}</td>
                                <td>{new Date(log.sent_at).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EmailTab;