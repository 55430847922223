export const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';

    let date;
    try {
        // Comprehensive date parsing
        if (timestamp.$date) {
            // MongoDB $date object
            date = new Date(timestamp.$date);
        } 
        else if (typeof timestamp === 'string') {
            // Handle various string formats
            if (timestamp.includes('GMT')) {
                // Parsing GMT format
                date = new Date(timestamp);
            }
            else if (timestamp.includes('T')) {
                // ISO format
                date = new Date(timestamp);
            }
            else {
                // Remove microseconds and replace space with 'T'
                const formattedTimestamp = timestamp.split('.')[0].replace(' ', 'T');
                date = new Date(formattedTimestamp);
            }
        } 
        else if (timestamp instanceof Date) {
            // Already a Date object
            date = timestamp;
        }
        else {
            console.warn('Unhandled timestamp format:', timestamp, 'Type:', typeof timestamp);
            return '';
        }

        // Validate date
        if (!date || isNaN(date)) {
            console.warn('Invalid date parsing:', {
                input: timestamp,
                inputType: typeof timestamp,
                parsedDate: date
            });
            return '';
        }

        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        // Relative time calculations
        if (diffInSeconds < 60) {
            return 'Just now';
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes}m ago`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours}h ago`;
        } else if (diffInSeconds < 604800) {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days}d ago`;
        } else {
            return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }
    } catch (error) {
        console.error('Comprehensive timestamp parsing error:', {
            input: timestamp,
            inputType: typeof timestamp,
            error: error.message,
            stack: error.stack
        });
        return '';
    }
};