import React from 'react';
import { FileImage, Heart, Pencil } from 'lucide-react';

const ImageTypeSelector = ({ activeTab, setActiveTab, isLoading }) => {
    const tabs = [
        {
            id: 'original',
            label: 'Original',
            icon: FileImage
        },
        {
            id: 'customized',
            label: 'Customized',
            icon: Pencil
        },
        {
            id: 'liked',
            label: 'Liked',
            icon: Heart
        }
    ];

    return (
        <div className="profile-tabs-container">
            <div className="profile-tabs">
                {tabs.map(({ id, label, icon: Icon }) => (
                    <button
                        key={id}
                        className={`profile-tab ${activeTab === id ? "profile-tab-active" : ""}`}
                        onClick={() => !isLoading && setActiveTab(id)}
                        disabled={isLoading}
                        aria-selected={activeTab === id}
                        role="tab"
                    >
                        <Icon className="profile-tab-icon" />
                        {label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ImageTypeSelector;