import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

const GithubCallBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const authenticateWithGithub = async (code, emailSubscription) => {
    try {
      const response = await fetch(`${API_URL}/github-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Key': process.env.REACT_APP_KEY
        },
        body: JSON.stringify({
          code,
          emailSubscription
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Authentication failed');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Authentication error:', error);
      throw error;
    }
  };

  useEffect(() => {
    const handleCallback = async () => {
      try {
        setIsLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (!code) {
          throw new Error('No authentication code received from GitHub.');
        }

        // Parse email subscription preference from state
        let emailSubscription = true;
        if (state) {
          try {
            const parsedState = JSON.parse(decodeURIComponent(state));
            emailSubscription = parsedState.emailSubscription;
          } catch (error) {
            console.warn('Failed to parse state, using default email subscription');
          }
        }

        const { token, user } = await authenticateWithGithub(code, emailSubscription);

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        setIsLoading(false);
        navigate(location.state?.from || '/');
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
        navigate('/login', {
          state: {
            error: error.message || 'Failed to authenticate with GitHub. Please try again.'
          }
        });
      }
    };

    handleCallback();
  }, [navigate, location]);

  if (error) {
    return (
      <div className="gcb-container">
        <div className="gcb-error-box">
          <h2 className="gcb-error-title">Authentication Error</h2>
          <p className="gcb-error-message">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="gcb-container">
      <div className="gcb-message-box">
        <h2 className="gcb-title">
          {isLoading ? 'Authenticating with GitHub...' : 'Authentication Complete'}
        </h2>
        <p className="gcb-message">
          {isLoading ? 'Please wait while we complete your authentication.' : 'Redirecting...'}
        </p>
      </div>
    </div>
  );
};

export default GithubCallBack;
