import React from 'react';
import './FeaturePollinations.css';

const FeaturePollinations = () => {
    return (
        <div className="fp-container">
            <div className="fp-content">
                <div className="fp-header">
                    <h1 className="fp-heading">Featured on Pollinations.ai</h1>
                    <p className="fp-subheading">Open-Source Platform for Creative Innovation</p>
                </div>
                <div className="fp-details">
                    <div className="fp-description">
                        <p>
                            RangrezAI is proud to be featured on Pollinations.ai,
                            an open-source platform that redefines AI-driven creativity.
                            This recognition underscores our shared commitment to empowering
                            users with innovative and accessible design tools.
                        </p>
                    </div>
                    <div className="fp-credit">
                        <h2 className="fp-credit-heading">Special Thanks</h2>
                        <div className="fp-credit-details">
                            <a
                                href="https://github.com/voodoohop"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fp-credit-name"
                            >Thomash Haferlach
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturePollinations;