// src/utils/elementBehaviors/woodBehaviour.js
export const updateWoodBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Enhanced wood characteristics tracking
    currentCell.burnProgress = currentCell.burnProgress || 0;
    currentCell.sproutData = currentCell.sproutData || {
        exists: false,
        length: 0,
        maxLength: 3,
        growthChance: 0.05,
        startChance: 0.05
    };

    // Wood burning and sprout neighbors check
    const neighbors = [
        { x: i, y: j },
        { x: i - 1, y: j }, { x: i + 1, y: j },
        { x: i, y: j - 1 }, { x: i, y: j + 1 },
        { x: i - 1, y: j - 1 }, { x: i + 1, y: j + 1 },
        { x: i - 1, y: j + 1 }, { x: i + 1, y: j - 1 }
    ];

    // Check for fire and water in neighboring cells
    const firePresent = neighbors.some(({ x, y }) => 
        withinCols(x) && withinRows(y) && grid[x][y].type === 'FIRE'
    );

    const waterPresent = neighbors.some(({ x, y }) => 
        withinCols(x) && withinRows(y) && grid[x][y].type === 'WATER'
    );

    // Handle burning
    if (firePresent) {
        // Increment burn progress
        currentCell.burnProgress += Math.random() * 0.3;

        // Dynamic color change based on burn progress
        const burnColor = `hsl(30, 50%, ${50 - currentCell.burnProgress * 20}%)`;
        
        // Completely burn out wood
        if (currentCell.burnProgress >= 1) {
            nextGrid[i][j] = { type: 'ASH', color: burnColor, lifetime: 40 };
            return true;
        } else {
            // Partially burned wood
            nextGrid[i][j] = {
                ...currentCell,
                color: burnColor
            };
            return true;
        }
    }

    // Handle sprout growth when water is present
    if (waterPresent) {
        // Very low chance to start a sprout when water is nearby
        if (!currentCell.sproutData.exists && Math.random() < currentCell.sproutData.startChance) {
            currentCell.sproutData.exists = true;
        }

        // Grow existing sprouts
        if (currentCell.sproutData.exists) {
            // Low probability of growth
            if (Math.random() < currentCell.sproutData.growthChance) {
                // Increment sprout length if not at max
                if (currentCell.sproutData.length < currentCell.sproutData.maxLength) {
                    currentCell.sproutData.length += 1;
                }
            }

            // Update color to reflect sprout growth
            const sproutIntensity = Math.min(50 + currentCell.sproutData.length * 10, 90);
            currentCell.color = `hsl(120, 50%, ${sproutIntensity}%)`;
        }
    }

    // Normal wood behavior
    nextGrid[i][j] = currentCell;
    return false;
};