
export const createElementBehaviors = (cols, rows) => {
    const withinCols = (i) => i >= 0 && i <= cols - 1;
    const withinRows = (j) => j >= 0 && j <= rows - 1;

    const updatePlantBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
        // Check surrounding cells for water and fire
        const neighbors = [
            { x: i - 1, y: j }, { x: i + 1, y: j },
            { x: i, y: j - 1 }, { x: i, y: j + 1 }
        ];

        let waterNearby = false;
        let fireNearby = false;

        neighbors.forEach(({ x, y }) => {
            if (withinCols(x) && withinRows(y)) {
                const neighborCell = grid[x][y];

                if (neighborCell.type === 'WATER') {
                    waterNearby = true;
                }
                if (neighborCell.type === 'FIRE') {
                    fireNearby = true;
                }
            }
        });

        // Burn to ash when fire is nearby
        if (fireNearby) {
            nextGrid[i][j] = {
                type: 'ASH',
                color: `hsl(0, 0%, 20%)`
            };
            return true;
        }

        // Grow or stay in place when water is nearby
        if (waterNearby && Math.random() < 0.1) {
            // Potential growth logic with vertical preference
            const growthDirections = [
                { x: i, y: j - 1 },  // Prefer upward growth
                { x: i - 1, y: j },
                { x: i + 1, y: j }
            ];

            for (let { x, y } of growthDirections) {
                if (withinCols(x) && withinRows(y) && !grid[x][y].type) {
                    // Add flower when reaching a certain height
                    const isFlower = j < 5 && Math.random() < 0.2;
                    nextGrid[x][y] = {
                        type: isFlower ? 'FLOWER' : 'PLANT',
                        color: isFlower 
                            ? `hsl(${Math.random() * 360}, 70%, ${50 + Math.random() * 20}%)` 
                            : `hsl(120, 70%, ${40 + Math.random() * 20}%)`
                    };
                    break;
                }
            }
        }

        // Keep plant in place or support bottom-up growth
        if (j === rows - 1 && Math.random() < 0.1) {
            // Grow upwards from the bottom
            const growUpwards = { x: i, y: j - 1 };
            if (withinCols(growUpwards.x) && withinRows(growUpwards.y) && !grid[growUpwards.x][growUpwards.y].type) {
                nextGrid[growUpwards.x][growUpwards.y] = {
                    type: 'PLANT',
                    color: `hsl(120, 70%, ${40 + Math.random() * 20}%)`
                };
            }
        }

        nextGrid[i][j] = currentCell;
        return true;
    };

    const updateCloudBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
        // Limit cloud spread with a maximum cloud group size
        const MAX_CLOUD_WIDTH = 10;  // Adjust this value to control cloud spread
        const MAX_CLOUD_HEIGHT = 5;  // Maximum vertical extent

        // Count nearby clouds
        const checkCloudSpread = (grid, centerX, centerY) => {
            let cloudCount = 0;
            const cloudNeighbors = [
                { x: centerX - 1, y: centerY }, { x: centerX + 1, y: centerY },
                { x: centerX, y: centerY - 1 }, { x: centerX, y: centerY + 1 }
            ];

            cloudNeighbors.forEach(({ x, y }) => {
                if (withinCols(x) && withinRows(y) && grid[x][y].type === 'CLOUD') {
                    cloudCount++;
                }
            });

            return cloudCount;
        };

        // Prevent excessive cloud spread
        const cloudSpread = checkCloudSpread(grid, i, j);
        if (cloudSpread > 3) {
            nextGrid[i][j] = currentCell;
            return true;
        }

        // Clouds move towards the top and float horizontally with controlled spreading
        if (j > 0) {
            const left = withinCols(i - 1) ? grid[i - 1][j] : null;
            const right = withinCols(i + 1) ? grid[i + 1][j] : null;
            const above = withinRows(j - 1) ? grid[i][j - 1] : null;

            // Limit vertical extent
            const verticalExtent = j < MAX_CLOUD_HEIGHT;

            // Move up if possible and within vertical limit
            if (verticalExtent && (!above || above.type === null)) {
                nextGrid[i][j - 1] = currentCell;
                return true;
            }

            // Horizontal floating with randomness and spread control
            if (Math.random() < 0.3) {
                // Ensure horizontal spread doesn't exceed MAX_CLOUD_WIDTH
                const horizontalSpread = grid.filter(col => 
                    col[j].type === 'CLOUD' || (col[j].type === null && Math.random() < 0.3)
                ).length;

                if (horizontalSpread < MAX_CLOUD_WIDTH) {
                    if (left && left.type === null) {
                        nextGrid[i - 1][j] = currentCell;
                        return true;
                    }
                    if (right && right.type === null) {
                        nextGrid[i + 1][j] = currentCell;
                        return true;
                    }
                }
            }

            // Cloud generation logic with reduced frequency
            if (cloudSpread >= 2 && Math.random() < 0.1) {
                const waterDirections = [
                    { x: i, y: j + 1 },
                    { x: i - 1, y: j + 1 },
                    { x: i + 1, y: j + 1 }
                ];

                for (let { x, y } of waterDirections) {
                    if (withinCols(x) && withinRows(y) && !grid[x][y].type) {
                        nextGrid[x][y] = {
                            type: 'WATER',
                            color: `hsl(210, 100%, 50%)`
                        };
                        break;
                    }
                }
            }
        }

        // Keep cloud in place or move
        nextGrid[i][j] = currentCell;
        return true;
    };

    return { updatePlantBehavior, updateCloudBehavior };
};

export const addNewElementBehaviors = (createSandPhysicsSimulation) => {
    return (cols, rows, squareSize) => {
        const baseSandPhysics = createSandPhysicsSimulation(cols, rows, squareSize);
        const { updatePlantBehavior, updateCloudBehavior } = createElementBehaviors(cols, rows);

        const extendedUpdateElements = (grid, gravity = 0.1) => {
            let nextGrid = baseSandPhysics.updateElements(grid, gravity);

            // Process grid from bottom to top to prevent multiple updates
            for (let j = rows - 1; j >= 0; j--) {
                for (let i = 0; i < cols; i++) {
                    const currentCell = grid[i][j];

                    if (!currentCell.type) continue;
                    // eslint-disable-next-line
                    switch (currentCell.type) {
                        case 'PLANT':
                            updatePlantBehavior(grid, nextGrid, i, j, currentCell, gravity);
                            break;
                        case 'CLOUD':
                            updateCloudBehavior(grid, nextGrid, i, j, currentCell, gravity);
                            break;
                            
                    }
                }
            }

            return nextGrid;
        };

        return {
            ...baseSandPhysics,
            updateElements: extendedUpdateElements
        };
    };
};