import React, { useEffect, useRef } from 'react';
import Masonry from 'react-masonry-css';
import ImageCard from '../../ImageCard';
import ImageTypeSelector from '../ImageTypeSelector';
import { Loader } from 'lucide-react';

const ProfileGallery = ({
    isOwnProfile,
    activeImageType,
    setActiveImageType,
    isLoading,
    displayedImages,
    selectedImageIndex,
    handleImageClick,
    handleLike,
    handleCopyPromptWrapper,
    handleDownloadWrapper,
    handleDeleteImageWrapper,
    handleReport,
    handleShareClick,
    buttonStates,
    likeInProgress,
    showLikedImages,
    handleAdminDeleteImage,
    isAdmin,
    handleShowSettings,
    handleSetAsProfile,
    isLoggedIn,
    breakpointColumnsObj,
    isLoadingCustomizedImages = false,
    isLoadingLikedImages = false,
    handleCopyMarkdownLink,
    fetchMoreImages,
    hasMore,
    isLoadingMore,
    username
}) => {
    const bottomElementRef = useRef(null);
    const observerRef = useRef(null);

    const getEmptyMessage = () => {
        switch (activeImageType) {
            case 'original':
                return "No original creations yet. Create something new in the Create tab and refresh your profile.";
            case 'customized':
                return "No customized designs yet. Start customizing by selecting a design in the Design tab.";
            case 'liked':
                return "No liked designs yet. Like your favorite designs to save them for later edits.";
            default:
                return "No designs to display.";
        }
    };

    const isCurrentlyLoading = () => {
        if (activeImageType === 'customized') return isLoadingCustomizedImages;
        if (activeImageType === 'liked') return isLoadingLikedImages;
        return false;
    };

    useEffect(() => {
        if (!bottomElementRef.current) return;
    
        const observerOptions = {
            root: null,
            rootMargin: "200px",
            threshold: 0
        };
    
        const observer = new IntersectionObserver((entries) => {
            const first = entries[0];
            
            // Only fetch more if:
            // 1. Actually intersecting
            // 2. Has more images
            // 3. Not already loading
            // 4. Current image type is 'original' (default images from get-user-details)
            if (first.isIntersecting && hasMore && !isLoadingMore && activeImageType === 'original') {
                fetchMoreImages();
            }
        }, observerOptions);
    
        observerRef.current = observer;
    
        if (bottomElementRef.current) {
            observer.observe(bottomElementRef.current);
        }
    
        return () => {
            if (observerRef.current && bottomElementRef.current) {
                // eslint-disable-next-line
                observerRef.current.unobserve(bottomElementRef.current);
            }
        };
    }, [hasMore, isLoadingMore, fetchMoreImages, username, activeImageType]);

    const user = JSON.parse(localStorage.getItem('user'));
    const verifiedFollower = user && user.verified_follower ? user.verified_follower : false;

    return (
        <div className="profile-album-section">
            <div className="profile-album-header">
                <h2 className="profile-album-title">Designs</h2>
                {isOwnProfile && (
                    <ImageTypeSelector
                        activeTab={activeImageType}
                        setActiveTab={setActiveImageType}
                        isLoading={isLoading}
                    />
                )}
            </div>

            {isCurrentlyLoading() ? (
                <div className="profile-loading-spinner-container">
                    <Loader size={20} className='profile-loading-spinner animate-spin' />
                </div>
            ) : displayedImages.length > 0 ? (
                <>
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {displayedImages.map((image, index) => (
                            <ImageCard
                                key={image.id}
                                image={{
                                    ...image,
                                    is_liked: image.is_liked,
                                    likes_count: image.likes_count || 0
                                }}
                                index={index}
                                selectedImageIndex={selectedImageIndex}
                                handleImageClick={handleImageClick}
                                handleLike={handleLike}
                                copyPromptToClipboard={handleCopyPromptWrapper}
                                handleDownload={(url, id) => handleDownloadWrapper(url, id, `${image.title} by ${image.username} on rangrezai.com`, verifiedFollower)}
                                handleDelete={handleDeleteImageWrapper}
                                handleReport={handleReport}
                                handleShareClick={handleShareClick}
                                buttonStates={buttonStates}
                                likeInProgress={likeInProgress}
                                isOwnProfile={isOwnProfile}
                                isLikedImage={showLikedImages}
                                hideUserInfo={true}
                                onDeleteImage={(e) => handleAdminDeleteImage(image.id, e)}
                                isAdminView={isAdmin}
                                handleShowSettings={handleShowSettings}
                                handleCopyMarkdownLink={handleCopyMarkdownLink}
                                handleSetAsProfile={handleSetAsProfile}
                                isRealtedImage={true}
                                isLoggedIn={isLoggedIn}
                                isCustomizedDesign={image?.is_customized || false}
                            />
                        ))}
                    </Masonry>
                    {hasMore && activeImageType === 'original' && (
                        <div ref={bottomElementRef} className="loading-more-indicator">
                            <Loader className="animate-spin" />
                        </div>
                    )}
                </>
            ) : (
                <p className="profile-no-images-message">
                    {getEmptyMessage()}
                </p>
            )}
        </div>
    );
};

export default ProfileGallery;