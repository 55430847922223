import React from 'react';
import './InteractiveTitle.css';

const InteractiveTitle = () => {
  const text = "RangrezAI.";
  
  return (
    <div className="interactive-text">
      {text.split('').map((char, index) => (
        <span 
          key={index} 
          className="char" 
          style={{ 
            "--direction": index % 2 === 0 ? -1 : 1 
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

export default InteractiveTitle;