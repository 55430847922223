// components/ImageGenerator/PromptSection.js

import React from 'react';
import { RefreshCw } from 'lucide-react';
import ExplicitContentFilter from '../ExplicitContentFilter';

const PromptSection = ({
    formData,
    wordCount,
    isLoading,
    handleChange,
    generateRandomSeed,
    handleImprovePrompt,
    handleRandomPrompt,
    setIsPromptValid
}) => {
    // Check if multiple images are selected
    const isMultipleImages = formData.numImages > 1;

    return (
        <div className="imgen-mobile-prompt-view">
            <textarea
                className="imgen-prompt-input"
                placeholder="Enter Design Prompt:"
                name="prompt"
                value={formData.prompt}
                onChange={handleChange}
                required
            />
            <ExplicitContentFilter
                prompt={formData.prompt}
                onValidPrompt={() => setIsPromptValid(true)}
                onInvalidPrompt={() => setIsPromptValid(false)}
            />
            <div className="imgen-word-count-seed-container">
                <div className="imgen-word-count">
                    {wordCount}/600 words
                </div>
                <div className="imgen-seed-input">
                    <label
                        title={isMultipleImages ? "Seed is disabled for multiple images" : "Change Seed for Unique Image"}
                        htmlFor="seed"
                        className={isMultipleImages ? "imgen-seed-disabled" : ""}
                    >
                        Seed:
                    </label>
                    <div className="imgen-seed-input-group">
                        <input
                            type="number"
                            name="seed"
                            title={isMultipleImages ? "Seed is disabled for multiple images" : "Change Seed for Unique Image"}
                            value={isMultipleImages ? "" : formData.seed}
                            onChange={handleChange}
                            placeholder={isMultipleImages ? "random" : "value"}
                            className={`imgen-dimension-input ${isMultipleImages ? "imgen-seed-disabled" : ""}`}
                            disabled={isMultipleImages}
                        />
                        <button
                            type="button"
                            title={isMultipleImages ? "Random seed is disabled for multiple images" : "Random seed"}
                            onClick={generateRandomSeed}
                            className={`imgen-seed-refresh-button ${isMultipleImages ? "imgen-seed-disabled" : ""}`}
                            aria-label="Generate random seed"
                            disabled={isMultipleImages}
                        >
                            <RefreshCw size={16} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="imgen-button-group">
                <button
                    type="button"
                    onClick={handleImprovePrompt}
                    className="imgen-improve-button"
                >
                    Improve
                </button>
                <button
                    type="button"
                    onClick={handleRandomPrompt}
                    className="imgen-random-button"
                >
                    Random
                </button>
            </div>
            <button
                type="submit"
                disabled={isLoading || wordCount > 600}
                className="imgen-generate-button"
            >
                {isLoading ? 'Generating...' : 'GENERATE DESIGN'}
            </button>
        </div>
    );
};

export default PromptSection;