export const checkWaterLavaInteraction = (grid, nextGrid, i, j) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Check adjacent cells for water and lava interaction
    const checkDirections = [
        { x: i, y: j - 1 },   // Up
        { x: i, y: j + 1 },   // Down
        { x: i - 1, y: j },   // Left
        { x: i + 1, y: j }    // Right
    ];

    for (let { x, y } of checkDirections) {
        if (withinCols(x) && withinRows(y)) {
            const adjacentCell = grid[x][y];
            const currentCell = grid[i][j];

            // Water touches Lava
            if (currentCell.type === 'WATER' && adjacentCell.type === 'LAVA') {
                // Turn both into rock with some color variation
                nextGrid[i][j] = {
                    type: 'STONE',
                    color: `hsl(0, 0%, ${35 + Math.random() * 30}%)`
                };
                nextGrid[x][y] = {
                    type: 'STONE',
                    color: `hsl(0, 0%, ${35 + Math.random() * 30}%)`
                };
                return true;
            }

            // Lava touches Water
            if (currentCell.type === 'LAVA' && adjacentCell.type === 'WATER') {
                // Turn water to smoke and lava to rock
                nextGrid[i][j] = {
                    type: 'STONE',
                    color: `hsl(0, 0%, ${35 + Math.random() * 30}%)`
                };
                nextGrid[x][y] = {
                    type: 'SMOKE',
                    color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                    lifetime: 4
                };
                return true;
            }
        }
    }

    return false;
};