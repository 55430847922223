import React from 'react';
import '../css/TermsOfService.css';
import { useDocumentHead } from '../hooks/useDocumentHead';

const TermsOfService = () => {

  useDocumentHead(
    'Terms of Service - Rangrez AI | User Agreement and Guidelines',
    'Review the Terms of Service for Rangrez AI, outlining user agreements, responsibilities, and guidelines for using our platform. Stay informed about your rights and obligations.'
  );

  return (
    <div className="tos-container margin">
      <h1 className="tos-title">Terms of Service</h1>
      <div className="tos-content">
        <section className="tos-section">
          <h2 className="tos-section-title">1. Acceptance of Terms</h2>
          <p className="tos-text">By accessing or using rangrezai.com, you agree to comply with and be bound by these Terms of Service.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">2. Description of Service</h2>
          <p className="tos-text">Rangrez AI provides a variety of AI-powered tools, including image generation, logo design, and more, using advanced AI technologies. Our platform offers a comprehensive suite to foster creativity and productivity for all types of users.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">3. User Responsibilities</h2>
          <ul className="tos-list">
            <li>You are responsible for your use of the service and any content you create or share.</li>
            <li>You agree not to use the service for any illegal, harmful, or unauthorized purposes.</li>
            <li>You must ensure compliance with all applicable laws in your jurisdiction when using our service.</li>
          </ul>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">4. Prohibited Content</h2>
          <p className="tos-text">
            Users are strictly prohibited from creating, uploading, or sharing any explicit content involving minors. Any violations may result in temporary restrictions from generating images or a permanent ban and will be reported to the relevant authorities. We take such matters seriously and fully cooperate with legal authorities in cases of prohibited content.
          </p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">5. Intellectual Property</h2>
          <p className="tos-text">Content generated through our service is owned by the user, but it is subject to the terms and conditions of third-party services. You are responsible for ensuring that you have the necessary rights to use any generated content.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">6. Limitation of Liability</h2>
          <p className="tos-text">Rangrez AI is provided "as is" without warranties of any kind. We are not liable for any damages arising from the use of our services.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">7. Content Moderation and Removal</h2>
          <p className="tos-text">
            We reserve the right to monitor, review, and remove any content uploaded, created, or shared on rangrezai.com without prior notice, if we believe the content:
          </p>
          <ul className="tos-list">
            <li>Violates our terms, policies, or community standards;</li>
            <li>Is unlawful, inappropriate, or harmful to others;</li>
            <li>Compromises the security or integrity of our platform.</li>
          </ul>
          <p className="tos-text">
            By using our service, you acknowledge and agree that we have full discretion to remove content that violates these terms, and such removal may occur without prior notice.
          </p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">8. Changes to Terms</h2>
          <p className="tos-text">We reserve the right to modify these terms at any time. Continued use of the service after changes constitute acceptance of the new terms.</p>
        </section>
        <section className="tos-section">
          <h2 className="tos-section-title">9. Contact Information</h2>
          <p className="tos-text">For any questions regarding these terms, please contact us at <a href="mailto:hello@rangrezai.com">hello@rangrezai.com</a>.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
