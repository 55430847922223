import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import './Search.css';

const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ designs: [] });
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [trendingTags, setTrendingTags] = useState([]);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const searchTimeout = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (!query) {
      fetchTrendingTags();
    }
  }, [query]);

  const fetchTrendingTags = async () => {
    try {
      const response = await authenticatedApiCall('/trending-tags', 'GET');
      const randomTags = response.trending_tags
        .sort(() => 0.5 - Math.random())
        .slice(0, 7);
      setTrendingTags(randomTags);
    } catch (error) {
      console.error('Trending tags error:', error);
    }
  };

  const handleSearch = async (searchQuery) => {
    if (!searchQuery.trim()) {
      setResults({ designs: [] });
      return;
    }

    setLoading(true);
    try {
      const response = await authenticatedApiCall(`/search?query=${encodeURIComponent(searchQuery)}`, 'GET');
      setResults(response);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    setShowDropdown(true);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      handleSearch(newQuery);
    }, 300);
  };

  const handleDesignClick = (designId) => {
    navigate(`/design/${designId}`);
    setShowDropdown(false);
    setQuery('');
  };

  const handleTagClick = (tag) => {
    setQuery(tag);
    handleSearch(tag);
  };

  const renderDesignResults = () => {

    return results.designs.map((design) => (
      <div
        key={design.id}
        className="ser-design-item"
        onClick={() => handleDesignClick(design.id)}
      >
        <img
          src={design.imageUrl}
          alt={design.title}
          className="ser-design-thumbnail"
        />
        <div className="ser-design-info">
          <div className="ser-design-title">{design.title}</div>
          <div className="ser-design-type">{design.designType}</div>
          <div className="ser-design-tags">
            {design.tags.slice(0, 3).map((tag, index) => (
              <span key={index} className="ser-tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    ));
  };

  const renderTags = () => {
    const tagsToShow = trendingTags;
    const tagClassName = 'ser-tag ser-trending-tag';

    return tagsToShow.length > 0 ? (
      <div className="ser-suggested-tags">
        {tagsToShow.map((tag, index) => (
          <span
            key={index}
            className={tagClassName}
            onClick={() => handleTagClick(tag)}
          >
            {query ? tag : (
              <>
                <span className="ser-trending-arrow">↑</span>
                {tag}
              </>
            )}
          </span>
        ))}
      </div>
    ) : null;
  };

  return (
    <div className="ser-search-container" ref={searchRef}>
      <div className="ser-search-input-wrapper">
        <input
          type="text"
          className="ser-search-input"
          placeholder="Search any design..."
          value={query}
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
        />
        {loading && <div className="ser-loading-spinner" />}
      </div>

      {showDropdown && (
        <div className="ser-dropdown">
          {renderDesignResults()}
          {renderTags()}
        </div>
      )}
    </div>
  );
};

export default Search;