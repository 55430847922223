import React from 'react';
import { X } from 'lucide-react';

export const ImageSettings = ({
    showSettings,
    settingsData,
    setShowSettings,
}) => {
    if (!showSettings || !settingsData) return null;

    return (
        <div
            className="ic-settings-overlay"
            onClick={(e) => {
                if (e.target.className === 'ic-settings-overlay') {
                    setShowSettings(false);
                }
            }}
        >
            <div className="ic-settings-content">
                <div className="ic-settings-header">
                    <h3>Design Settings</h3>
                    <button
                        className="ic-action-button"
                        onClick={() => setShowSettings(false)}
                    >
                        <X size={20} className="ic-action-icon" />
                    </button>
                </div>
                <div className="ic-settings-body">
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Width:</span>
                        <span>{settingsData.width}</span>
                    </div>
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Height:</span>
                        <span>{settingsData.height}</span>
                    </div>
                    <div className="ic-settings-item">
                        <span className="ic-settings-label">Seed:</span>
                        <span>{settingsData.seed}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};