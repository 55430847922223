// ProfileEditForm.js
import React from 'react';

const ProfileEditForm = ({ editedProfileData, handleInputChange, handleSaveProfile, handleCancelEdit }) => {
  return (
    <div className="profile-edit-form">
      <input
        type="text"
        name="fullName"
        value={editedProfileData.fullName}
        onChange={handleInputChange}
        placeholder="Full Name (letters & spaces only)"
      />
      <input
        type="text"
        name="username"
        value={editedProfileData.username}
        onChange={handleInputChange}
        placeholder="Username (4-12, unique, lowercase or numbers)"
      />
      <textarea
        name="bio"
        value={editedProfileData.bio}
        onChange={handleInputChange}
        placeholder="Bio (max 50 words/300 characters)"
      />
      <div className="profile-edit-buttons">
        <button onClick={handleSaveProfile}>Save</button>
        <button onClick={handleCancelEdit}>Cancel</button>
      </div>
    </div>
  );
};

export default ProfileEditForm;