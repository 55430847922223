import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import '../css/Home.css';
import ImageCard from './ImageCard';
import useLikeImage from '../hooks/image/useLikeImage';
import useImageHandlers from '../hooks/image/useImageHandlers';
import Masonry from 'react-masonry-css';
import { useDocumentHead } from '../hooks/useDocumentHead';
import HomeSearch from './Search/HomeSearch';
import FilterComponent from './Filter/FilterComponent';
import { Filter } from 'lucide-react';

const Home = ({ activeFilters, onFilterChange }) => {
  const [feedImages, setFeedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);
  const location = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const navigate = useNavigate();
  const lastFetchTime = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const currentRequest = useRef(null);

  const breakpointColumnsObj = {
    default: 4,
    1200: 4,
    768: 3,
    576: 2,
    460: 2,
    330: 1,
  };

  useDocumentHead(
    'Designs - Explore AI-Generated Designs | Rangrez AI',
    'Welcome to the Rangrez AI Designs page! Discover a dynamic feed of AI-generated designs. Like, download, and interact with unique creations to fuel your creativity.'
  );

  const {
    images,
    setImages: setLikeImages,
    handleLike: originalHandleLike,
    likeInProgress,
    error: likeError
  } = useLikeImage(feedImages);

  const {
    buttonStates,
    error,
    handleDownload,
    handleReport,
    handleShareClick,
    setError,
    handleShowSettings,
    handleCopyMarkdownLink
  } = useImageHandlers();

  const handleLike = useCallback(async (imageId) => {
    const { success, newLikeStatus } = await originalHandleLike(imageId);
    if (success) {
      setFeedImages(prevImages =>
        prevImages.map(img =>
          img.id === imageId ? {
            ...img,
            is_liked: newLikeStatus,
            likeCount: img.likeCount + (newLikeStatus ? 1 : -1)
          } : img
        )
      );
    }
  }, [originalHandleLike]);

  const fetchImages = useCallback(async () => {
    if (loading || !hasMore) return;

    // Prevent multiple simultaneous requests
    const now = Date.now();
    if (lastFetchTime.current && now - lastFetchTime.current < 1000) {
      return;
    }

    // Cancel any existing request
    if (currentRequest.current) {
      currentRequest.current.abort();
    }

    setLoading(true);
    lastFetchTime.current = now;
    setError('');
    try {
      const controller = new AbortController();
      currentRequest.current = controller;

      const queryParams = new URLSearchParams({
        page: page.toString(),
        per_page: '20',
        sort: activeFilters.sort || 'default',
        ...(searchQuery && { search: searchQuery })
      });

      // Add filters only if they have values
      for (const [key, value] of Object.entries(activeFilters)) {
        if (Array.isArray(value) && value.length > 0) {
          queryParams.append(key.toLowerCase(), value.join(','));
        }
      }

      const response = await authenticatedApiCall(
        `/get-images?${queryParams.toString()}`,
        'GET',
        null,
        controller.signal
      );

      if (response.length === 0) {
        if (page === 1) {
          setFeedImages([]);
        }
        setHasMore(false);
      } else {
        setFeedImages(prevImages => {
          // Filter out duplicates based on image ID
          const existingIds = new Set(prevImages.map(img => img.id));
          const uniqueNewImages = response.filter(img => !existingIds.has(img.id));
          return page === 1 ? response : [...prevImages, ...uniqueNewImages];
        });
        setPage(prevPage => prevPage + 1);
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        setError('Error fetching images: ' + err.message);
      }
    } finally {
      setLoading(false);
      currentRequest.current = null;
      setIsInitialLoad(false);
    }
  }, [loading, hasMore, page, activeFilters, searchQuery, setError]);

  const resetImageState = useCallback(() => {
    if (currentRequest.current) {
      currentRequest.current.abort();
    }
    setFeedImages([]);
    setPage(1);
    setHasMore(true);
    setLoading(false);
    setIsInitialLoad(true);
    lastFetchTime.current = null;
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    resetImageState();
  }, [resetImageState]);

  const handleRefresh = useCallback(() => {
    window.scrollTo({ top: 0 });
    resetImageState();
  }, [resetImageState]);

  useEffect(() => {
    resetImageState();
  }, [activeFilters, resetImageState]);

  // Modified initial load effect
  useEffect(() => {
    if (feedImages.length === 0 && !loading && isInitialLoad) {
      fetchImages();
    }
  }, [feedImages.length, fetchImages, loading, isInitialLoad]);

  const handleImageClick = useCallback((index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && !loading && hasMore && !isInitialLoad) {
      fetchImages();
    }
  }, [loading, hasMore, fetchImages, isInitialLoad]);

  useEffect(() => {
    setLikeImages(feedImages);
  }, [feedImages, setLikeImages]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('header-filter-container')) {
        setShowFilters(false);
      }
    };

    if (showFilters) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showFilters]);

  useEffect(() => {
    if (likeError) {
      setError(likeError);
    }
  }, [likeError, setError]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.home-image-card')) {
        setSelectedImageIndex(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "300px",
      threshold: 0.1
    };
    const observer = new IntersectionObserver(handleObserver, option);
    const currentLoader = loader.current;

    if (currentLoader) observer.observe(currentLoader);

    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  }, [handleObserver]);
  const user = JSON.parse(localStorage.getItem('user'));
  const verifiedFollower = user && user.verified_follower ? user.verified_follower : false;
  const renderItems = useCallback(() => {
    return images.map((image, index) => (
      <ImageCard
        key={image.id}
        image={{
          ...image,
          is_liked: image.is_liked
        }}
        index={index}
        selectedImageIndex={selectedImageIndex}
        handleImageClick={handleImageClick}
        handleLike={handleLike}
        handleDownload={(url, id) => handleDownload(url, id, `${image.title} by ${image.username} on rangrezai.com`, verifiedFollower)}
        handleReport={handleReport}
        handleShareClick={handleShareClick}
        buttonStates={buttonStates}
        likeInProgress={likeInProgress}
        handleCopyMarkdownLink={handleCopyMarkdownLink}
        handleShowSettings={handleShowSettings}
        isCustomizedDesign={image?.is_customized || false}
      />
    ));
  }, [images, selectedImageIndex, handleImageClick, handleLike,
    handleDownload, buttonStates, likeInProgress, handleReport, handleShareClick,
    handleShowSettings, handleCopyMarkdownLink, verifiedFollower]);

  const SkeletonCard = () => (
    <div className="home-image-card home-skeleton">
      <div className="home-skeleton-image"></div>
    </div>
  );

  const renderEndContent = () => {
    if (!loading && !hasMore) {
      return (
        <div className="home-end-content">
          <p className="home-no-more-images">You've reached the end of your feed</p>
          <div className="home-end-buttons">
            <button
              onClick={handleRefresh}
              className="home-end-button refresh"
            >
              Refresh
            </button>
            <button
              className="home-end-button create"
              onClick={() => navigate('/create')}
            >
              Create New
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  const getActiveFiltersCount = () => {
    if (!activeFilters) return 0;

    return Object.entries(activeFilters)
      .filter(([key]) => key !== 'sort')
      .reduce((total, [_, values]) =>
        Array.isArray(values) ? total + values.length : total, 0);
  };

  return (
    <div className="home-home-container margin margin-home">
      {error && <p className="home-error-text">{error}</p>}
      <div
        className={`header-filter-container ${showFilters ? 'visible' : ''}`}
        aria-label="Close filters"
      >
        <FilterComponent
          onFilterChange={onFilterChange}
          activeFilters={activeFilters}
          isVisible={showFilters}
        />
      </div>
      <div className="home-top-bar">
        <button
          className='home-filter-button'
          onClick={() => setShowFilters(!showFilters)}
        >
          <Filter size={18} /> Filter
          <span>
            {getActiveFiltersCount() > 0 && (
              <span className='header-filter-count-span'>
                {getActiveFiltersCount()}
              </span>
            )}
          </span>
        </button>
        <HomeSearch onSearch={handleSearch} />
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {loading && images.length === 0
          ? Array(20).fill().map((_, index) => <SkeletonCard key={index} />)
          : renderItems()}
        {loading && images.length > 0 && Array(10).fill().map((_, index) => <SkeletonCard key={`loading-${index}`} />)}
      </Masonry>

      {renderEndContent()}
      {hasMore && <div ref={loader} />}
    </div>
  );
};

export default Home;