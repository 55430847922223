import React from 'react';
import './ImageGeneration.css';
import { Link } from 'react-router-dom';

const ImageGeneration = () => {
    return (
        <div className="igh-container">
            <div className="igh-left-section">
                <div className="igh-images-container">
                    <Link to={"/design/673cc91b574cd156f7572a22"} className="igh-image-link">
                        <img src="../images/landing/logo-design.png" alt="Logo Design" className="igh-image" />
                        <span>Collage-Style Logo Design</span>
                    </Link>
                    <Link to={"/design/673ec60548923816082ba56f"} className="igh-image-link">
                        <img src="../images/landing/product-design.png" alt="Product Design" className="igh-image" />
                        <span>Sleek Portable Coffee Tumbler Design</span>
                    </Link>
                    <Link to={"/design/673ec50748923816082ba564"} className="igh-image-link">
                        <img src="../images/landing/art-design.png" alt="Art Design" className="igh-image" />
                        <span>Urban Odyssey</span>
                    </Link>
                </div>
            </div>
            <div className="igh-right-section">
                <h1 className="igh-heading big-h">Create Stunning Designs with AI</h1>
                <p className="igh-description landing-p">
                    Bring your ideas to life with Rangrez AI. Generate, refine, and perfect designs effortlessly using cutting-edge tools. Join a vibrant creator community and showcase your work.
                </p>
                <div className="igh-button">
                    <Link to={"/create"}>Start Creating</Link>
                </div>
            </div>
        </div>
    );
};

export default ImageGeneration;
