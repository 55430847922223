// src/utils/elementBehaviors/rockBehavior.js
export const updateRockBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;
// eslint-disable-next-line
    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // Rock falls straight down and interacts with different elements
    if (j === rows - 1) {
        nextGrid[i][j] = currentCell;
        return false;
    }

    const below = withinRows(j + 1) ? grid[i][j + 1] : null;

    // Rock falls down through water, maintaining specific vertical movement
    if (below && (below.type === null || below.type === 'WATER' || below.type === 'FIRE')) {
        nextGrid[i][j + 1] = currentCell;
        return true;
    }

    // If can't move down, stay in place
    nextGrid[i][j] = currentCell;
    return false;
};