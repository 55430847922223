import React, { useState, useEffect } from 'react';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import '../../css/VerifyFollows.css';

const VerifyFollows = () => {
    const [followRequests, setFollowRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchFollowRequests();
    }, []);

    const fetchFollowRequests = async () => {
        try {
            setLoading(true);
            const response = await authenticatedApiCall('/get-follow-requests', 'GET');
            setFollowRequests(response.users || []);
        } catch (err) {
            setError('Failed to fetch follow requests');
        } finally {
            setLoading(false);
        }
    };

    const handleVerification = async (userId, username, isVerified) => {
        try {
            const endpoint = isVerified 
                ? '/verify-follower' 
                : '/unverify-follower';
            
            await authenticatedApiCall(endpoint, 'POST', { userId, username });
            
            // Remove the user from the list after verification
            setFollowRequests(prevRequests => 
                prevRequests.filter(user => user.id !== userId)
            );
        } catch (err) {
            setError(`Failed to ${isVerified ? 'verify' : 'unverify'} follower`);
        }
    };

    const openInstagramProfile = (handle) => {
        window.open(`https://www.instagram.com/${handle}`, '_blank');
    };

    if (loading) return <div className="vf-loading">Loading follow requests...</div>;
    if (error) return <div className="vf-error">{error}</div>;
    if (!followRequests.length) return <div className="vf-no-requests">No pending follow requests</div>;

    return (
        <div className="vf-verify-follows-container">
            <h2 className="vf-title">Verify Followers</h2>
            <div className="vf-instructions">
                Click on the Instagram handle to open the user's profile
            </div>
            <table className="vf-followers-table">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Instagram Handle</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {followRequests.map(user => (
                        <tr key={user.id} className="vf-follower-row">
                            <td>{user.username}</td>
                            <td>
                                <span 
                                    className="vf-instagram-handle"
                                    onClick={() => openInstagramProfile(user.instagram_handle)}
                                >
                                    @{user.instagram_handle}
                                </span>
                            </td>
                            <td className="vf-action-buttons">
                                <button 
                                    className="vf-btn vf-btn-verify"
                                    onClick={() => handleVerification(user.id, user.username, true)}
                                >
                                    Verified
                                </button>
                                <button 
                                    className="vf-btn vf-btn-unverify"
                                    onClick={() => handleVerification(user.id, user.username, false)}
                                >
                                    Not Verified
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default VerifyFollows;