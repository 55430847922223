// src/utils/elementBehaviors/index.js
import { updateSandStoneBehavior } from './sandBehaviour';
import { updateWaterBehavior } from './waterBehaviour';
import { updateFireBehavior } from './fireBehaviour';
import { updateIceBehavior } from './iceBehaviour';
import { updateRockBehavior } from './rockBehaviour';
import { updateOilBehavior } from './oilBehaviour';
import { updateGasBehavior } from './gasBehaviour';
import { updateVirusBehavior } from './virusBehaviour';
import { updatePlantBehavior } from './plantBehaviour';
import { updateCloudBehavior } from './cloudBehaviour';
import { updateSmokeBehavior } from './smokeBehaviour';
import { updateAshBehavior } from './ashBehaviour';
import { updateWoodBehavior } from './woodBehaviour';
import { updateFlowerBehavior } from './flowerBehaviour';
import { updateLavaBehavior } from './lavaBehaviour';
import { updateOilRigBehavior } from './oilRigBehaviour';
import { updateBeeBehavior } from './beeBehaviour';
import { updateBeehiveBehavior } from './beehiveBehaviour';

export const elementBehaviors = {
    SAND: (grid, nextGrid, i, j, currentCell, gravity) => 
        updateSandStoneBehavior(grid, nextGrid, i, j, currentCell, gravity, 'SAND'),
    STONE: (grid, nextGrid, i, j, currentCell, gravity) => 
        updateSandStoneBehavior(grid, nextGrid, i, j, currentCell, gravity, 'STONE'),
    WATER: updateWaterBehavior,
    WOOD: updateWoodBehavior, 
    FIRE: updateFireBehavior,
    ICE: updateIceBehavior,
    OIL_RIG_SOURCE: updateOilRigBehavior,
    ROCK: updateRockBehavior,
    LAVA: updateLavaBehavior,
    OIL: updateOilBehavior,
    GAS: updateGasBehavior,
    VIRUS: updateVirusBehavior,
    FLOWER: updateFlowerBehavior,
    MUD: (grid, nextGrid, i, j, currentCell, gravity) => 
        updateSandStoneBehavior(grid, nextGrid, i, j, currentCell, gravity, 'MUD'),
    PLANT: updatePlantBehavior,
    CLOUD: updateCloudBehavior,
    SMOKE: updateSmokeBehavior,
    ASH: updateAshBehavior,
    BEE: updateBeeBehavior,
    BEEHIVE: updateBeehiveBehavior
};