import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ChevronDown } from 'lucide-react';
import '../css/Blog.css';
import Footer from './Footer';
import { useDocumentHead } from '../hooks/useDocumentHead';

// Helper function to parse any markdown content into sections
const parseMarkdownContent = (markdown) => {
  if (!markdown) return { title: '', sections: [] };

  // Split content by headings (##, ###, or **)
  const lines = markdown.split('\n');
  let sections = [];
  let currentSection = { title: '', content: [] };
  let mainTitle = '';

  lines.forEach((line, index) => {
    // Check for main title (first ** or # section)
    if (index === 0 && (line.startsWith('**') || line.startsWith('#'))) {
      // eslint-disable-next-line
      mainTitle = line.replace(/[\*#]/g, '').trim();
      return;
    }

    // Check for new section
    if (line.startsWith('##') || line.startsWith('###') || line.startsWith('**')) {
      // Save previous section if it exists
      if (currentSection.title || currentSection.content.length > 0) {
        sections.push({
          title: currentSection.title,
          content: currentSection.content.join('\n').trim()
        });
      }

      // Start new section
      currentSection = {
        // eslint-disable-next-line
        title: line.replace(/[\*#]/g, '').trim(),
        content: []
      };
    } else {
      currentSection.content.push(line);
    }
  });

  // Add the last section
  if (currentSection.title || currentSection.content.length > 0) {
    sections.push({
      title: currentSection.title,
      content: currentSection.content.join('\n').trim()
    });
  }

  return {
    title: mainTitle,
    sections: sections.filter(section => section.title || section.content)
  };
};

const Section = ({ title, content, isOpen, onClick }) => (
  <div className="card-item">
    <div
      className={`item-header ${isOpen ? 'open' : ''}`}
      onClick={onClick}
      style={{ paddingLeft: title ? '2rem' : '1rem' }}
    >
      <span>{title || 'Content'}</span>
      <ChevronDown
        className={`chevron ${isOpen ? 'open' : ''}`}
        size={16}
      />
    </div>
    <div className={`item-content ${isOpen ? 'open' : ''}`}>
      <div className="content-wrapper">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  </div>
);

const ContentCard = ({ title, items, category }) => {
  const [openItem, setOpenItem] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [blogContent, setBlogContent] = useState({});
  const [parsedContent, setParsedContent] = useState({});

  const loadContent = async (category, item) => {
    try {
      const response = await fetch(`/blogs/${category.toLowerCase()}/${item.toLowerCase().replace(/ /g, '-')}.md`);
      if (!response.ok) {
        throw new Error('Content not found');
      }
      const text = await response.text();
      setBlogContent(prev => ({
        ...prev,
        [`${category}-${item}`]: text
      }));

      // Parse markdown content into sections
      const parsed = parseMarkdownContent(text);
      setParsedContent(prev => ({
        ...prev,
        [`${category}-${item}`]: parsed
      }));
    } catch (error) {
      console.error('Error loading content:', error);
      setBlogContent(prev => ({
        ...prev,
        [`${category}-${item}`]: '# Content Not Found\n\nSorry, the requested content could not be found.'
      }));
    }
  };

  const handleItemClick = async (index, item) => {
    if (openItem === index) {
      setOpenItem(null);
      setOpenSection(null);
    } else {
      setOpenItem(index);
      setOpenSection(null);
      if (!blogContent[`${category}-${item}`]) {
        await loadContent(category, item);
      }
    }
  };

  const handleSectionClick = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <div className="content-card">
      <h3 className="card-title">{title}</h3>
      <div className="card-items">
        {items.map((item, index) => (
          <div key={index}>
            <div className="card-item">
              <div
                className={`item-header ${openItem === index ? 'open' : ''}`}
                onClick={() => handleItemClick(index, item)}
              >
                <span>{item}</span>
                <ChevronDown
                  className={`chevron ${openItem === index ? 'open' : ''}`}
                  size={20}
                />
              </div>
              <div className={`item-content ${openItem === index ? 'open' : ''}`}>
                <div className="content-wrapper">
                  {parsedContent[`${category}-${item}`]?.sections.map((section, sectionIndex) => (
                    <Section
                      key={sectionIndex}
                      title={section.title}
                      content={section.content}
                      isOpen={openSection === sectionIndex}
                      onClick={() => handleSectionClick(sectionIndex)}
                    />
                  )) || <ReactMarkdown>{blogContent[`${category}-${item}`] || ' Loading...'}</ReactMarkdown>}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Blog = () => {
  useDocumentHead(
    'Blog - Tutorials, FAQs, and AI Guides | Rangrez AI',
    'Explore the Rangrez AI blog for expert tutorials, FAQs, and guides on maximizing AI-powered design and content creation.'
  );

  const categories = {
    'Questions': ['FAQ', 'Common Issues', 'Troubleshooting'],
    'Tutorials': ['Getting Started'],
    'Other': ['Release Notes', 'Community', 'Report Bug']
  };

  return (
    <div className="blog-container">
      <h1 className="blog-title">Knowledge Base</h1>
      <div className="content-grid">
        {Object.entries(categories).map(([category, items], index) => (
          <ContentCard
            key={index}
            title={category}
            items={items}
            category={category}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Blog;