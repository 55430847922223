import React, { useState, useEffect } from 'react';
import './FilterComponent.css';

const FilterComponent = ({ onFilterChange, activeFilters = {}, isVisible }) => {
    const [isClosing, setIsClosing] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    const filters = {
        sort: [
            { value: 'default', label: 'Default' },
            { value: 'latest', label: 'Latest First' },
            { value: 'oldest', label: 'Oldest First' },
            { value: 'most_liked', label: 'Most Liked' },
            { value: 'followed', label: 'From Followed Users' },
        ],
        design_type: [
            'logo',
            'art',
            'sketch',
            'wallpaper',
            'product',
            'meme',
            'sticker',
            't-shirt-print',
            'coloring-page'
        ],
        style: [
            'minimalist',
            'modern',
            'vintage',
            'playful',
            'luxurious',
            'tech-inspired',
            'natural',
            'artistic',
            '3d-realistic',
            'abstract'
        ]
    };

    const normalizedActiveFilters = {
        sort: activeFilters.sort || 'default',
        design_type: activeFilters.design_type || [],
        style: activeFilters.style || [],
    };

    // useEffect(() => {
    //     onFilterChange(normalizedActiveFilters);
    //     // eslint-disable-next-line 
    // }, []);

    useEffect(() => {
        if (isVisible) {
            setShouldRender(true);
            setIsClosing(false);
        } else {
            setIsClosing(true);
            // Wait for animation to complete before unmounting
            const timer = setTimeout(() => {
                setShouldRender(false);
            }, 300); // Match this with your CSS transition duration
            return () => clearTimeout(timer);
        }
    }, [isVisible]);

    const handleFilterClick = (category, value) => {
        if (category === 'sort') {
            onFilterChange({
                ...normalizedActiveFilters,
                sort: value
            });
        } else {
            const updatedFilters = {
                ...normalizedActiveFilters,
                [category]: normalizedActiveFilters[category].includes(value)
                    ? normalizedActiveFilters[category].filter(item => item !== value)
                    : [...normalizedActiveFilters[category], value]
            };
            onFilterChange(updatedFilters);
        }
    };

    const clearFilters = () => {
        const emptyFilters = {
            sort: 'default',
            design_type: [],
            style: [],
        };
        onFilterChange(emptyFilters);
    };

    const getActiveFiltersCount = () => {
        return Object.entries(normalizedActiveFilters)
            .filter(([key]) => key !== 'sort')
            .reduce((total, [_, values]) =>
                Array.isArray(values) ? total + values.length : total, 0);
    };

    const formatCategoryName = (category) => {
        return category
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    if (!shouldRender) return null;

    return (
        <div className={`filter-container ${isVisible && !isClosing ? 'visible' : ''}`}>
            <div className="filter-header">
                <h3>Filters</h3>
                {getActiveFiltersCount() > 0 && (
                    <button onClick={clearFilters} className="clear-filters">
                        Clear all
                    </button>
                )}
            </div>

            {Object.entries(filters).map(([category, values]) => (
                <div key={category} className="filter-category">
                    <div className="category-header">
                        <h4>{formatCategoryName(category)}</h4>
                    </div>
                    <div className="filter-options">
                        {category === 'sort'
                            ? values.map(({ value, label }) => (
                                <div
                                    key={value}
                                    className={`filter-option ${normalizedActiveFilters.sort === value ? 'active' : ''}`}
                                    onClick={() => handleFilterClick(category, value)}
                                >
                                    {label}
                                </div>
                            ))
                            : values.map(value => (
                                <div
                                    key={value}
                                    className={`filter-option ${normalizedActiveFilters[category].includes(value) ? 'active' : ''}`}
                                    onClick={() => handleFilterClick(category, value)}
                                >
                                    {value}
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FilterComponent;