import React, { useState } from 'react';
import UserManagement from './UserManagement';
import NotificationsTab from './NotificationsTab';
import ReportedImages from './ReportedImages';
import '../../css/AdminDashboard.css';
import { useDocumentHead } from '../../hooks/useDocumentHead'
import UnapprovedDesigns from './UnapprovedDesigns';
import EmailTab from './EmailTab';
import VerifyFollows from './VerifyFollows';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState();

    useDocumentHead(
        'Admin Dashboard - Rangrez AI | Creative Platform',
        'Access the admin dashboard for managing Rangrez AI tools, user data, and platform settings. Authorized personnel only.',
        'noindex, nofollow'
    );

    return (
        <div className="ad-admin-dashboard margin">
            <h1 className="ad-dashboard-title">Admin Dashboard</h1>
            <div className="ad-tabs">
                <button
                    className={`ad-tab ${activeTab === 'users' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    Users
                </button>
                <button
                    className={`ad-tab ${activeTab === 'notifications' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('notifications')}
                >
                    Notifications
                </button>
                <button
                    className={`ad-tab ${activeTab === 'unapproved-designs' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('unapproved-designs')}
                >
                    Unapproved Designs
                </button>
                <button
                    className={`ad-tab ${activeTab === 'verify-follows' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('verify-follows')}
                >
                    Verify Follows
                </button>
                <button
                    className={`ad-tab ${activeTab === 'reported-images' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('reported-images')}
                >
                    Reported Images
                </button>
                <button
                    className={`ad-tab ${activeTab === 'emails' ? 'ad-active' : ''}`}
                    onClick={() => setActiveTab('emails')}
                >
                    Emails
                </button>
            </div>
            <div className="ad-content">
                {activeTab === 'users' && <UserManagement />}
                {activeTab === 'notifications' && <NotificationsTab />}
                {activeTab === 'unapproved-designs' && <UnapprovedDesigns />}
                {activeTab === 'verify-follows' && <VerifyFollows />}
                {activeTab === 'reported-images' && <ReportedImages />}
                {activeTab === 'emails' && <EmailTab />}
            </div>
        </div>
    );
};

export default AdminDashboard;