// src/utils/elementBehaviors/gasBehavior.js
export const updateGasBehavior = (grid, nextGrid, i, j, currentCell, gravity) => {
    const rows = grid[0].length;
    const cols = grid.length;

    const withinCols = (value) => value >= 0 && value < cols;
    const withinRows = (value) => value >= 0 && value < rows;

    // More complex spreading in all directions
    const spreadDirections = [
        { x: i - 1, y: j },     // Left
        { x: i + 1, y: j },     // Right
        { x: i, y: j - 1 },     // Up
        { x: i, y: j + 1 },     // Down
        { x: i - 1, y: j - 1 }, // Up-Left
        { x: i + 1, y: j - 1 }, // Up-Right
        { x: i - 1, y: j + 1 }, // Down-Left
        { x: i + 1, y: j + 1 }  // Down-Right
    ];

    // Explode when near fire with more destructive effect
    const fireNeighbors = spreadDirections.filter(({ x, y }) =>
        withinCols(x) && withinRows(y) &&
        grid[x][y].type === 'FIRE'
    );

    if (fireNeighbors.length > 0) {
        // More intense blast effect
        const blastRadius = 4;
        for (let dx = -blastRadius; dx <= blastRadius; dx++) {
            for (let dy = -blastRadius; dy <= blastRadius; dy++) {
                const x = i + dx;
                const y = j + dy;
                
                if (withinCols(x) && withinRows(y)) {
                    // Different probabilities and effects based on distance from center
                    const distance = Math.sqrt(dx * dx + dy * dy);
                    const probability = Math.max(0, 1 - (distance / blastRadius));

                    if (Math.random() < probability) {
                        nextGrid[x][y] = {
                            type: 'SMOKE',
                            color: `hsl(0, 0%, ${50 + Math.random() * 30}%)`,
                            lifetime: 5 + Math.floor(Math.random() * 5)
                        };

                        // Occasional fire generation near the blast center
                        if (distance <= blastRadius / 2 && Math.random() < 0.5) {
                            nextGrid[x][y] = {
                                type: 'FIRE',
                                color: `hsl(30, 100%, ${50 + Math.random() * 40}%)`,
                                lifetime: 8
                            };
                        }
                    }
                }
            }
        }
        return true;
    }

    // Spread gas in multiple directions
    let hasMoved = false;
    for (let { x, y } of spreadDirections) {
        if (withinCols(x) && withinRows(y) && 
            (!grid[x][y].type || grid[x][y].type === null) && 
            Math.random() < 0.3) {
            
            nextGrid[x][y] = {
                ...currentCell,
                // Slight color variation to show spreading
                color: `hsl(120, 10%, ${80 + Math.random() * 15}%)`
            };
            hasMoved = true;
            break;
        }
    }

    // If no movement occurred, keep in current position
    if (!hasMoved) {
        nextGrid[i][j] = currentCell;
    }

    return hasMoved;
};