import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import '../../css/Profile.css'
import useLikeImage from '../../hooks/image/useLikeImage';
import useImageHandlers from '../../hooks/image/useImageHandlers';
import useProfileHandlers from '../../hooks/profile/useProfileHandlers';
import useMessage from '../../hooks/useNotify';
import ProfileSettings from './ProfileSettings';
import UserListPopup from './UserListPopup';
import { useDocumentHead } from '../../hooks/useDocumentHead'
import { isLoggedIn } from '../../utils/cookies';
import HeaderMessage from '../HeaderMessage';
import ProfileHeader from './Profile/ProfileHeader';
import ProfileInfo from './Profile/ProfileInfo';
import ProfileEditForm from './Profile/ProfileEditForm';
import ProfileStats from './Profile/ProfileStats';
import ProfileGallery from './Profile/ProfileGallery';
import ComparisonPopup from '../ComparisonPopup';
import { ProfileActions, ProfileAdultWarning, ProfileSkeleton, ProfileFollowButton } from './Profile/ProfileComponents';
import Search from '../Search/Search';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const COMPARISON_LAST_RATED_KEY = 'comparisonLastRated';
const RATE_NOW_KEY = 'rateNowEnabled';
const HOURS_24 = 1 * 60 * 60 * 1000; // 1 hour not 24

const Profile = () => {
    const [profileData, setProfileData] = useState({
        imageUrl: null,
        fullName: '',
        email: '',
        username: '',
        bio: '',
        savedImageUrls: [],
        isPublic: true,
        followerCount: 0,
        followingCount: 0,
        isFollowing: false,
        socialLinks: {},
        badges: {},
        adult_images_count: 0,
        new_notification: false
    });

    const [isEditing, setIsEditing] = useState(false);
    const [editedProfileData, setEditedProfileData] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [newNotification, setNewNotification] = useState(false);
    const [showRateNow, setShowRateNow] = useState(false);
    const [isComparisonOpen, setIsComparisonOpen] = useState(false);

    const [isRefreshing, setIsRefreshing] = useState(false);

    const breakpointColumnsObj = {
        default: 4,
        1200: 4,
        1024: 4,
        768: 3,
        576: 2,
        400: 2
    };

    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowers, setShowFollowers] = useState(false);
    const [showFollowing, setShowFollowing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [showHeaderMessage, setShowHeaderMessage] = useState(false);
    const [customizedLoaded, setCustomizedLoaded] = useState(false);
    const [activeImageType, setActiveImageType] = useState('original');
    const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
    const [isComparisonLoading, setIsComparisonLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { username } = useParams();
    const isInitialMount = useRef(true);

    const [isLoadingFollowers, setIsLoadingFollowers] = useState(false);
    const [isLoadingFollowing, setIsLoadingFollowing] = useState(false);

    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // eslint-disable-next-line
    const [showLikedImages, setShowLikedImages] = useState(false);
    const [isLoadingLikedImages, setIsLoadingLikedImages] = useState(false);

    const [originalImages, setOriginalImages] = useState([]);
    const [customizedImages, setCustomizedImages] = useState([]);
    const [likedImages, setLikedImages] = useState([]);

    const { MessageDisplay, setMessage, clearMessage } = useMessage();

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const getDisplayedImages = () => {
        switch (activeImageType) {
            case 'original':
                return originalImages;
            case 'customized':
                return customizedImages;
            case 'liked':
                return likedImages;
            default:
                return [];
        }
    };

    const {
        images: currentImages,
        setImages: setCurrentImages,
        handleLike,
        likeInProgress,
    } = useLikeImage(getDisplayedImages());

    const {
        buttonStates,
        handleDownload,
        handleReport,
        handleShareClick,
        handleShowSettings,
        handleSetAsProfile,
        handleCopyMarkdownLink
    } = useImageHandlers();

    const {
        isDeleting,
        followLoading,
        toggleProfileVisibility,
        handleDeleteImage,
        handleDeleteAccount,
        handleFollow,
        handleUnfollow,
    } = useProfileHandlers();

    const checkRatingStatus = () => {
        const lastRatedTime = localStorage.getItem(COMPARISON_LAST_RATED_KEY);
        const rateNowEnabled = localStorage.getItem(RATE_NOW_KEY);
        const currentTime = Date.now();

        if (!lastRatedTime || (currentTime - parseInt(lastRatedTime)) >= HOURS_24) {
            localStorage.setItem(RATE_NOW_KEY, 'true');
            setShowRateNow(true);
        } else {
            setShowRateNow(rateNowEnabled === 'true');
        }
    };

    const getCurrentUser = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user?.username || null;
    };

    const getCacheKey = () => {
        const currentUser = getCurrentUser();
        return currentUser ? `profile_cache_${currentUser}` : null;
    };

    useDocumentHead(
        `${profileData.fullName || profileData.username}'s Profile | Rangrez AI Designs`,
        `Explore ${profileData.fullName || profileData.username}'s creative journey on Rangrez AI. ${profileData.bio ? `${profileData.bio.slice(0, 150)}${profileData.bio.length > 150 ? '...' : ''}` : 'Discover unique AI-generated designs and artistic creativity.'}`,
        'index, follow'
    );

    useEffect(() => {
        if (activeImageType === 'customized' && !customizedLoaded) {
            fetchCustomizedDesigns();
        } else if (activeImageType === 'liked') {
            fetchLikedImages();
        } else {
            setCurrentImages(profileData.savedImageUrls);
        }
        // eslint-disable-next-line 
    }, [activeImageType]);

    useEffect(() => {
        if (showLikedImages) {
            setCurrentImages(likedImages);
        } else {
            setCurrentImages(profileData.savedImageUrls);
        }
    }, [showLikedImages, likedImages, profileData.savedImageUrls, setCurrentImages]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user?.is_admin || false);
    }, []);

    useEffect(() => {
        if (isOwnProfile) {
            checkRatingStatus();
        }
    }, [isOwnProfile]);


    const fetchFollowers = async () => {
        setIsLoadingFollowers(true);
        try {
            const response = await authenticatedApiCall(`/followers/${username || profileData.username}`, 'GET');
            setFollowers(response);
            setMessage(null, null);
        } catch (error) {
            setMessage('Error fetching followers', 'error');
        } finally {
            setIsLoadingFollowers(false);
        }
    };

    const fetchFollowing = async () => {
        setIsLoadingFollowing(true);
        try {
            const response = await authenticatedApiCall(`/following/${username || profileData.username}`, 'GET');
            setFollowing(response);
            setMessage(null, null);
        } catch (error) {
            setMessage('Error fetching following', 'error');
        } finally {
            setIsLoadingFollowing(false);
        }
    };

    const toggleFollowersList = () => {
        setShowFollowers(!showFollowers);
        setShowFollowing(false);
        if (!showFollowers && followers.length === 0) {
            fetchFollowers();
        }
    };

    const toggleFollowingList = () => {
        setShowFollowing(!showFollowing);
        setShowFollowers(false);
        if (!showFollowing && following.length === 0) {
            fetchFollowing();
        }
    };


    const handleRateClick = async () => {
        setIsComparisonLoading(true);
        setIsComparisonOpen(true);
    };

    const handleComparisonClose = () => {
        setIsComparisonOpen(false);
        setIsComparisonLoading(false);
        localStorage.setItem(RATE_NOW_KEY, 'false');
        setShowRateNow(false);
        localStorage.setItem(COMPARISON_LAST_RATED_KEY, Date.now().toString());
    };


    const handleFollowAction = async () => {
        const success = isFollowing
            ? await handleUnfollow(username)
            : await handleFollow(username);
        if (success) {
            setIsFollowing(!isFollowing);
            setProfileData(prev => ({
                ...prev,
                followerCount: prev.followerCount + (isFollowing ? -1 : 1)
            }));
            setMessage(isFollowing ? 'Unfollowed successfully' : 'Followed successfully', 'success');
        } else {
            setMessage('Failed to update follow status', 'error');
        }
    };

    const handleImageClick = (index) => {
        if (window.innerWidth <= 768) {
            setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
        } else {
            setSelectedImageIndex(index === selectedImageIndex ? null : index);
        }
    };

    const handleBellClick = () => {
        setShowHeaderMessage(true);
        setNewNotification(false);

        const updatedProfileData = {
            ...profileData,
            new_notification: false
        };

        setProfileData(updatedProfileData);
        cacheProfile(updatedProfileData);
    };


    const cacheCustomizedDesigns = (designs) => {
        const cacheData = {
            data: designs,
            timestamp: Date.now()
        };
        localStorage.setItem('customized_designs_cache', JSON.stringify(cacheData));
    };

    const getCachedCustomizedDesigns = () => {
        const cached = localStorage.getItem('customized_designs_cache');
        if (!cached) return null;

        const { data, timestamp } = JSON.parse(cached);
        const isExpired = Date.now() - timestamp > CACHE_DURATION;

        if (isExpired) {
            localStorage.removeItem('customized_designs_cache');
            return null;
        }

        return data;
    };



    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.image-card')) {
                setSelectedImageIndex(null);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    // eslint-disable-next-line
    const getCachedProfile = () => {
        const cacheKey = getCacheKey();
        if (!cacheKey) return null;

        // Don't use cache if coming from adult warning
        if (location.state?.fromAdultWarning) {
            return null;
        }

        const cached = localStorage.getItem(cacheKey);
        if (!cached) return null;

        const { data, timestamp } = JSON.parse(cached);
        const isExpired = Date.now() - timestamp > CACHE_DURATION;

        if (isExpired) {
            localStorage.removeItem(cacheKey);
            return null;
        }

        return data;
    };

    const cacheProfile = (profileData) => {
        const cacheKey = getCacheKey();
        if (!cacheKey) return;

        const cacheData = {
            data: profileData,
            timestamp: Date.now()
        };
        localStorage.setItem(cacheKey, JSON.stringify(cacheData));
    };

    const clearUserCache = () => {
        const cacheKey = getCacheKey();
        if (cacheKey) {
            localStorage.removeItem(cacheKey);
        }
    };

    const fetchMoreImages = async () => {
        // Prevent multiple simultaneous calls or calls when no more images
        if (isLoadingMore || !hasMore) {
            console.log('Aborting fetch - already loading or no more images');
            return;
        }

        setIsLoadingMore(true);
        try {
            const response = await authenticatedApiCall(
                `/get-user-details?username=${username || profileData.username}&page=${page + 1}`,
                'GET'
            );

            console.log('Fetch more images response:', response);

            if (response.savedImageUrls && response.savedImageUrls.length > 0) {
                // Append new images
                setOriginalImages(prev => [...prev, ...response.savedImageUrls]);

                // Increment page only after successful fetch
                setPage(prevPage => prevPage + 1);

                // Update has more status based on actual image count
                setHasMore(response.savedImageUrls.length > 0);
            } else {
                // No more images, explicitly set hasMore to false
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching more images:', error);
            setMessage('Error fetching more images', 'error');

            // In case of error, stop further attempts
            setHasMore(false);
        } finally {
            setIsLoadingMore(false);
        }
    };

    const fetchProfileDetails = async (forceRefresh = false) => {
        // If forcing refresh or first load, reset to initial state more aggressively
        if (forceRefresh) {
            setIsLoading(true);
            setOriginalImages([]);
            setPage(1);
            setHasMore(true);
        } else {
            setIsLoading(!isRefreshing);
        }

        try {
            const response = await authenticatedApiCall(
                `/get-user-details?username=${username || profileData.username}&page=1`, // Always fetch first page on refresh
                'GET'
            );

            // Rest of your existing logic remains the same...
            setIsOwnProfile(response.isOwnProfile || false);
            setIsFollowing(response.isFollowing || false);

            if (response.isOwnProfile) {
                cacheProfile(response);
            }

            setProfileData(response);
            setNewNotification(response.new_notification);
            setEditedProfileData(response);

            setOriginalImages(response.savedImageUrls || []);
            setHasMore(response.hasMore || false);
            setPage(1);

        } catch (error) {
            console.error('Error fetching profile details:', error);
            setMessage('Failed to load profile', 'error');
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
    };

    const handleAdminDeleteImage = async (imageId, e) => {
        if (e) {
            e.stopPropagation();
        }
        try {
            await authenticatedApiCall(`/admin/delete-image/${imageId}`, 'DELETE');

            // Update both the profile data and displayed images
            const updatedImages = currentImages.filter(img => img.id !== imageId);
            setCurrentImages(updatedImages);

            setProfileData(prev => ({
                ...prev,
                savedImageUrls: prev.savedImageUrls.filter(img => img.id !== imageId)
            }));

            setMessage('Image deleted successfully', 'success');
        } catch (err) {
            console.error('Error deleting image: ' + err.message);
            setMessage('Failed to delete image', 'error');
        }
    };

    const handleRefresh = () => {
        setIsRefreshing(true);
        localStorage.removeItem('customized_designs_cache');
        localStorage.removeItem(getCacheKey());
        setPage(1);
        setCustomizedLoaded(false);
        setOriginalImages([]);
        fetchProfileDetails(true);
        setActiveImageType('original');
    };

    useEffect(() => {
        setCurrentImages(getDisplayedImages());
        // eslint-disable-next-line 
    }, [activeImageType, originalImages, customizedImages, likedImages]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchProfileDetails();
        } else if (username) {
            fetchProfileDetails();
        }
        // eslint-disable-next-line 
    }, [username]);


    const fetchLikedImages = async () => {
        if (isLoadingLikedImages) return;

        setIsLoadingLikedImages(true);
        try {
            const response = await authenticatedApiCall('/get-liked-images', 'GET');
            setLikedImages(response);
        } catch (error) {
            setMessage('Error fetching liked images', 'error');
        } finally {
            setIsLoadingLikedImages(false);
        }
    };

    const fetchCustomizedDesigns = async () => {
        const cachedDesigns = getCachedCustomizedDesigns();
        if (cachedDesigns) {
            setCustomizedImages(cachedDesigns);
            setCustomizedLoaded(true);
            return;
        }

        try {
            const response = await authenticatedApiCall('/get-customized-designs', 'GET');
            setCustomizedImages(response);
            cacheCustomizedDesigns(response);
            setCustomizedLoaded(true);
        } catch (error) {
            setMessage('Error fetching customized designs', 'error');
        }
    };

    const handleEditProfile = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedProfileData(profileData);
    };

    const handleSaveProfile = async () => {
        try {
            const response = await authenticatedApiCall('/update-profile', 'POST', editedProfileData);
            if (response.success) {
                setProfileData(editedProfileData);
                cacheProfile(editedProfileData);
                setIsEditing(false);
                setMessage('Profile updated successfully', 'success');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            } else {
                setMessage(response.error, 'error');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            }
        } catch (error) {
            setMessage('Failed to update profile', 'error');
            setTimeout(() => {
                clearMessage();
            }, 3000);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProfileData(prev => ({ ...prev, [name]: value }));
    };
    const handleSocialLinksChange = (newSocialLinks) => {
        setEditedProfileData(prev => ({ ...prev, socialLinks: newSocialLinks }));
    };

    const toggleProfileVisibilityWrapper = async () => {
        const result = await toggleProfileVisibility();
        if (result.success) {
            const updatedProfileData = {
                ...profileData,
                isPublic: result.isPublic
            };
            setProfileData(updatedProfileData);
            cacheProfile(updatedProfileData);
            setMessage(`Profile visibility set to ${result.isPublic ? 'public' : 'private'}`, 'success');
        } else {
            setMessage(result.error, result.type);
        }
    };

    const handleDeleteImageWrapper = async (image, imageId, index) => {
        // For adult content images, delete immediately without confirmation
        if (image.isAdult) {
            const success = await handleDeleteImage(imageId);
            if (success) {
                const updatedProfileData = {
                    ...profileData,
                    savedImageUrls: profileData.savedImageUrls.filter(img => img.id !== imageId)
                };
                setProfileData(updatedProfileData);
                cacheProfile(updatedProfileData);
                setMessage('Image deleted successfully', 'success');
            } else {
                setMessage('Failed to delete image', 'error');
            }
            return;
        }

        // For non-adult content images, show confirmation dialog
        const confirmed = window.confirm("Are you sure you want to delete this image? This action cannot be undone.");
        if (!confirmed) return;

        const success = await handleDeleteImage(imageId);
        if (success) {
            const updatedProfileData = {
                ...profileData,
                savedImageUrls: profileData.savedImageUrls.filter(img => img.id !== imageId)
            };
            setProfileData(updatedProfileData);
            cacheProfile(updatedProfileData);
            setMessage('Image deleted successfully', 'success');
        } else {
            setMessage('Failed to delete image', 'error');
        }
    };

    const handleDeleteAccountWrapper = async () => {
        if (window.confirm("Are you sure you want to delete your account? All of your generated art will be deleted permanently. This action cannot be undone.")) {
            const success = await handleDeleteAccount();
            if (success) {
                clearUserCache();
                localStorage.removeItem('token');
                navigate('/login');
                setMessage('Account deleted successfully', 'success');
            } else {
                setMessage('Failed to delete account', 'error');
            }
        }
    };

    const handleLogout = () => {
        clearUserCache();
        localStorage.removeItem('token');
        window.location.replace('/login');
    };

    const handleDownloadWrapper = async (url, id, title, verifiedFollower) => {
        const result = await handleDownload(url, id, title, verifiedFollower);
        setMessage(result.message, result.success ? 'success' : 'error');
    };

    if (isLoading) {
        return <ProfileSkeleton />;
    }

    const renderAdultContentWarning = () => {
        if (!isOwnProfile) return null;
        return <ProfileAdultWarning adultImagesCount={profileData.adult_images_count} />;
    };

    const getContainerClassName = () => {
        let className = "profile-profile-container margin";
        if (activeImageType === 'liked') {
            className += " liked-images-view";
        }
        return className;
    };

    return (
        <div className={getContainerClassName()}>
            <MessageDisplay />
            <div className="about-top-bar">
                <Search />
            </div>
            <div className="profile-profile-card">
                <div className="profile-profile-header">
                    <ProfileHeader profileData={profileData} />
                    <div className="profile-profile-info">
                        {isEditing ? (
                            <ProfileEditForm
                                editedProfileData={editedProfileData}
                                handleInputChange={handleInputChange}
                                handleSaveProfile={handleSaveProfile}
                                handleCancelEdit={handleCancelEdit}
                            />
                        ) : (
                            <ProfileInfo profileData={profileData} />
                        )}
                        <ProfileStats
                            profileData={profileData}
                            toggleFollowersList={toggleFollowersList}
                            toggleFollowingList={toggleFollowingList}
                        />
                        <UserListPopup
                            isOpen={showFollowers}
                            onClose={() => setShowFollowers(false)}
                            users={followers}
                            title="Followers"
                            emptyMessage="No followers yet"
                            isLoading={isLoadingFollowers}
                        />

                        <UserListPopup
                            isOpen={showFollowing}
                            onClose={() => setShowFollowing(false)}
                            users={following}
                            title="Following"
                            emptyMessage="Not following anyone yet"
                            isLoading={isLoadingFollowing}
                        />
                        {!isOwnProfile && (
                            <ProfileFollowButton
                                isFollowing={isFollowing}
                                followLoading={followLoading}
                                handleFollowAction={handleFollowAction}
                            />
                        )}
                        <div className="profile-seperator-line"></div>
                        <ProfileActions
                            isEditing={isEditing}
                            editedProfileData={editedProfileData}
                            profileData={profileData}
                            onSocialLinksChange={handleSocialLinksChange}
                            isOwnProfile={isOwnProfile}
                            handleRefresh={handleRefresh}
                            handleBellClick={handleBellClick}
                            handleRateClick={handleRateClick}
                            setIsSettingsOpen={setIsSettingsOpen}
                            isRefreshing={isRefreshing}
                            isLoadingNotifications={isLoadingNotifications}
                            newNotification={newNotification}
                            showRateNow={showRateNow}
                            renderAdultContentWarning={renderAdultContentWarning}
                            isComparisonLoading={isComparisonLoading}
                        />
                        {!profileData.isPublic && !isOwnProfile && (
                            <p className="profile-private-profile-message">
                                This profile is Locked!
                                {isAdmin && " (Visible to you as an admin)"}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {(isOwnProfile || profileData.isPublic || isAdmin) && (
                <ProfileGallery
                    isOwnProfile={isOwnProfile}
                    activeImageType={activeImageType}
                    setActiveImageType={setActiveImageType}
                    isLoading={isLoading}
                    displayedImages={currentImages}
                    handleLike={handleLike}
                    selectedImageIndex={selectedImageIndex}
                    handleImageClick={handleImageClick}
                    handleDownloadWrapper={handleDownloadWrapper}
                    handleDeleteImageWrapper={handleDeleteImageWrapper}
                    handleReport={handleReport}
                    handleShareClick={handleShareClick}
                    buttonStates={buttonStates}
                    likeInProgress={likeInProgress}
                    showLikedImages={showLikedImages}
                    handleAdminDeleteImage={handleAdminDeleteImage}
                    isAdmin={isAdmin}
                    handleShowSettings={handleShowSettings}
                    handleSetAsProfile={handleSetAsProfile}
                    isLoggedIn={isLoggedIn()}
                    handleCopyMarkdownLink={handleCopyMarkdownLink}
                    breakpointColumnsObj={breakpointColumnsObj}
                    isLoadingCustomizedImages={!customizedLoaded && activeImageType === 'customized'}
                    isLoadingLikedImages={isLoadingLikedImages}
                    fetchMoreImages={fetchMoreImages}
                    hasMore={hasMore}
                    isLoadingMore={isLoadingMore}
                    username={username}
                />
            )}
            {isOwnProfile && (
                <ProfileSettings
                    isOpen={isSettingsOpen}
                    onClose={() => setIsSettingsOpen(false)}
                    onEditProfile={handleEditProfile}
                    onToggleVisibility={toggleProfileVisibilityWrapper}
                    onLogout={handleLogout}
                    onDeleteAccount={handleDeleteAccountWrapper}
                    isPublic={profileData.isPublic}
                    isDeleting={isDeleting}
                    username={profileData.username}
                    imageUrl={profileData.imageUrl}
                />
            )}
            {showHeaderMessage && (
                <HeaderMessage
                    isLoggedIn={isLoggedIn()}
                    username={profileData.username}
                    onLoadingChange={setIsLoadingNotifications}
                />
            )}
            {isComparisonOpen && (
                <ComparisonPopup
                    isOpen={isComparisonOpen}
                    onClose={handleComparisonClose}
                />
            )}
        </div>
    );
};

export default Profile;