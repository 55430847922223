import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../utils/cookies';
import { useDocumentHead } from '../hooks/useDocumentHead';
import Footer from '../Components/Footer';
import ImageGeneration from './ImageGeneration';
import ContentGeneration from './ContentGeneration';
import ThemeShowcase from './ThemeShowcase';
import './About.css'
import Search from '../Components/Search/Search';
import FeaturePollinations from './FeaturePollinations';
import CommunityShowcase from './CommunityShowcase';
import InteractiveTitle from './InteractiveTitle';

const About = () => {
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const animationFrameRef = useRef(null);
  const scrollPositionsRef = useRef({ first: 0, second: 0 });
  const navigate = useNavigate();

  useDocumentHead(
    'Rangrez AI - The Ultimate Platform for AI-Generated Images, Designs, and Creativity',
    'Welcome to Rangrez AI, the ultimate platform for generating unique AI-powered images, designs, logos, and more. Unleash your creativity, collaborate with others, and explore endless possibilities with our powerful AI tools.'
  );

  const aiTools = [
    "Generate Wallpapers",
    "Create & Customize Logos",
    "Design Shirt Graphics",
    "Generate Memes",
    "Create Sketchbook Pages",
    "Enhance Prompts",
    "Explore and Share Content",
    "Download High-Quality Designs",
    "Get Random Design Ideas",
    "Follow & Connect with Artists",
    "Auto-Save Your Creations",
    "View Community Art Feed"
  ];

  useEffect(() => {
    if (isLoggedIn() && !sessionStorage.getItem('createPageVisited')) {
      navigate('/create');
      sessionStorage.setItem('createPageVisited', 'true');
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const firstColumn = firstColumnRef.current;
    const secondColumn = secondColumnRef.current;

    if (firstColumn && secondColumn) {
      const SCROLL_SPEED = 0.05; // Reduced speed factor (adjust this value to change animation speed)

      const animateScroll = (timestamp) => {
        if (!scrollPositionsRef.current.lastTimestamp) {
          scrollPositionsRef.current.lastTimestamp = timestamp;
        }

        const deltaTime = timestamp - scrollPositionsRef.current.lastTimestamp;
        scrollPositionsRef.current.lastTimestamp = timestamp;

        if (isMobile) {
          // Mobile horizontal scrolling
          scrollPositionsRef.current.first += SCROLL_SPEED * deltaTime;
          scrollPositionsRef.current.second -= SCROLL_SPEED * deltaTime;

          if (scrollPositionsRef.current.first >= firstColumn.scrollWidth / 2) {
            scrollPositionsRef.current.first = 0;
          }

          if (scrollPositionsRef.current.second <= 0) {
            scrollPositionsRef.current.second = secondColumn.scrollWidth / 2;
          }

          firstColumn.scrollLeft = scrollPositionsRef.current.first;
          secondColumn.scrollLeft = scrollPositionsRef.current.second;
        } else {
          // Desktop vertical scrolling
          scrollPositionsRef.current.first += SCROLL_SPEED * deltaTime;
          scrollPositionsRef.current.second -= SCROLL_SPEED * deltaTime;

          if (scrollPositionsRef.current.first >= firstColumn.scrollHeight / 2) {
            scrollPositionsRef.current.first = 0;
          }

          if (scrollPositionsRef.current.second <= 0) {
            scrollPositionsRef.current.second = secondColumn.scrollHeight / 2;
          }

          firstColumn.scrollTop = scrollPositionsRef.current.first;
          secondColumn.scrollTop = scrollPositionsRef.current.second;
        }

        animationFrameRef.current = requestAnimationFrame(animateScroll);
      };

      animationFrameRef.current = requestAnimationFrame(animateScroll);

      return () => {
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }
  }, [isMobile]);

  return (
    <>
      <div className="about-top-bar">
        <Search />
      </div>
      <div className="home about-home">
        <div className="left center">
          <h1 className="big-h">
            Design Anything with <InteractiveTitle />
          </h1>
          <p className="landing-p">
            Discover a versatile platform where imagination meets innovation. With advanced AI tools, effortlessly design logos, shirt graphics, memes, sketchbook pages, and more.
            Edit and refine your creations using intuitive prompts.
            {!isMobile && (
              <> 
                From crafting stunning visuals to sharing your work with a vibrant community, this is your hub for creativity, collaboration, and inspiration.
                Let your ideas shine with RangrezAI.
              </>
            )}
          </p>
        </div>
        <div className={`right ${isMobile ? 'mobile' : ''}`}>
          <div className="scroll-column first" ref={firstColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`first-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
          <div className="scroll-column second" ref={secondColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`second-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="home-buttons">
        <Link to="/create">Create Now</Link>
        <Link to="/design">Explore</Link>
      </div>
      <ThemeShowcase />
      <ImageGeneration />
      <ContentGeneration />
      <CommunityShowcase />
      <FeaturePollinations />
      <Footer />
    </>
  );
};

export default About;