import React, { useState } from 'react';
import './CommunityShowcase.css';
import { Link } from 'react-router-dom';

const communityDesigns = [
    {
        id: "6743aeb8c0dec89d025d9983",
        title: "Blonde Woman Modern Logo Design",
        creator: "stylus Temp",
        username: "stylustemp8421",
        likes: 245,
        image: "../images/community/blonde-woman-modern-logo-design.png",
        category: "Logo Design"
    },
    {
        id: "674c80e677e6f9da25be2a94",
        title: "Elegance in Motion",
        creator: "MUHAMMAD BAGUS",
        username: "ravasha2785",
        likes: 212,
        image: "../images/community/elegance-in-motion.png",
        category: "Wallpaper"
    },
    {
        id: "6744bfd1f89097deb379c219",
        title: "Marble Windbreaker Design",
        creator: "Darragh Riordan",
        username: "darrariordan4652",
        likes: 189,
        image: "../images/community/marble-windbreaker-design.png",
        category: "Product Design"
    },
    {
        id: "67457e103c6baf2ad181dbf2",
        title: "Whimsical Origami Dinner Table Spread",
        creator: "Muxa Zloy",
        username: "machupikchu9593",
        likes: 376,
        image: "../images/community/whimsical-origami-dinner-table-spread.png",
        category: "Art Design"
    }

];

const CommunityShowcase = () => {
    const [activeCategory, setActiveCategory] = useState('All');

    const categories = [
        'All',
        'Wallpaper',
        'Logo Design',
        'Product Design',
        'Art Design'
    ];

    const filteredDesigns = activeCategory === 'All'
        ? communityDesigns
        : communityDesigns.filter(design => design.category === activeCategory);

    return (
        <div className="cs-container">
            <div className="cs-content">
                <div className="cs-header">
                    <h1 className="cs-heading big-h">Community Creations</h1>
                    <p className="cs-subheading landing-p">
                        Explore stunning designs crafted by our global creative community.
                        Get inspired, appreciate incredible work, and see the limitless
                        possibilities of AI-assisted design.
                    </p>
                </div>

                <div className="cs-category-filter">
                    {categories.map(category => (
                        <button
                            key={category}
                            className={`cs-category-btn ${activeCategory === category ? 'active' : ''}`}
                            onClick={() => setActiveCategory(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <div className="cs-designs-grid">
                    {filteredDesigns.map(design => (
                        <div key={design.id} className="cs-design-card">
                            <Link to={`/design/${design.id}`} className="cs-design-link">
                                <div className="cs-design-image-container">
                                    <img
                                        src={design.image}
                                        alt={design.title}
                                        className="cs-design-image"
                                    />
                                </div>
                                <div className="cs-design-info">
                                    <h3 className="cs-design-title">{design.title}</h3>
                                    <div className="cs-design-meta">
                                        <span className="cs-design-creator">By:
                                            <span>{design.creator}</span>
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="cs-cta-section">
                    <h2 className="cs-cta-heading">Ready to Showcase Your Creativity?</h2>
                    <p className="cs-cta-description">
                        Join our community and transform your ideas into breathtaking designs.
                        RangrezAI empowers creators like you to push the boundaries of imagination.
                    </p>
                    <Link to={'/create'} className="cs-cta-button">Start Creating</Link>
                </div>
            </div>
        </div>
    );
};

export default CommunityShowcase;